import _Object$keys from 'babel-runtime/core-js/object/keys';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces, getClusters } from '@/api/namespaces';
import { fetchPartitionTopicStats, deletePartitionTopicOnCluster, expireMessagesAllSubscriptionsOnCluster, resetCursorByTimestampOnCluster, clearBacklogOnCluster, getPermissionsOnCluster, grantPermissionsOnCluster, revokePermissionsOnCluster } from '@/api/topics';
import { fetchTopicsByPulsarManager } from '@/api/topics';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';
import { putSubscriptionOnCluster, deleteSubscriptionOnCluster } from '@/api/subscriptions';

var defaultForm = {
  persistent: '',
  tenant: '',
  namespace: '',
  topic: ''
};
var defaultClusterForm = {
  cluster: ''
};
export default {
  name: 'ParititionTopicInfo',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      autoRefreshInterval: 'off',
      postForm: _Object$assign({}, defaultForm),
      clusterForm: _Object$assign({}, defaultClusterForm),
      activeName: 'overview',
      replicatedClusters: [],
      tenantsListOptions: [],
      namespacesListOptions: [],
      topicsListOptions: [],
      partitionTopicStats: [],
      partitionsList: [],
      partitionTableKey: 0,
      partitionsListLoading: false,
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      roleValue: [],
      roleMap: {},
      roleMapOptions: {},
      roleOptions: [{
        value: 'consume',
        label: 'consume'
      }, {
        value: 'produce',
        label: 'produce'
      }, {
        value: 'functions',
        label: 'functions'
      }],
      authorizationContent: this.$i18n.t('topic.policy.authorizationContent'),
      partitionedTopicName: '',
      firstInit: false,
      firstInitTopic: false,
      currentTabName: '',
      textMap: {
        delete: this.$i18n.t('topic.deleteTopic'),
        expire: this.$i18n.t('topic.subscription.msgExpired'),
        clear: this.$i18n.t('topic.subscription.clearMessage'),
        reset: this.$i18n.t('topic.subscription.resetByTime'),
        createSub: this.$i18n.t('topic.subscription.sub'),
        deleteSub: this.$i18n.t('topic.subscription.unsub')
      },
      dialogFormVisible: false,
      dialogStatus: '',
      subscriptionsListLoading: false,
      subscriptionTableKey: 0,
      subscriptionsList: [],
      subscriptionsTotal: 0,
      subscriptionsListQuery: {
        page: 1,
        limit: 0
      },
      form: {
        expireTime: '',
        resetByTime: ''
      },
      rules: {
        expireTime: [{ required: true, message: this.$i18n.t('topic.subscription.expireTimeRequired'), trigger: 'blur' }],
        resetByTime: [{ required: true, message: this.$i18n.t('topic.subscription.resetByTimeRequired'), trigger: 'blur' }]
      },
      currentSubscription: ''
    };
  },
  created: function created() {
    var _this = this;

    this.postForm.persistent = this.$route.params && this.$route.params.persistent;
    this.postForm.tenant = this.$route.params && this.$route.params.tenant;
    this.postForm.namespace = this.$route.params && this.$route.params.namespace;
    this.postForm.topic = this.$route.params && this.$route.params.topic;
    this.tenantNamespaceTopic = this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic;
    if (this.$route.query && this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    }
    this.partitionedTopicName = this.postForm.persistent + '://' + this.tenantNamespaceTopic;
    this.firstInit = true;
    this.firstInitTopic = true;
    this.getRemoteTenantsList();
    this.getNamespacesList(this.postForm.tenant);
    this.getTopicsList();
    this.getReplicatedClusters();
    this.initTopicStats();
    this.initPermissions();
    var refreshInterval = sessionStorage.getItem('refreshInterval');
    this.autoRefreshInterval = refreshInterval;
    setTimeout(function () {
      if (refreshInterval !== null && refreshInterval !== undefined && refreshInterval !== 'off') {
        refreshInterval = parseInt(refreshInterval);
        _this.refreshIntervalId = setInterval(function () {
          location.reload();
          _this.$refs.autoRefreshSelect.value = refreshInterval;
        }, refreshInterval * 1000);
      }
    }, 1000);
  },

  methods: {
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this2 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this2.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    getReplicatedClusters: function getReplicatedClusters() {
      var _this3 = this;

      if (this.postForm.tenant && this.postForm.namespace) {
        getClusters(this.postForm.tenant, this.postForm.namespace).then(function (response) {
          if (!response.data) {
            return;
          }
          _this3.replicatedClusters = response.data;
          if (response.data.length > 0) {
            _this3.clusterForm.cluster = _this3.routeCluster || _this3.replicatedClusters[0];
          }
        });
      }
    },
    initTopicStats: function initTopicStats() {
      var _this4 = this;

      fetchPartitionTopicStats(this.postForm.persistent, this.tenantNamespaceTopic, true).then(function (response) {
        if (!response.data) return;
        _this4.partitionTopicStats = [];
        _this4.partitionTopicStats.push({
          inMsg: numberFormatter(response.data.msgRateIn, 2),
          outMsg: numberFormatter(response.data.msgRateOut, 2),
          inBytes: formatBytes(response.data.msgThroughputIn),
          outBytes: formatBytes(response.data.msgThroughputOut)
        });
        var prefix = _this4.postForm.persistent + '://' + _this4.tenantNamespaceTopic;
        var tempPartitionsList = _Object$keys(response.data.partitions);
        _this4.partitionsList = [];
        for (var i = 0; i < tempPartitionsList.length; i++) {
          var key = prefix + '-partition-' + i;
          if (response.data.partitions.hasOwnProperty(key)) {
            var partition = _this4.postForm.topic + '-partition-' + i;
            var publishers = 0;
            if (response.data.partitions[key].hasOwnProperty('publishers')) {
              publishers = response.data.partitions[key].publishers.length;
            }
            _this4.partitionsList.push({
              'partition': partition,
              'producers': publishers,
              'subscriptions': _Object$keys(response.data.partitions[key].subscriptions).length,
              'inMsg': numberFormatter(response.data.partitions[key].msgRateIn, 2),
              'outMsg': numberFormatter(response.data.partitions[key].msgRateOut, 2),
              'inBytes': formatBytes(response.data.partitions[key].msgThroughputIn),
              'outBytes': formatBytes(response.data.partitions[key].msgThroughputOut),
              'storageSize': formatBytes(response.data.partitions[key].storageSize, 0),
              'partitionTopicLink': '/management/topics/' + _this4.postForm.persistent + '/' + _this4.tenantNamespaceTopic + '-partition-' + i + '/topic'
            });
          }
        }
        var index = 0;
        _this4.subscriptionsList = [];
        for (var s in response.data.subscriptions) {
          index += 1;
          var type = 'Exclusive';
          var children = [];
          for (var j in response.data.partitions) {
            var subSplitPartition = j.split('://');
            var subPartition = subSplitPartition[1].split('/')[2];
            if (response.data.partitions[j].hasOwnProperty('subscriptions')) {
              for (var p in response.data.partitions[j].subscriptions) {
                if (p === s) {
                  children.push({
                    'id': 1000000 * (index + 1) + j,
                    'subscription': subPartition,
                    'outMsg': numberFormatter(response.data.partitions[j].subscriptions[p].msgRateOut, 2),
                    'outBytes': formatBytes(response.data.partitions[j].subscriptions[p].msgThroughputOut),
                    'msgExpired': numberFormatter(response.data.partitions[j].subscriptions[p].msgRateExpired, 2),
                    'backlog': response.data.partitions[j].subscriptions[p].msgBacklog,
                    'type': response.data.partitions[j].subscriptions[p].type,
                    'subscriptionLink': '/management/subscriptions/' + _this4.postForm.persistent + '/' + subSplitPartition[1] + '/' + s + '/subscription',
                    'enableSubscriptionLink': true
                  });
                  type = response.data.partitions[j].subscriptions[p].type;
                }
              }
            }
          }

          _this4.subscriptionsList.push({
            'id': index,
            'subscription': s,
            'outMsg': numberFormatter(response.data.subscriptions[s].msgRateOut, 2),
            'outBytes': formatBytes(response.data.subscriptions[s].msgThroughputOut),
            'msgExpired': numberFormatter(response.data.subscriptions[s].msgRateExpired, 2),
            'backlog': response.data.subscriptions[s].msgBacklog,
            'type': type,
            'children': children,
            'subscriptionLink': '#',
            'enableSubscriptionLink': false
          });
        }
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this5 = this;

      var namespace = [];
      this.namespacesListOptions = [];
      this.topicsListOptions = [];
      if (this.firstInit) {
        this.firstInit = false;
      } else {
        this.postForm.namespace = '';
        this.postForm.topic = '';
      }
      fetchNamespaces(tenant, this.query).then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.data.length; i++) {
          namespace = response.data.data[i].namespace;
          _this5.namespacesListOptions.push(namespace);
        }
      });
    },
    getTopicsList: function getTopicsList() {
      var _this6 = this;

      this.getReplicatedClusters();
      fetchTopicsByPulsarManager(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
        _this6.topicsListOptions = [];
        if (_this6.firstInitTopic) {
          _this6.firstInitTopic = false;
        } else {
          _this6.postForm.topic = '';
        }
        for (var i in response.data.topics) {
          if (response.data.topics[i]['partitions'] !== '0') {
            _this6.topicsListOptions.push(response.data.topics[i]['topic']);
          }
        }
      });
    },
    getPartitionTopicInfo: function getPartitionTopicInfo() {
      this.$router.push({ path: '/management/topics/' + this.postForm.persistent + '/' + this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic + '/partitionedTopic?tab=' + this.currentTabName + '&cluster=' + this.getCurrentCluster()
      });
    },
    getCurrentCluster: function getCurrentCluster() {
      return this.clusterForm.cluster || '';
    },
    initPermissions: function initPermissions() {
      var _this7 = this;

      getPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic).then(function (response) {
        if (!response.data) return;
        for (var key in response.data) {
          _this7.dynamicTags.push(key);
          _this7.roleMap[key] = response.data[key];
          _this7.roleMapOptions[key] = _this7.roleOptions;
        }
      });
    },
    handleClick: function handleClick(tab, event) {
      this.currentTabName = tab.name;
      this.$router.push({ query: {
          'tab': tab.name,
          'cluster': this.getCurrentCluster()
        } });
    },
    handleClose: function handleClose(tag) {
      var _this8 = this;

      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      revokePermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, tag).then(function (response) {
        _this8.$notify({
          title: 'success',
          message: _this8.$i18n.t('namespace.notification.removeRoleSuccess'),
          type: 'success',
          duration: 3000
        });
        delete _this8.roleMap[tag];
        delete _this8.roleMapOptions[tag];
      });
    },
    showInput: function showInput() {
      var _this9 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this9.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var _this10 = this;

      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.roleMap.hasOwnProperty(inputValue)) {
          this.$message({
            message: this.$i18n.t('role.roleAlreadyExists'),
            type: 'error'
          });
          this.inputVisible = false;
          this.inputValue = '';
          return;
        }
        grantPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, inputValue, this.roleMap[inputValue]).then(function (response) {
          _this10.$notify({
            title: 'success',
            message: _this10.$i18n.t('namespace.notification.addRoleSuccess'),
            type: 'success',
            duration: 3000
          });
          _this10.dynamicTags.push(inputValue);
          _this10.roleMap[inputValue] = [];
          _this10.roleMapOptions[inputValue] = _this10.roleOptions;
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleChangeOptions: function handleChangeOptions(role) {
      var _this11 = this;

      grantPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, role, this.roleMap[role]).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: 'Set permissions success',
          type: 'success',
          duration: 3000
        });
      });
      this.$forceUpdate();
    },
    handleDeletePartitionTopic: function handleDeletePartitionTopic() {
      this.postForm.expireTime = '';
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    deleteParititionTopic: function deleteParititionTopic() {
      var _this12 = this;

      deletePartitionTopicOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: _this12.$i18n.t('topic.notification.deletePartitionedTopicSuccess'),
          type: 'success',
          duration: 3000
        });
        _this12.$router.push({ path: '/management/namespaces/' + _this12.postForm.tenant + '/' + _this12.postForm.namespace + '/namespace?tab=topics' });
      });
    },
    handleOptions: function handleOptions() {
      var _this13 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          switch (_this13.dialogStatus) {
            case 'delete':
              _this13.deleteParititionTopic();
              break;
            case 'expire':
              _this13.expireAllSubMessage();
              break;
            case 'reset':
              _this13.resetAllSubMessage();
              break;
            case 'clear':
              _this13.clearAllSubMessage();
              break;
            case 'createSub':
              _this13.createSub();
              break;
            case 'unsub':
              _this13.deleteSub();
              break;
          }
        }
      });
    },
    expireAllSubMessage: function expireAllSubMessage() {
      var _this14 = this;

      expireMessagesAllSubscriptionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.form.expireTime).then(function (response) {
        _this14.$notify({
          title: 'success',
          message: _this14.$i18n.t('topic.notification.expireMessageSuccess'),
          type: 'success',
          duration: 3000
        });
        _this14.dialogFormVisible = false;
      });
    },
    handleAllSub: function handleAllSub(command, subscription) {
      this.dialogFormVisible = true;
      this.dialogStatus = command.action;
      this.currentSubscription = command.subscription;
    },
    resetAllSubMessage: function resetAllSubMessage() {
      var _this15 = this;

      var dateTime = new Date().getTime();
      var timestamp = Math.floor(dateTime) - parseInt(this.form.resetByTime) * 60 * 1000;
      resetCursorByTimestampOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.currentSubscription, timestamp).then(function (response) {
        _this15.$notify({
          title: 'success',
          message: _this15.$i18n.t('topic.notification.resetMessageSuccess'),
          type: 'success',
          duration: 3000
        });
        _this15.dialogFormVisible = false;
      });
    },
    clearAllSubMessage: function clearAllSubMessage() {
      var _this16 = this;

      clearBacklogOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.currentSubscription).then(function (response) {
        _this16.$notify({
          title: 'success',
          message: _this16.$i18n.t('topic.notification.clearMessageSuccess'),
          type: 'success',
          duration: 3000
        });
        _this16.dialogFormVisible = false;
        _this16.getPartitionTopicInfo();
      });
    },
    handleCreateSub: function handleCreateSub() {
      this.currentSubscription = '';
      this.dialogStatus = 'createSub';
      this.dialogFormVisible = true;
    },
    createSub: function createSub() {
      var _this17 = this;

      if (this.currentSubscription.length <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.subNotification'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      putSubscriptionOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.currentSubscription).then(function (response) {
        _this17.$notify({
          title: 'success',
          message: _this17.$i18n.t('topic.subscription.createSubSuccess'),
          type: 'success',
          duration: 3000
        });
        _this17.initTopicStats();
        _this17.dialogFormVisible = false;
      });
    },
    deleteSub: function deleteSub() {
      var _this18 = this;

      deleteSubscriptionOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.currentSubscription).then(function (response) {
        _this18.$notify({
          title: 'success',
          message: _this18.$i18n.t('topic.subscription.deleteSubSuccess'),
          type: 'success',
          duration: 3000
        });
        _this18.initTopicStats();
        _this18.dialogFormVisible = false;
      });
    },
    onAutoRefreshChanged: function onAutoRefreshChanged(val) {
      if (this.refreshIntervalId !== undefined) {
        clearInterval(this.refreshIntervalId);
      }
      if (val !== 'off') {
        sessionStorage.setItem('refreshInterval', val);
        this.refreshIntervalId = setInterval(function () {
          location.reload();
        }, val * 1000);
      } else {
        sessionStorage.removeItem('refreshInterval');
      }
    }
  }
};