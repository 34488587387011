//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AuthRedirect',
  created: function created() {
    var hash = window.location.search.slice(1);
    window.opener.location.href = window.location.origin + '/login#' + hash;
    window.close();
  }
};