import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchTenants } from '@/api/tenants';
import { getTenant } from '@/utils/tenant';
import { fetchNamespaces, putNamespace, deleteNamespace as _deleteNamespace } from '@/api/namespaces';
import { validateEmpty } from '@/utils/validate';

var defaultForm = {
  tenant: getTenant()
};

export default {
  name: 'TenantInfo',
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      adminRoles: '',
      tenantsListOptions: [],
      listQuery: {
        namespace: '',
        page: 1,
        limit: 20
      },
      activeName: 'namespaces',
      listLoading: false,
      tableKey: 0,
      total: 0,
      listNamespaces: [],
      temp: {
        tenant: '',
        namespace: '',
        limit: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      rules: {
        namespace: [{ required: true, message: this.$i18n.t('namespace.namespaceNameIsRequired'), trigger: 'blur' }]
      },
      currentTabName: '',
      textMap: {
        createNamespace: this.$i18n.t('namespace.newNamespace'),
        deleteNamespace: this.$i18n.t('namespace.deleteNamespace')
      },
      tempNamespacesList: [],
      searchNamespace: '',
      searchList: []
    };
  },
  created: function created() {
    this.getRemoteTenantsList();
    this.getNamespacesList();
  },

  methods: {
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    getNamespacesList: function getNamespacesList() {
      var _this2 = this;

      fetchNamespaces(this.postForm.tenant, this.listQuery).then(function (response) {
        if (!response.data.data) return;
        _this2.listNamespaces = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this2.listNamespaces.push({
            'tenant': _this2.postForm.tenant,
            'namespace': response.data.data[i].namespace,
            'topics': response.data.data[i].topics
          });
        }
        _this2.total = _this2.listNamespaces.length;
        // Just to simulate the time of the request
        setTimeout(function () {
          _this2.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilterNamespace: function handleFilterNamespace() {
      if (this.tempNamespacesList.length <= 0) {
        for (var t = 0; t < this.listNamespaces.length; t++) {
          this.tempNamespacesList.push(this.listNamespaces[t]);
        }
      }
      if (!validateEmpty(this.searchNamespace)) {
        this.searchList = [];
        for (var i = 0; i < this.listNamespaces.length; i++) {
          if (this.listNamespaces[i]['namespace'].indexOf(this.searchNamespace) !== -1) {
            this.searchList.push(this.listNamespaces[i]);
          }
        }
        this.listNamespaces = this.searchList;
      } else {
        this.listNamespaces = this.tempNamespacesList;
      }
    },
    handleCreateNamespace: function handleCreateNamespace() {
      this.temp.namespace = '';
      this.dialogStatus = 'createNamespace';
      this.dialogFormVisible = true;
    },
    createNamespace: function createNamespace() {
      var _this3 = this;

      putNamespace(this.postForm.tenant, this.temp.namespace, this.temp).then(function () {
        _this3.listNamespaces = [];
        _this3.getNamespacesList();
        _this3.dialogFormVisible = false;
        _this3.$notify({
          title: 'success',
          message: _this3.$i18n.t('namespace.createNsSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    handleOptions: function handleOptions() {
      var _this4 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          switch (_this4.dialogStatus) {
            case 'createNamespace':
              _this4.createNamespace();
              break;
            case 'deleteNamespace':
              _this4.deleteNamespace();
              break;
          }
        }
      });
    },
    handleDeleteNamespace: function handleDeleteNamespace(row) {
      this.dialogStatus = 'deleteNamespace';
      this.dialogFormVisible = true;
      this.temp.tenant = row.tenant;
      this.temp.namespace = row.namespace;
    },
    deleteNamespace: function deleteNamespace() {
      var _this5 = this;

      var tenantNamespace = this.temp.tenant + '/' + this.temp.namespace;
      _deleteNamespace(tenantNamespace).then(function (response) {
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('namespace.deleteNsSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this5.dialogFormVisible = false;
        _this5.listNamespaces = [];
        _this5.getNamespacesList();
      });
    }
  }
};