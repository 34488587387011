/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var errorLog = {
  state: {
    logs: []
  },
  mutations: {
    ADD_ERROR_LOG: function ADD_ERROR_LOG(state, log) {
      state.logs.push(log);
    }
  },
  actions: {
    addErrorLog: function addErrorLog(_ref, log) {
      var commit = _ref.commit;

      commit('ADD_ERROR_LOG', log);
    }
  }
};

export default errorLog;