var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-prometheus" }, [
    _vm.iframe === "yes"
      ? _c("div", [
          _c("iframe", {
            ref: "iframe",
            staticClass: "dashboard-prometheus-iframe",
            attrs: { src: _vm.src }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.iframe === "no"
      ? _c("div", [_c("span", [_vm._v("Apache Pulsar")])])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }