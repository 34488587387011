import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import ErrorLog from '@/components/ErrorLog';
import SizeSelect from '@/components/SizeSelect';
import LangSelect from '@/components/LangSelect';
import { fetchEnvironments } from '@/api/environments';
import { setEnvironment, getEnvironment } from '@/utils/environment';

export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    ErrorLog: ErrorLog,
    SizeSelect: SizeSelect,
    LangSelect: LangSelect
  },
  data: function data() {
    return {
      currentEnv: 'Environments',
      environmentsListOptions: [{
        'label': 'localhost:8080',
        'value': 'localhost:8080'
      }]
    };
  },

  computed: _extends({}, mapGetters(['sidebar', 'name', 'avatar', 'device'])),
  created: function created() {
    this.getEnvironmentsList();
    this.currentEnv = getEnvironment();
  },

  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
    handleCommand: function handleCommand(command) {
      if (command === 'newEnvironment') {
        this.$router.push({ path: '/environments' });
        return;
      }
      setEnvironment(command.value);
      window.location.reload();
    },
    getEnvironmentsList: function getEnvironmentsList() {
      var _this = this;

      fetchEnvironments().then(function (response) {
        if (!response.data) return;
        _this.environmentsListOptions = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this.environmentsListOptions.push({
            'value': response.data.data[i].name,
            'label': response.data.data[i].broker,
            'status': response.data.data[i].status
          });
        }
      });
    }
  }
};