import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import codeEditor from '@/components/CodeEditor'
import yaml from 'js-yaml';
import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces } from '@/api/namespaces';
import jsonEditor from '@/components/JsonEditor';
import { validateEmpty } from '@/utils/validate';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { fetchSinks, createSink, updateSink, getSinkStatus, startSinkInstance, stopSinkInstance, restartSinkInstance, deleteSink, fetchSinksStats, getSinkStatusInstance, getBuiltinSinks } from '@/api/sinks';
import { fetchTopics, fetchPersistentPartitonsTopics, fetchNonPersistentPartitonsTopics } from '@/api/topics';

var defaultForm = {
  tenant: '',
  namespace: ''
};
export default {
  name: 'Sinks',
  components: {
    // codeEditor
    jsonEditor: jsonEditor,
    Pagination: Pagination
  },
  data: function data() {
    return {
      codeValue: null,
      postForm: _Object$assign({}, defaultForm),
      tenantsListOptions: [],
      namespacesListOptions: [],
      inputTopicsListOptions: [],
      guanrateesListOptions: [],
      instancesListOptions: [],
      // codeMode: 'text/x-cython',
      localList: [],
      searchList: [],
      tenant: '',
      namespace: '',
      jsonValue: {},
      tableKey: 0,
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      list: null,
      currentFile: '',
      triggerFile: '',
      currentTriggerFileName: '',
      currentSink: '',
      dialogStatus: '',
      listQuery: {
        topic: '',
        page: 1,
        limit: 10
      },
      temp: {
        sink: '',
        instanceId: 0,
        className: '',
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        parallelism: '',
        schemaType: '',
        currentFile: '',
        currentFileName: '',
        currentConfigFile: '',
        currentConfigFileName: '',
        sinkConfig: '',
        triggerValue: '',
        triggerInput: '',
        deClassName: '',
        guarantees: ''
      },
      rules: {
        sink: [{ required: true, message: 'sink name is required', trigger: 'blur' }],
        className: [{ required: true, message: 'className is required', trigger: 'blur' }],
        inputTopic: [{ required: true, message: 'inputTopic is required', trigger: 'blur' }]
      }
    };
  },
  mounted: function mounted() {
    this.guanrateesListOptions = ['ATLEAST_ONCE', 'ATMOST_ONCE', 'EFFECTIVELY_ONCE'];
  },
  created: function created() {
    this.getSinks();
    this.getRemoteTenantsList();
  },

  methods: {
    getSinks: function getSinks() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0);
      } else {
        this.listLoading = true;
        if (this.postForm.tenant.length > 0) {
          this.tenant = this.postForm.tenant;
        }
        if (this.postForm.namespace.length > 0) {
          this.namespace = this.postForm.namespace;
        }
        if (this.tenant.length <= 0 || this.namespace.length <= 0) {
          this.tenant = 'public';
          this.namespace = 'default';
        }
        fetchSinks(this.tenant, this.namespace).then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            _this.localList.push({ 'sink': response.data[i], 'stats': 'stats', 'status': 'status' });
          }
          _this.total = _this.localList.length;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.topic)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['sink'].indexOf(this.listQuery.topic) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    handleFilter: function handleFilter(tenant, namespace) {
      this.tenant = tenant;
      this.namespace = namespace;
      this.getSinks();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.resetTemp();
      this.$nextTick(function () {
        _this2.$refs['temp'].clearValidate();
      });
    },
    getCurrentRow: function getCurrentRow(item) {
      this.currentSink = item.sink;
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      if (this.currentSink.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one sink in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      deleteSink(this.tenant, this.namespace, this.currentSink).then(function (response) {
        _this3.$notify({
          title: 'success',
          message: 'delete sink sink',
          type: 'success',
          duration: 2000
        });
        _this3.localList = [];
        _this3.getSinks();
      });
    },
    confirmCreateSink: function confirmCreateSink() {
      var _this4 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var formData = _this4.prepareSinkParams(_this4.temp.sink);
          createSink(_this4.tenant, _this4.namespace, _this4.temp.sink, formData).then(function (response) {
            _this4.$notify({
              title: 'success',
              message: 'create sink success',
              type: 'success',
              duration: 2000
            });
            _this4.dialogFormVisible = false;
            _this4.localList = [];
            _this4.getSinks();
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      this.resetTemp();
      if (this.currentSink.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one sink in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
    },
    handleAction: function handleAction(action, row) {
      var _this5 = this;

      this.dialogStatus = action;
      this.dialogFormVisible = true;
      this.instancesListOptions = [];
      this.currentSink = row.sink;
      getSinkStatus(this.tenant, this.namespace, row.sink).then(function (response) {
        for (var i = 0; i < response.data.instances.length; i++) {
          _this5.instancesListOptions.push(response.data.instances[i]['instanceId']);
        }
      });
    },
    handleOptions: function handleOptions() {
      switch (this.dialogStatus) {
        case 'create':
          this.confirmCreateSink();
          break;
        case 'start':
          this.confirmStart();
          break;
        case 'stop':
          this.confirmStop();
          break;
        case 'restart':
          this.confirmRestart();
          break;
        case 'update':
          this.confirmUpdate();
          break;
        case 'status':
          this.confirmStatus();
          break;
      }
    },
    loadTextFromFile: function loadTextFromFile(ev) {
      var _this6 = this;

      var file = ev.target.files[0];
      console.log(ev.target);
      console.log(ev.target.files);
      this.temp.currentFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this6.temp.currentFile = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },
    loadSinkConfigFile: function loadSinkConfigFile(ev) {
      var _this7 = this;

      var file = ev.target.files[0];
      this.temp.currentConfigFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this7.temp.currentConfigFile = e.target.result;
      };
      reader.readAsText(file);
    },
    handleGet: function handleGet(row) {
      var _this8 = this;

      fetchSinksStats(this.tenant, this.namespace, row.sink).then(function (response) {
        _this8.jsonValue = response.data;
      });
    },
    confirmStart: function confirmStart() {
      var _this9 = this;

      startSinkInstance(this.tenant, this.namespace, this.currentSink, this.temp.instanceId).then(function (response) {
        _this9.$notify({
          title: 'success',
          message: 'start sink success',
          type: 'success',
          duration: 2000
        });
        _this9.dialogFormVisible = false;
      });
    },
    confirmStop: function confirmStop() {
      var _this10 = this;

      stopSinkInstance(this.tenant, this.namespace, this.currentSink, this.temp.instanceId).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: 'stop sink success',
          type: 'success',
          duration: 2000
        });
        _this10.dialogFormVisible = false;
      });
    },
    confirmRestart: function confirmRestart() {
      var _this11 = this;

      restartSinkInstance(this.tenant, this.namespace, this.currentSink, this.temp.instanceId).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: 'restart sink success',
          type: 'success',
          duration: 2000
        });
        _this11.dialogFormVisible = false;
      });
    },
    confirmStatus: function confirmStatus() {
      var _this12 = this;

      getSinkStatusInstance(this.tenant, this.namespace, this.currentSink, this.temp.instanceId).then(function (response) {
        _this12.jsonValue = response.data;
        _this12.$notify({
          title: 'success',
          message: 'get status sink success',
          type: 'success',
          duration: 2000
        });
        _this12.dialogFormVisible = false;
      });
    },
    prepareSinkParams: function prepareSinkParams(sinkName) {
      var formData = new FormData();
      var sinkConfig = {
        'topicName': sinkName,
        'className': this.temp.className,
        'tenant': this.tenant,
        'namespace': this.namespace
      };
      if (sinkName.length > 0) {
        sinkConfig['name'] = sinkName;
      }
      var blob = new Blob([this.temp.currentFile], { type: 'application/octet-stream' });
      sinkConfig['archive'] = this.temp.currentFileName;
      sinkConfig['inputs'] = [this.temp.inputTopic];
      formData.append('data', blob, this.temp.currentFileName);
      if (this.temp.schemaType.length > 0) {
        sinkConfig['schemaType'] = this.temp.schemaType;
      }
      if (this.temp.cpu.length > 0) {
        sinkConfig['resources'] = { 'cpu': parseInt(this.temp.cpu) };
      }
      if (this.temp.disk.length > 0) {
        sinkConfig['resources'] = { 'disk': this.temp.disk };
      }
      if (this.temp.ram.length > 0) {
        sinkConfig['resources'] = { 'ram': this.temp.ram };
      }
      if (this.temp.parallelism.length > 0) {
        sinkConfig['parallelism'] = parseInt(this.temp.parallelism);
      }
      if (this.temp.guarantees.length > 0) {
        sinkConfig['processingGuarantees'] = this.temp.guarantees;
      }
      if (this.temp.sinkConfig.length > 0) {
        sinkConfig['configs'] = JSON.parse(this.temp.sinkConfig);
      }
      if (this.temp.currentConfigFile.length > 0) {
        var yamlFile = yaml.load(this.temp.currentConfigFile);
        var yamlJSON = JSON.parse(_JSON$stringify(yamlFile));
        sinkConfig['configs'] = yamlJSON['configs'];
      }
      formData.append('sinkConfig', _JSON$stringify(sinkConfig));
      return formData;
    },
    confirmUpdate: function confirmUpdate() {
      var _this13 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var formData = _this13.prepareSinkParams('');
          updateSink(_this13.tenant, _this13.namespace, _this13.currentSink, formData).then(function (response) {
            _this13.$notify({
              title: 'success',
              message: 'update sink success',
              type: 'success',
              duration: 2000
            });
            _this13.dialogFormVisible = false;
          });
        }
      });
    },
    getSinksList: function getSinksList() {
      this.getSinks();
    },
    builtinSinks: function builtinSinks() {
      var _this14 = this;

      getBuiltinSinks().then(function (response) {
        _this14.jsonValue = response.data;
      });
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this15 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        _this15.tenantsListOptions = response.data;
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this16 = this;

      this.tenant = tenant;
      fetchNamespaces(tenant, this.query).then(function (response) {
        var namespace = [];
        for (var i = 0; i < response.data.length; i++) {
          namespace = response.data[i].split('/');
          // namespace.splice(1, namespace.length).join('/')
          _this16.namespacesListOptions.push(namespace.splice(1, namespace.length).join('/'));
        }
      });
    },
    getTopicsList: function getTopicsList() {
      var _this17 = this;

      this.inputTopicsListOptions = [];
      fetchPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchNonPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchTopics(this.tenant, this.namespace, this.listQuery).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
        }
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        sink: '',
        instanceId: 0,
        className: '',
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        parallelism: '',
        schemaType: '',
        currentFile: '',
        currentFileName: '',
        currentConfigFile: '',
        currentConfigFileName: '',
        sinkConfig: '',
        triggerValue: '',
        triggerInput: '',
        deClassName: '',
        guarantees: ''
      };
    }
  }
};