var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: _vm.$t("tenant.label") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("tenant.name"),
                        filterable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.getNamespacesList(_vm.postForm.tenant)
                        }
                      },
                      model: {
                        value: _vm.postForm.tenant,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "tenant", $$v)
                        },
                        expression: "postForm.tenant"
                      }
                    },
                    _vm._l(_vm.tenantsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: _vm.$t("namespace.label") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("namespace.name"),
                        filterable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.getNamespaceInfo(
                            _vm.postForm.tenant,
                            _vm.postForm.namespace
                          )
                        }
                      },
                      model: {
                        value: _vm.postForm.namespace,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "namespace", $$v)
                        },
                        expression: "postForm.namespace"
                      }
                    },
                    _vm._l(_vm.namespacesListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.overview"), name: "overview" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.namespaceStats, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inMsg"), prop: "inMsg" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.outMsg"), prop: "outMsg" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inBytes"), prop: "inBytes" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("common.outBytes"),
                      prop: "outBytes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "h4",
                [
                  _vm._v("\n        Bundles\n        "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("namespace.bundle.bundleInfoContent")
                              ) +
                              "\n            "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                href:
                                  "https://pulsar.apache.org/docs/en/next/administration-load-balance/#pulsar-load-manager-architecture",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("namespace.bundle.bundleInfoLink")
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-info" })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleBundleTabClick },
                  model: {
                    value: _vm.activeBundleCluster,
                    callback: function($$v) {
                      _vm.activeBundleCluster = $$v
                    },
                    expression: "activeBundleCluster"
                  }
                },
                _vm._l(_vm.replicationClustersValue, function(item, index) {
                  return _c(
                    "el-tab-pane",
                    { key: item + index, attrs: { label: item, name: item } },
                    [
                      _c(
                        "div",
                        { staticClass: "filter-container" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                type: "danger",
                                icon: "el-icon-download"
                              },
                              on: { click: _vm.handleUnloadAll }
                            },
                            [_vm._v("Unload All")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "danger", icon: "el-icon-close" },
                              on: { click: _vm.hanldeClearAllBacklog }
                            },
                            [_vm._v("Clear All Backlog")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.localList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("namespace.bundle.label"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.bundle))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("namespace.bundle.operation"),
                              align: "center",
                              "class-name": "small-padding fixed-width"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          size: "medium",
                                          type: "danger",
                                          icon: "el-icon-share"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.handleSplitBundle(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("namespace.bundle.split")
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          size: "medium",
                                          type: "danger",
                                          icon: "el-icon-download"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.handleUnloadBundle(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("namespace.bundle.unload")
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          size: "medium",
                                          type: "danger",
                                          icon: "el-icon-close"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.handleClearBundleBacklog(
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("namespace.clearBacklog")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.topic"), name: "topics" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("namespace.searchTopics") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilterTopic($event)
                  }
                },
                model: {
                  value: _vm.searchTopic,
                  callback: function($$v) {
                    _vm.searchTopic = $$v
                  },
                  expression: "searchTopic"
                }
              }),
              _vm._v(" "),
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilterTopic }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreateTopic }
                },
                [_vm._v(_vm._s(_vm.$t("namespace.newTopic")))]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.topicsListLoading,
                              expression: "topicsListLoading"
                            }
                          ],
                          key: _vm.topicsTableKey,
                          attrs: {
                            data: _vm.topicsList,
                            "tree-props": {
                              children: "children",
                              hasChildren: "hasChildren"
                            },
                            fit: "",
                            "highlight-current-row": "",
                            "row-key": "id"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.label"),
                              "min-width": "50px",
                              align: "left"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: { to: scope.row.topicLink }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.topic))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.partitionNumber"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.partitions))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.topicDomain"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.persistent))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.producerNumber"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.producers))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "topic.subscription.subscriptionNumber"
                              ),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.subscriptions))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inMsg"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outMsg"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inBytes"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outBytes"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.storageSize"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.storageSize))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.policies"), name: "policies" } },
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("namespace.policy.cluster")))]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "component-item" },
                [
                  _c(
                    "div",
                    { staticClass: "section-title" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.replicatedCluster"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.$t(
                              "namespace.policy.replicatedClustersContent"
                            ),
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px", "margin-top": "20px" },
                      attrs: {
                        placeholder: _vm.$t("cluster.selectClusterMessage"),
                        multiple: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.handleReplicationsClusters()
                        }
                      },
                      model: {
                        value: _vm.replicationClustersValue,
                        callback: function($$v) {
                          _vm.replicationClustersValue = $$v
                        },
                        expression: "replicationClustersValue"
                      }
                    },
                    _vm._l(_vm.replicationClustersOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "h4",
                [
                  _vm._v(
                    _vm._s(_vm.$t("namespace.policy.authorization")) +
                      "\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.authorizationContent,
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-info" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                [
                  _vm._l(_vm.dynamicTags, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        staticClass: "role-el-tag",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { label: tag, "disable-transitions": false }
                      },
                      [
                        _c("div", [
                          _c("span", [_vm._v(" " + _vm._s(tag) + " ")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.$t(
                                "namespace.policy.selectRole"
                              ),
                              multiple: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.handleChangeOptions(tag)
                              }
                            },
                            model: {
                              value: _vm.roleMap[tag],
                              callback: function($$v) {
                                _vm.$set(_vm.roleMap, tag, $$v)
                              },
                              expression: "roleMap[tag]"
                            }
                          },
                          _vm._l(_vm.roleMapOptions[tag], function(item) {
                            return _c("el-option", {
                              key: item.value,
                              staticStyle: { width: "300px" },
                              attrs: { label: item.label, value: item.value }
                            })
                          })
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.handleClose(tag)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("namespace.policy.deleteRole"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            staticStyle: {
                              "margin-right": "10px",
                              width: "200px",
                              "vertical-align": "top"
                            },
                            attrs: { size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              }
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showInput()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("namespace.policy.addRole")))]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("namespace.policy.subscriptionAuthentication"))
                )
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "section-title" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "namespace.policy.subscriptionAuthenticationMode"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "namespace.policy.subscriptionAuthenticationModeContent"
                                )
                              ) +
                              "\n            "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "namespace.policy.subscriptionAuthenticationModeOptions"
                                )
                              ) +
                              "\n            "
                          ),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "namespace.policy.subscriptionAuthenticationModeNone"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "namespace.policy.subscriptionAuthenticaitonModePrefix"
                                    )
                                  ) +
                                  "\n                "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "namespace.policy.subscriptionAuthenticaitonModePrefixExample"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-info" })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-top": "20px", width: "300px" },
                  attrs: { placeholder: "Please select Authentication" },
                  on: {
                    change: function($event) {
                      _vm.handleSubscriptionAuthMode()
                    }
                  },
                  model: {
                    value: _vm.subscriptionAuthenticationMode,
                    callback: function($$v) {
                      _vm.subscriptionAuthenticationMode = $$v
                    },
                    expression: "subscriptionAuthenticationMode"
                  }
                },
                _vm._l(_vm.subscriptionAuthenticationModeOptions, function(
                  item
                ) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                })
              ),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.$t("namespace.policy.storage")))]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "markDeleteMaxRate" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.markDeleteRate"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.markDeleteRateContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDeleteMaxRate"
                          ),
                          name: "markDeleteMaxRate"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handlePersistence($event)
                          }
                        },
                        model: {
                          value: _vm.form.markDeleteMaxRate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "markDeleteMaxRate", $$v)
                          },
                          expression: "form.markDeleteMaxRate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "encryptionRequire" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.encryptionRequire"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.encryptionRequireContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-top": "8px", width: "300px" },
                          attrs: { size: "medium" },
                          on: {
                            change: function($event) {
                              _vm.handleEncryption()
                            }
                          },
                          model: {
                            value: _vm.form.encryptionRequireRadio,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "encryptionRequireRadio", $$v)
                            },
                            expression: "form.encryptionRequireRadio"
                          }
                        },
                        [
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.enabled") }
                          }),
                          _vm._v(" "),
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.disabled") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "deduplication" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("namespace.policy.deduplication")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.deduplicationContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-top": "8px", width: "300px" },
                          attrs: { size: "medium" },
                          on: {
                            change: function($event) {
                              _vm.handleDeduplication()
                            }
                          },
                          model: {
                            value: _vm.form.deduplicationRadio,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "deduplicationRadio", $$v)
                            },
                            expression: "form.deduplicationRadio"
                          }
                        },
                        [
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.enabled") }
                          }),
                          _vm._v(" "),
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.disabled") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.$t("namespace.policy.backlog")))]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "backlogQuotasLimit" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.backlogQuotasLimit"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.backlogQuotasLimitContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputBacklogQuotasLimit"
                          ),
                          name: "backlogQuotasLimit"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBacklogQuota($event)
                          }
                        },
                        model: {
                          value: _vm.form.backlogQuotasLimit,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "backlogQuotasLimit", $$v)
                          },
                          expression: "form.backlogQuotasLimit"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "300px" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.backlogRententionPolicy")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "namespace.policy.backlogRententionPolicyContent"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "namespace.policy.backlogRententionPolicyOptions"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "namespace.policy.producerRequestHoldDesc"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "namespace.policy.producerExceptionDesc"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "namespace.policy.consumerEvictionDesc"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-info" })
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "8px", width: "400px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "namespace.policy.inputBacklogRententionPolicyContent"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.handleBacklogQuota()
                            }
                          },
                          model: {
                            value: _vm.form.backlogRententionPolicy,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "backlogRententionPolicy", $$v)
                            },
                            expression: "form.backlogRententionPolicy"
                          }
                        },
                        _vm._l(_vm.backlogRententionPolicyOption, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        })
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.$t("namespace.policy.schema")))]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "autoUpdateStrategy" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.autoUpdateStrategy"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "namespace.policy.autoUpdateStrategyContent"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    href:
                                      "http://pulsar.apache.org/docs/en/next/schema-evolution-compatibility/#schema-compatibility-check-strategy",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "namespace.policy.schemaCompatibilityCheckStrategyLink"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-info" })
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "8px", width: "300px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "namespace.policy.inputAutoUpdateStrategy"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.handleSchemaAutoUpdateStrategy()
                            }
                          },
                          model: {
                            value: _vm.form.autoUpdateStrategy,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "autoUpdateStrategy", $$v)
                            },
                            expression: "form.autoUpdateStrategy"
                          }
                        },
                        _vm._l(_vm.autoUpdateStrategyOption, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        })
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "schemaValidationEnforced" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.schemaValidationEnforced")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.schemaValidationEnforcedContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-top": "8px", width: "300px" },
                          attrs: { size: "medium" },
                          on: {
                            change: function($event) {
                              _vm.handleSchemaValidationEnforced()
                            }
                          },
                          model: {
                            value: _vm.form.schemaValidationEnforcedRadio,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "schemaValidationEnforcedRadio",
                                $$v
                              )
                            },
                            expression: "form.schemaValidationEnforcedRadio"
                          }
                        },
                        [
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.enabled") }
                          }),
                          _vm._v(" "),
                          _c("el-radio", {
                            attrs: { label: _vm.$t("common.disabled") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("namespace.policy.cleanupPolicy")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.form, rules: _vm.rules },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "messageTTL" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("namespace.policy.messageTTL")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.messageTTLContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputMessageTTL"
                          ),
                          name: "messageTTL"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleMessageTTL($event)
                          }
                        },
                        model: {
                          value: _vm.form.messageTTL,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "messageTTL", $$v)
                          },
                          expression: "form.messageTTL"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "retentionSize" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("namespace.policy.retentionSize")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.retentionSizeContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputRententionSize"
                          ),
                          name: "retentionSize"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleRetention($event)
                          }
                        },
                        model: {
                          value: _vm.form.retentionSize,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "retentionSize", $$v)
                          },
                          expression: "form.retentionSize"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "retentionTime" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("namespace.policy.retentionTime")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.retentionTimeContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputRetentionTime"
                          ),
                          name: "retentionTime"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleRetention($event)
                          }
                        },
                        model: {
                          value: _vm.form.retentionTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "retentionTime", $$v)
                          },
                          expression: "form.retentionTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "compactionThreshold" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.compactionThreshold"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.compactionThresholdContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputCompactionThreshold"
                          ),
                          name: "compactionThreshold"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleCompactionThreshold($event)
                          }
                        },
                        model: {
                          value: _vm.form.compactionThreshold,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "compactionThreshold", $$v)
                          },
                          expression: "form.compactionThreshold"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "offloadThreshold" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.offloadThreshold"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.offloadThresholdContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputOffloadThreshold"
                          ),
                          name: "offloadThreshold"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleOffloadThreshold($event)
                          }
                        },
                        model: {
                          value: _vm.form.offloadThreshold,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "offloadThreshold", $$v)
                          },
                          expression: "form.offloadThreshold"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "offloadDeletionLag" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.offloadDeletionLag"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.offloadDeletionLagContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputOffloadDeleteLag"
                          ),
                          name: "offloadDeletionLag"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleOffloadDeletionLag($event)
                          }
                        },
                        model: {
                          value: _vm.form.offloadDeletionLag,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "offloadDeletionLag", $$v)
                          },
                          expression: "form.offloadDeletionLag"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.$t("namespace.policy.throttling")))]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "maxProducersPerTopic" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.maxProducersPerTopic")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.maxProducersPerTopicContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputMaxProducersPerTopic"
                          ),
                          name: "maxProducersPerTopic"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleMaxProducersPerTopic($event)
                          }
                        },
                        model: {
                          value: _vm.form.maxProducersPerTopic,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maxProducersPerTopic", $$v)
                          },
                          expression: "form.maxProducersPerTopic"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "maxConsumersPerTopic" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.maxConsumersPerTopic")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.maxConsumersPerTopicContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputMaxConsumersForTopic"
                          ),
                          name: "maxConsumersPerTopic"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleMaxConsumersPerTopic($event)
                          }
                        },
                        model: {
                          value: _vm.form.maxConsumersPerTopic,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maxConsumersPerTopic", $$v)
                          },
                          expression: "form.maxConsumersPerTopic"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.form, rules: _vm.rules },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "maxConsumerPerSub" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "namespace.policy.maxConsumerPerSubscription"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.maxConsumerPerSubContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputMaxConsumersForSub"
                          ),
                          name: "maxConsumerPerSub"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleMaxConsuemrsPerSubscription($event)
                          }
                        },
                        model: {
                          value: _vm.form.maxConsumerPerSub,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maxConsumerPerSub", $$v)
                          },
                          expression: "form.maxConsumerPerSub"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("namespace.policy.dispatchRate")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("namespace.policy.dispatchRatePerTopic")))
              ]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.dispatchRatePerTopicContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerTopicBytes" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.dispatchRatePerTopicBytes")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchRateBytes"
                          ),
                          name: "dispatchRatePerTopicBytes"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerTopic($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerTopicBytes,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dispatchRatePerTopicBytes", $$v)
                          },
                          expression: "form.dispatchRatePerTopicBytes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerTopicMessage" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "namespace.policy.dispatchRatePerTopicMessage"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchRatePerTopicMessage"
                          ),
                          name: "dispatchRatePerTopicMessage"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerTopic($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerTopicMessage,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "dispatchRatePerTopicMessage",
                              $$v
                            )
                          },
                          expression: "form.dispatchRatePerTopicMessage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerTopicPeriod" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "namespace.policy.dispatchRatePerTopicPeriod"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchPerTopicPerPeriod"
                          ),
                          name: "dispatchRatePerTopicPeriod"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerTopic($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerTopicPeriod,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "dispatchRatePerTopicPeriod",
                              $$v
                            )
                          },
                          expression: "form.dispatchRatePerTopicPeriod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("namespace.policy.dispatchRateForSub")))
              ]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.dispatchRatePerSubContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.form, rules: _vm.rules },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerSubBytes" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.dispatchRatePerSubBytes")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchRatePerSubBytes"
                          ),
                          name: "dispatchRatePerSubBytes"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerSub($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerSubBytes,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dispatchRatePerSubBytes", $$v)
                          },
                          expression: "form.dispatchRatePerSubBytes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerSubMessage" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.dispatchRatePerSubMessage")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchRatePerSubMessage"
                          ),
                          name: "dispatchRatePerSubMessage"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerSub($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerSubMessage,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dispatchRatePerSubMessage", $$v)
                          },
                          expression: "form.dispatchRatePerSubMessage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dispatchRatePerSubPeriod" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("namespace.policy.dispatchRatePerSubPeriod")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputDispatchRatePerSubPeriod"
                          ),
                          name: "dispatchRatePerSubPeriod"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleDispatchRatePerSub($event)
                          }
                        },
                        model: {
                          value: _vm.form.dispatchRatePerSubPeriod,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dispatchRatePerSubPeriod", $$v)
                          },
                          expression: "form.dispatchRatePerSubPeriod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("namespace.policy.subscribeRatePerConsumer"))
                )
              ]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.subscribeRatePerConsumerContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.form, rules: _vm.rules },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "subscribeRatePerConsumerSub" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "namespace.policy.subscribeRatePerConsumerSub"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputSubscribeRate"
                          ),
                          name: "subscribeRatePerConsumerSub"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSubscribeRate($event)
                          }
                        },
                        model: {
                          value: _vm.form.subscribeRatePerConsumerSub,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "subscribeRatePerConsumerSub",
                              $$v
                            )
                          },
                          expression: "form.subscribeRatePerConsumerSub"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "subscribeRatePerConsumerPeriod" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "namespace.policy.subscribeRatePerConsumerPeriod"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputSubscribeRate"
                          ),
                          name: "subscribeRatePerConsumerPeriod"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSubscribeRate($event)
                          }
                        },
                        model: {
                          value: _vm.form.subscribeRatePerConsumerPeriod,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "subscribeRatePerConsumerPeriod",
                              $$v
                            )
                          },
                          expression: "form.subscribeRatePerConsumerPeriod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("namespace.policy.antiAffinity")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.form, rules: _vm.rules },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "antiAffinityGroup" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("namespace.policy.antiAffinityGroup"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.antiAffinityGroupContent,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(" "),
                      _c("md-input", {
                        staticClass: "md-input-style",
                        attrs: {
                          placeholder: _vm.$t(
                            "namespace.policy.inputAntiAffinityGroup"
                          ),
                          name: "antiAffinityGroup"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleAntiAffinityGroup($event)
                          }
                        },
                        model: {
                          value: _vm.form.antiAffinityGroup,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "antiAffinityGroup", $$v)
                          },
                          expression: "form.antiAffinityGroup"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", { staticStyle: { color: "#E57470" } }, [
                _vm._v(_vm._s(_vm.$t("common.dangerZone")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "danger-line" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDeleteNamespace }
                },
                [_vm._v(_vm._s(_vm.$t("namespace.deleteNamespace")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "create"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("topic.topicDomain") } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              model: {
                                value: _vm.form.isPersistent,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "isPersistent", $$v)
                                },
                                expression: "form.isPersistent"
                              }
                            },
                            [
                              _c("el-radio", {
                                attrs: { label: _vm.$t("topic.persistent") }
                              }),
                              _vm._v(" "),
                              _c("el-radio", {
                                attrs: { label: _vm.$t("topic.nonPersistent") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("topic.topicName"),
                            prop: "topic"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.topic,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "topic", $$v)
                              },
                              expression: "form.topic"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("topic.partitions"),
                            prop: "partition"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.partitions,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "partitions", $$v)
                              },
                              expression: "form.partitions"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.createTopic()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "delete"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("namespace.deleteNamespaceMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "delete"
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.deleteNamespace()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogFormVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }