var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c(
            "div",
            { staticClass: "title-container" },
            [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("login.title")))
              ]),
              _vm._v(" "),
              _c("lang-select", { staticClass: "set-language" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("login.username"),
                  name: "username",
                  type: "text",
                  "auto-complete": "on"
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  type: _vm.passwordType,
                  placeholder: _vm.$t("login.password"),
                  name: "password",
                  "auto-complete": "on"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  }
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [_c("svg-icon", { attrs: { "icon-class": "eye" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("login.logIn")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-left": "0" },
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.loginWithCasdoor }
            },
            [_vm._v(_vm._s(_vm.$t("login.casdoor")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("login.thirdparty"),
            visible: _vm.showDialog,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("login.thirdpartyTips")) + "\n    "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("social-sign")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }