import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchClusters, putCluster, fetchClusterConfig, deleteCluster as _deleteCluster } from '@/api/clusters';
import waves from '@/directive/waves'; // Waves directive
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import jsonEditor from '@/components/JsonEditor';
import { validateEmpty, validateServiceUrl } from '@/utils/validate';
import MdInput from '@/components/MDinput';

export default {
  name: 'Clusters',
  components: {
    Pagination: Pagination,
    jsonEditor: jsonEditor,
    MdInput: MdInput
  },
  directives: { waves: waves },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      localList: [],
      peerClustersListOptions: [],
      domainNamesListOptions: [],
      searchList: [],
      total: 0,
      listLoading: true,
      jsonValue: {},
      listQuery: {
        cluster: '',
        page: 1,
        limit: 10
      },
      currentCluster: '',
      currentCommand: '',
      temp: {
        cluster: '',
        serviceUrl: '',
        serviceUrlTls: '',
        brokerServiceUrl: '',
        brokerServiceUrlTls: '',
        peerClusters: [],
        domainNames: '',
        brokerList: '',
        domainName: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        delete: this.$i18n.t('cluster.deleteCluster'),
        create: this.$i18n.t('cluster.addCluster')
      },
      dialogPvVisible: false,
      rules: {
        cluster: [{ required: true, message: this.$i18n.t('cluster.clusterNameIsRequired'), trigger: 'change' }],
        serviceUrl: [{ required: true, message: this.$i18n.t('cluster.serviceUrlIsRequired'), trigger: 'change' }, { validator: validateServiceUrl('http:', false), trigger: 'blur' }],
        serviceUrlTls: [{ validator: validateServiceUrl('https:', true), trigger: 'blur' }],
        brokerServiceUrl: [{ required: true, message: this.$i18n.t('cluster.serviceUrlIsRequired'), trigger: 'change' }, { validator: validateServiceUrl('pulsar:', false), trigger: 'blur' }],
        brokerServiceUrlTls: [{ validator: validateServiceUrl('pulsar+ssl:', true), trigger: 'blur' }],
        domainName: [{ required: true, message: 'domainName is required', trigger: 'change' }],
        domainNames: [{ required: true, message: 'domainNames is required', trigger: 'change' }]
      },
      deleteClusterMessage: this.$i18n.t('cluster.deleteClusterMessage')
    };
  },
  created: function created() {
    this.getClusters();
  },

  methods: {
    getClusters: function getClusters() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0.5 * 1000);
      } else {
        this.listLoading = true;
        fetchClusters(this.listQuery).then(function (response) {
          for (var i = 0; i < response.data.data.length; i++) {
            _this.localList.push({
              'cluster': response.data.data[i]['cluster'],
              'brokers': response.data.data[i]['brokers'],
              'serviceUrl': response.data.data[i]['serviceUrl'],
              'brokerServiceUrl': response.data.data[i]['brokerServiceUrl']
            });
          }
          _this.total = _this.localList.length;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          // Just to simulate the time of the request
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.cluster)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['cluster'].indexOf(this.listQuery.cluster) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    handleGetConfig: function handleGetConfig(row) {
      var _this2 = this;

      this.temp = _Object$assign({}, row); // copy obj
      fetchClusterConfig(this.temp.cluster).then(function (response) {
        _this2.jsonValue = {
          'serviceUrl': response.data.serviceUrl,
          'serviceUrlTls': response.data.serviceUrlTls,
          'brokerServiceUrl': response.data.brokerServiceUrl,
          'brokerServiceUrlTsl': response.data.brokerServiceUrlTls
        };
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getClusters();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        cluster: '',
        serviceUrl: '',
        serviceUrlTls: '',
        brokerServiceUrl: '',
        brokerServiceUrlTls: ''
      };
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['temp'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      putCluster(this.temp.cluster, this.temp).then(function (response) {
        _this4.dialogFormVisible = false;
        _this4.localList = [];
        _this4.getClusters();
        _this4.$notify({
          title: 'success',
          message: _this4.$i18n.t('cluster.addClusterSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    handleOptions: function handleOptions() {
      var _this5 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          switch (_this5.dialogStatus) {
            case 'create':
              _this5.createData();
              break;
          }
        }
      });
    },
    handleCommand: function handleCommand(command) {
      if (this.currentCluster.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any cluster in table',
          type: 'error',
          duration: 2000
        });
        return;
      }
      this.currentCommand = command;
      switch (this.currentCommand) {
        case 'domain-name-create':
          this.handleDomainNameCreate();
          break;
      }
    },
    handleDelete: function handleDelete(row) {
      this.dialogStatus = 'delete';
      this.dialogFormVisible = true;
      this.temp.cluster = row.cluster;
    },
    deleteCluster: function deleteCluster() {
      var _this6 = this;

      _deleteCluster(this.temp.cluster).then(function (response) {
        _this6.$notify({
          title: 'success',
          message: _this6.$i18n.t('cluster.deleteClusterSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this6.dialogFormVisible = false;
        _this6.localList = [];
        _this6.getClusters();
      });
    }
  }
};