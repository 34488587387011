var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: _vm.$t("cluster.label") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "select cluster" },
                      on: {
                        change: function($event) {
                          _vm.getFailureDomainsList()
                        }
                      },
                      model: {
                        value: _vm.postForm.cluster,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "cluster", $$v)
                        },
                        expression: "postForm.cluster"
                      }
                    },
                    _vm._l(_vm.clustersListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: _vm.$t("fd.label") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "select domain" },
                      on: {
                        change: function($event) {
                          _vm.getFailureDomainInfo()
                        }
                      },
                      model: {
                        value: _vm.postForm.failureDomainName,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "failureDomainName", $$v)
                        },
                        expression: "postForm.failureDomainName"
                      }
                    },
                    _vm._l(_vm.failureDomainListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v(_vm._s(_vm.$t("fd.label")))]),
      _vm._v(" "),
      _c(
        "h4",
        [
          _vm._v(_vm._s(_vm.$t("fd.brokerList")) + "\n    "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                content: _vm.brokersContent,
                effect: "dark",
                placement: "top"
              }
            },
            [_c("i", { staticClass: "el-icon-info" })]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "500px", "margin-top": "20px" },
                  attrs: {
                    placeholder: _vm.$t("fd.selectBrokers"),
                    multiple: ""
                  },
                  model: {
                    value: _vm.brokerValue,
                    callback: function($$v) {
                      _vm.brokerValue = $$v
                    },
                    expression: "brokerValue"
                  }
                },
                _vm._l(_vm.brokerOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                })
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.handleSelectBrokers()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("fd.updateFd")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", { staticStyle: { color: "#E57470" } }, [
        _vm._v(_vm._s(_vm.$t("common.dangerZone")))
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "danger-line" }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "button",
          attrs: { type: "danger" },
          on: { click: _vm.handleDelete }
        },
        [_vm._v(_vm._s(_vm.$t("fd.deleteFd")))]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            title: _vm.textMap[_vm.dialogStatus],
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c("el-form", { attrs: { "label-position": "top" } }, [
            _vm.dialogStatus === "delete"
              ? _c(
                  "div",
                  [
                    _c("el-form-item", [
                      _c("h4", [_vm._v(_vm._s(_vm.deleteFdMessage))])
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.deleteDomain()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogFormVisible = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }