var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened
        }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { on: { command: _vm.handleCommand } },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v("\n        " + _vm._s(_vm.currentEnv)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm._l(_vm.environmentsListOptions, function(item, index) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: index,
                        attrs: {
                          command: item,
                          label: item.label,
                          value: item.value
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.value) + "\n        "
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "newEnvironment", divided: "" } },
                    [_vm._v(_vm._s(_vm.$t("env.manageEnvs")))]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item"
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("navbar.size"),
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("size-select", {
                      staticClass: "international right-menu-item"
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("lang-select", {
                  staticClass: "international right-menu-item"
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" }
            },
            [
              _c("span", { staticClass: "avatar-wrapper" }, [
                _vm._v("\n        Admin"),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://github.com/apache/pulsar"
                      }
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.github")) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }