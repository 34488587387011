import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces, getClusters } from '@/api/namespaces';
import { fetchTopicsByPulsarManager, fetchTopicStats, skipOnCluster, expireMessageOnCluster, clearBacklogOnCluster, fetchTopicStatsInternal, resetCursorByTimestampOnCluster, resetCursorByPositionOnCluster } from '@/api/topics';
import { fetchSubscriptions, peekMessagesOnCluster } from '@/api/subscriptions';
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';

var defaultForm = {
  persistent: '',
  tenant: '',
  namespace: '',
  topic: '',
  partition: '',
  subscription: ''
};
var defaultClusterForm = {
  cluster: ''
};
export default {
  name: 'SubscriptionInfo',
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clusterForm: _Object$assign({}, defaultClusterForm),
      subscriptionsListOptions: [],
      replicatedClusters: [],
      topActiveName: 'consumers',
      leftActiveName: '',
      currentTopTabName: 'consumers',
      currentLeftTabName: 'skip',
      tenantsListOptions: [],
      namespacesListOptions: [],
      topicsListOptions: [],
      consumersListLoading: false,
      consumerTableKey: 0,
      consumersList: [],
      consumersTotal: 0,
      consumersListQuery: {
        page: 0,
        limit: 1
      },
      inspectsList: [],
      inspectTableKey: 0,
      inspectListLoading: false,
      tabPosition: 'left',
      form: {
        peekNumMessages: 0,
        skipNumMessages: 0,
        expireNumMessages: 10,
        minutes: '0',
        messagesId: '',
        ledgerValue: ''
      },
      firstInitNamespace: false,
      firstInitTopic: false,
      firstInitSubscription: false,
      ledgerOptions: [],
      partitionsListOptions: [],
      topicPartitions: {},
      partitionDisabled: false
    };
  },
  created: function created() {
    this.postForm.persistent = this.$route.params && this.$route.params.persistent;
    this.postForm.tenant = this.$route.params && this.$route.params.tenant;
    this.postForm.namespace = this.$route.params && this.$route.params.namespace;
    this.postForm.topic = this.$route.params && this.$route.params.topic;
    if (this.postForm.topic.indexOf('-partition-') > 0) {
      var splitTopic = this.postForm.topic.split('-partition-');
      this.postForm.partition = splitTopic[1];
      this.postForm.topic = splitTopic[0];
    } else {
      this.postForm.partition = '-1';
      this.partitionDisabled = true;
    }
    this.tenantNamespaceTopic = this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic;
    this.postForm.subscription = this.$route.params && this.$route.params.subscription;
    this.firstInitNamespace = true;
    this.firstInitTopic = true;
    this.firstInitSubscription = true;
    if (this.$route.query && this.$route.query.topTab) {
      this.topActiveName = this.$route.query.topTab;
      this.currentTopTabName = this.$route.query.topTab;
      if (this.$route.query.leftTab) {
        this.leftActiveName = this.$route.query.leftTab;
      }
    }
    this.getRemoteTenantsList();
    this.getNamespacesList(this.postForm.tenant);
    this.getReplicatedClusters();
    this.getTopicsList();
    this.initTopicStats();
    this.handleStatsInternal();
  },

  methods: {
    generatePartitions: function generatePartitions() {
      var partitions = parseInt(this.topicPartitions[this.postForm.topic]);
      this.partitionsListOptions = [];
      if (partitions > 0) {
        this.partitionDisabled = false;
        if (this.postForm.partition === '-1') {
          this.postForm.partition = '';
        }
        for (var i = 0; i < partitions; i++) {
          this.partitionsListOptions.push(i);
        }
        if (this.firstInitSubscription) {
          this.getSubscriptionsList();
        } else {
          this.subscriptionsListOptions = [];
          this.postForm.subscription = '';
        }
      } else {
        this.partitionDisabled = true;
        this.postForm.partition = '-1';
        this.partitionsListOptions.push('-1');
        this.getSubscriptionsList();
      }
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    getReplicatedClusters: function getReplicatedClusters() {
      var _this2 = this;

      if (this.postForm.tenant && this.postForm.namespace) {
        getClusters(this.postForm.tenant, this.postForm.namespace).then(function (response) {
          if (!response.data) {
            return;
          }
          _this2.replicatedClusters = response.data;
          if (response.data.length > 0) {
            _this2.clusterForm.cluster = _this2.routeCluster || _this2.replicatedClusters[0];
          }
        });
      }
    },
    getCurrentCluster: function getCurrentCluster() {
      return this.clusterForm.cluster || '';
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this3 = this;

      var namespace = [];
      this.namespacesListOptions = [];
      this.topicsListOptions = [];
      this.partitionsListOptions = [];
      this.subscriptionsListOptions = [];
      if (this.firstInitNamespace) {
        this.firstInitNamespace = false;
      } else {
        this.postForm.namespace = '';
        this.postForm.topic = '';
        this.postForm.partition = '';
        this.postForm.subscription = '';
      }
      fetchNamespaces(tenant, this.query).then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.data.length; i++) {
          namespace = response.data.data[i].namespace;
          _this3.namespacesListOptions.push(namespace);
        }
      });
    },
    getTopicsList: function getTopicsList() {
      var _this4 = this;

      this.getReplicatedClusters();
      this.topicsListOptions = [];
      this.partitionsListOptions = [];
      this.subscriptionsListOptions = [];
      if (this.firstInitTopic) {
        this.firstInitTopic = false;
      } else {
        this.postForm.topic = '';
        this.postForm.partition = '';
        this.postForm.subscription = '';
      }
      fetchTopicsByPulsarManager(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
        for (var i in response.data.topics) {
          _this4.topicsListOptions.push(response.data.topics[i]['topic']);
          _this4.topicPartitions[response.data.topics[i]['topic']] = response.data.topics[i]['partitions'];
          if (response.data.topics[i]['topic'] === _this4.postForm.topic) {
            _this4.generatePartitions();
          }
        }
      });
    },
    getSubscriptionsList: function getSubscriptionsList() {
      var _this5 = this;

      fetchSubscriptions(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        if (_this5.firstInitSubscription) {
          _this5.firstInitSubscription = false;
        } else {
          _this5.subscriptionsListOptions = [];
          _this5.postForm.subscription = '';
        }
        for (var i in response.data) {
          _this5.subscriptionsListOptions.push(response.data[i]);
        }
      });
    },
    getSubscriptionsInfo: function getSubscriptionsInfo() {
      this.$router.push({ path: '/management/subscriptions/' + this.postForm.persistent + '/' + this.getFullTopic() + '/' + this.postForm.subscription + '/subscription?topTab=' + this.currentTopTabName + '&leftTab=' + this.currentLeftTabName });
    },
    handleStatsInternal: function handleStatsInternal() {
      var _this6 = this;

      fetchTopicStatsInternal(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        for (var i in response.data.ledgers) {
          _this6.ledgerOptions.push({
            value: response.data.ledgers[i]['ledgerId'],
            label: response.data.ledgers[i]['ledgerId']
          });
        }
      });
    },
    getFullTopic: function getFullTopic() {
      var fullTopic = this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic;
      if (parseInt(this.postForm.partition) >= 0) {
        fullTopic += '-partition-' + this.postForm.partition;
      }
      return fullTopic;
    },
    initTopicStats: function initTopicStats() {
      var _this7 = this;

      fetchTopicStats(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        if (response.data.subscriptions.hasOwnProperty(_this7.postForm.subscription)) {
          var subscription = response.data.subscriptions[_this7.postForm.subscription];
          if (subscription.hasOwnProperty('consumers')) {
            var consumers = subscription['consumers'];
            for (var s in consumers) {
              _this7.consumersList.push({
                'consumerName': consumers[s].consumerName,
                'outMsg': numberFormatter(consumers[s].msgRateOut, 2),
                'outBytes': formatBytes(consumers[s].msgThroughputOut),
                'avgMsgSize': formatBytes(response.data.averageMsgSize),
                'address': consumers[s].address,
                'since': consumers[s].connectedSince
              });
            }
          }
        }
      });
    },
    handleClick: function handleClick(tab, event) {
      this.currentTopTabName = tab.name;
      if (this.currentTopTabName === 'backlogOperation') {
        this.$router.push({ query: { 'topTab': tab.name, 'leftTab': this.currentLeftTabName } });
      } else {
        this.$router.push({ query: { 'topTab': tab.name } });
      }
    },
    handleLeftTabClick: function handleLeftTabClick(tab, event) {
      this.currentLeftTabName = tab.name;
    },
    getConsumers: function getConsumers() {},
    handlePeekMessages: function handlePeekMessages() {
      var _this8 = this;

      if (this.form.peekNumMessages <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.messageGreaterThanZero'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      peekMessagesOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.tenantNamespaceTopic, this.postForm.subscription, this.form.peekNumMessages).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this8.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (!response.data.data) return;
        if (response.data.data.hasOwnProperty('error')) {
          _this8.$notify({
            title: 'error',
            message: _this8.$i18n.t('topic.subscription.peekMessageError'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        _this8.inspectsList = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this8.inspectsList.push({
            'messageId': i,
            'entryId': response.data.data[i].entryId,
            'ledgerId': response.data.data[i].ledgerId,
            'data': window.atob(response.data.data[i].data)
          });
        }
      });
    },
    handleSkipMessages: function handleSkipMessages() {
      var _this9 = this;

      if (this.form.skipNumMessages <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.messageGreaterThanZero'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      skipOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.postForm.subscription, this.form.skipNumMessages).then(function (response) {
        _this9.$notify({
          title: 'success',
          message: _this9.$i18n.t('topic.subscription.messageSkipSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleExpireMessages: function handleExpireMessages() {
      var _this10 = this;

      if (this.form.expireMessages <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.messageGreaterThanZero'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      expireMessageOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.postForm.subscription, this.form.expireNumMessages).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: _this10.$i18n.t('topic.subscription.expireMessageSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleClearBacklog: function handleClearBacklog() {
      var _this11 = this;

      clearBacklogOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.postForm.subscription).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: _this11.$i18n.t('topic.subscription.clearMessageSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleResetCursorByTime: function handleResetCursorByTime() {
      var _this12 = this;

      if (parseInt(this.form.minutes) <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.minutesNotLessThanZero'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      var dateTime = new Date().getTime();
      var timestamp = Math.floor(dateTime) - parseInt(this.form.minutes) * 60 * 1000;
      resetCursorByTimestampOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.postForm.subscription, timestamp).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: _this12.$i18n.t('topic.subscription.resetCursorSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleResetCursorByMessageId: function handleResetCursorByMessageId() {
      var _this13 = this;

      if (this.form.messagesId.length <= 0 && this.form.ledgerValue != null) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.messageIdNotLessThanZero'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      var data = {
        'ledgerId': this.form.ledgerValue,
        'entryId': parseInt(this.form.messagesId)
      };
      resetCursorByPositionOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.postForm.subscription, data).then(function (response) {
        _this13.$notify({
          title: 'success',
          message: _this13.$i18n.t('topic.subscription.resetCursorSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleFilterConsumer: function handleFilterConsumer() {}
  }
};