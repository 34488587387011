var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: "Tenant" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("tenant.selectTenantMessage")
                      },
                      on: {
                        change: function($event) {
                          _vm.getNamespacesList(_vm.postForm.tenant)
                        }
                      },
                      model: {
                        value: _vm.postForm.tenant,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "tenant", $$v)
                        },
                        expression: "postForm.tenant"
                      }
                    },
                    _vm._l(_vm.tenantsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.namespace"), name: "namespaces" } },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t("namespace.searchNamespaces")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilterNamespace($event)
                      }
                    },
                    model: {
                      value: _vm.searchNamespace,
                      callback: function($$v) {
                        _vm.searchNamespace = $$v
                      },
                      expression: "searchNamespace"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.handleFilterNamespace }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleCreateNamespace }
                    },
                    [_vm._v(_vm._s(_vm.$t("namespace.newNamespace")))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading"
                            }
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.listNamespaces,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("namespace.name"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace?tab=overview"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.namespace))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.topicNumber"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace?tab=topics"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.topics))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("table.actions"),
                              align: "center",
                              "class-name": "small-padding fixed-width"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    scope.row.status != "deleted"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleDeleteNamespace(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("table.delete"))
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "temp",
              attrs: {
                model: _vm.temp,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "createNamespace"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("table.namespace"),
                        prop: "namespace"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("namespace.inputNamespaceMessage")
                        },
                        model: {
                          value: _vm.temp.namespace,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "namespace", $$v)
                          },
                          expression: "temp.namespace"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteNamespace"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("namespace.deleteNamespaceMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteTenant"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("tenant.deleteTenantMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.handleOptions()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }