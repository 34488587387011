//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { racksInfo, updateRacksByBookie, deleteRacksByBookie } from '@/api/bookies';
import { validateEmpty } from '@/utils/validate';

export default {
  name: 'Bookies',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      loading: false,
      tableKey: 0,
      list: null,
      listLoading: false,
      localList: [],
      searchList: [],
      total: 0,
      dialogFormVisible: false,
      currentBookie: '',
      dialogStatus: '',
      listQuery: {
        bookie: '',
        page: 1,
        limit: 10
      },
      temp: {
        bookie: '',
        group: '',
        hostname: '',
        rack: ''
      },
      rules: {
        bookie: [{ required: true, message: 'bookie is required', trigger: 'blur' }],
        group: [{ required: true, message: 'group is required', trigger: 'blur' }],
        hostname: [{ required: true, message: 'hostname is required', trigger: 'blur' }],
        rack: [{ required: true, message: 'rack is required', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getBookies();
  },

  methods: {
    getBookies: function getBookies() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0);
      } else {
        this.listLoading = true;
        racksInfo().then(function (response) {
          for (var group in response.data) {
            for (var bookie in response.data[group]) {
              _this.localList.push({
                'bookie': bookie,
                'group': group,
                'rack': response.data[group][bookie]['rack'],
                'hostname': response.data[group][bookie]['hostname']
              });
            }
          }
          _this.total = _this.localList.length;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          // this.localPaging()
          // Just to simulate the time of the request
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.bookie)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['bookie'].indexOf(this.listQuery.bookie) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    getCurrentRow: function getCurrentRow(item) {
      this.currentBookie = item.bookie;
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      this.confirmDelete(row);
    },
    handleFilter: function handleFilter() {
      this.getBookies();
    },
    handleCreate: function handleCreate() {
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.resetTemp();
    },
    handleOptions: function handleOptions() {
      switch (this.dialogStatus) {
        case 'create':
          this.confirmCreateAndUpdate();
          break;
        case 'update':
          this.confirmCreateAndUpdate();
          break;
      }
    },
    confirmCreateAndUpdate: function confirmCreateAndUpdate() {
      var _this2 = this;

      var data = {
        'rack': this.temp.rack,
        'hostname': this.temp.hostname
      };
      updateRacksByBookie(this.temp.bookie, this.temp.group, data).then(function (response) {
        _this2.$notify({
          title: 'success',
          message: 'Create success for bookies',
          type: 'success',
          duration: 3000
        });
        _this2.localList = [];
        _this2.getBookies();
        _this2.dialogFormVisible = false;
      });
    },
    confirmDelete: function confirmDelete(row) {
      var _this3 = this;

      deleteRacksByBookie(row.bookie).then(function (response) {
        _this3.$notify({
          title: 'success',
          message: 'Delete success for bookies',
          type: 'success',
          duration: 3000
        });
        _this3.localList = [];
        _this3.getBookies();
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        bookie: '',
        group: '',
        hostname: '',
        rack: ''
      };
    }
  }
};