var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: "Cluster" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("cluster.selectCluster") },
                      on: {
                        change: function($event) {
                          _vm.getClusterInfo()
                        }
                      },
                      model: {
                        value: _vm.postForm.cluster,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "cluster", $$v)
                        },
                        expression: "postForm.cluster"
                      }
                    },
                    _vm._l(_vm.clustersListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.broker"), name: "brokers" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.brokerTableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.brokersList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("broker.name"),
                              "min-width": "50px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/brokers/" +
                                            scope.row.cluster +
                                            "/" +
                                            scope.row.broker +
                                            "/broker"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.broker))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("fd.failureDomainNumber"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return _vm._l(
                                    scope.row.failureDomain,
                                    function(tag) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: tag,
                                          staticClass: "list-el-tag",
                                          attrs: { effect: "dark" }
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "link-type",
                                              attrs: {
                                                to:
                                                  "/management/clusters/" +
                                                  scope.row.cluster +
                                                  "/" +
                                                  tag +
                                                  "/failureDomainName"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(tag) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  )
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("broker.ownedNamespaceNumber"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.ownedNamespaces))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("broker.msgRate"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.msgRateIn))
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.msgRateOut))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("broker.throughput"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.throughputIn))
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.throughputOut))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.bookie"), name: "bookies" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "padding-right": "8px",
                        "margin-bottom": "30px"
                      },
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.bookieTableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.bookiesList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("bookie.name"),
                              "min-width": "50px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.bookie))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("bookie.state"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.status))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("bookie.storage"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.storage) + "%")
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("tabs.isolationpolicies"),
                name: "isolationPolicies"
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("ip.searchIps") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handlePolicyFilter($event)
                  }
                },
                model: {
                  value: _vm.isolationPolicyKey,
                  callback: function($$v) {
                    _vm.isolationPolicyKey = $$v
                  },
                  expression: "isolationPolicyKey"
                }
              }),
              _vm._v(" "),
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handlePolicyFilter }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreatePolicy }
                },
                [_vm._v(_vm._s(_vm.$t("ip.newIp")))]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.isolationTableKey,
                          staticStyle: { "margin-top": "10px" },
                          attrs: {
                            data: _vm.isolationPoliciesList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("ip.nameLabel"),
                              "min-width": "50px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/clusters/" +
                                            scope.row.cluster +
                                            "/" +
                                            scope.row.isolationPolicy +
                                            "/namespaceIsolationPolicy"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.isolationPolicy)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("ip.numPBLabel"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.numberOfPrimaryBrokers)
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("ip.numSBLabel"),
                              align: "center",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.numberOfSecondaryBrokers
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("table.actions"),
                              align: "center",
                              width: "240",
                              "class-name": "small-padding fixed-width"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/management/clusters/" +
                                            scope.row.cluster +
                                            "/" +
                                            scope.row.isolationPolicy +
                                            "/namespaceIsolationPolicy"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "el-button el-button--primary el-button--medium",
                                        attrs: { type: "danger", size: "mini" },
                                        on: {
                                          click: function($event) {
                                            _vm.handleDeletePolicy(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("tabs.failuredomains"),
                name: "failureDomains"
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("fd.searchFds") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFailureDomainFilter($event)
                  }
                },
                model: {
                  value: _vm.failureDomainsKey,
                  callback: function($$v) {
                    _vm.failureDomainsKey = $$v
                  },
                  expression: "failureDomainsKey"
                }
              }),
              _vm._v(" "),
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFailureDomainFilter }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.newFailureDomain }
                },
                [_vm._v(_vm._s(_vm.$t("fd.newFd")))]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "15px" },
                  attrs: { data: _vm.failureDomainList, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "domain", label: "Domain" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "link-type",
                                attrs: {
                                  to:
                                    "/management/clusters/" +
                                    scope.row.cluster +
                                    "/" +
                                    scope.row.domain +
                                    "/failureDomainName"
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(scope.row.domain))])]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "brokers", label: "Brokers" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.brokers))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("table.actions"),
                      align: "center",
                      "class-name": "small-padding fixed-width"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/management/clusters/" +
                                    scope.row.cluster +
                                    "/" +
                                    scope.row.domain +
                                    "/failureDomainName"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary", size: "mini" } },
                                  [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "el-button el-button--primary el-button--medium",
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.handleDeleteFailureDomain(scope.row)
                                  }
                                }
                              },
                              [_vm._v("Delete")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.config"), name: "config" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            inline: false,
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-position": "top"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("cluster.webServiceUrlPrefix"),
                                prop: "httpServiceUrl"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "http://" },
                                model: {
                                  value: _vm.form.httpServiceUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "httpServiceUrl", $$v)
                                  },
                                  expression: "form.httpServiceUrl"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("cluster.webServiceUrlTlsPrefix"),
                                prop: "httpsServiceUrl"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "https://" },
                                model: {
                                  value: _vm.form.httpsServiceUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "httpsServiceUrl", $$v)
                                  },
                                  expression: "form.httpsServiceUrl"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("cluster.brokerServiceUrlPrefix"),
                                prop: "brokerServiceUrl"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "pulsar://" },
                                model: {
                                  value: _vm.form.brokerServiceUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "brokerServiceUrl", $$v)
                                  },
                                  expression: "form.brokerServiceUrl"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cluster.brokerServiceUrlTlsPrefix"
                                ),
                                prop: "brokerServiceUrlTls"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "pulsar+ssl://" },
                                model: {
                                  value: _vm.form.brokerServiceUrlTls,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "brokerServiceUrlTls",
                                      $$v
                                    )
                                  },
                                  expression: "form.brokerServiceUrlTls"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "button",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleServiceUrl }
                            },
                            [_vm._v(_vm._s(_vm.$t("cluster.updateCluster")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", { staticStyle: { color: "#E57470" } }, [
                _vm._v(_vm._s(_vm.$t("common.dangerZone")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "danger-line" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDelete }
                },
                [_vm._v(_vm._s(_vm.$t("cluster.deleteCluster")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            title: _vm.textMap[_vm.dialogStatus],
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "temp",
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "createFailureDomain"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("fd.name"),
                            prop: "domainName"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.domainName,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "domainName", $$v)
                              },
                              expression: "temp.domainName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("fd.brokerList"),
                            prop: "brokerList"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "254px" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "broker.placeholderSelectBroker"
                                ),
                                multiple: ""
                              },
                              model: {
                                value: _vm.temp.brokerValue,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "brokerValue", $$v)
                                },
                                expression: "temp.brokerValue"
                              }
                            },
                            _vm._l(_vm.brokerOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            })
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.handleCreateFailureDomain()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteCluster"
                ? _c(
                    "div",
                    [
                      _c("el-form-item", [
                        _c("h4", [_vm._v(_vm._s(_vm.deleteClusterMessage))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.deleteCluster()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteDomain"
                ? _c(
                    "div",
                    [
                      _c("el-form-item", [
                        _c("h4", [_vm._v(_vm._s(_vm.deleteFdMessage))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.deleteDomain()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deletePolicy"
                ? _c(
                    "div",
                    [
                      _c("el-form-item", [
                        _c("h4", [_vm._v(_vm._s(_vm.deletePolicyMessage))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.deletePolicy()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }