//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchUsers, putUser, deleteUser as _deleteUser, updateUser as _updateUser } from '@/api/users';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'UsersInfo',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      userList: [],
      userTableKey: 0,
      userListLoading: false,
      textMap: {
        create: this.$i18n.t('user.newUser'),
        delete: this.$i18n.t('user.deleteUser'),
        update: this.$i18n.t('user.updateUser')
      },
      dialogFormVisible: false,
      dialogStatus: '',
      form: {
        name: '',
        description: '',
        email: '',
        phoneNumber: '',
        location: '',
        company: '',
        password: '',
        repeatPassword: ''
      },
      resourceTypeListOptions: [],
      resourceTypeValue: '',
      temp: {
        'name': '',
        'description': '',
        'phoneNumber': '',
        'location': '',
        'company': '',
        'password': ''
      },
      description: '',
      rules: {
        name: [{ required: true, message: this.$i18n.t('user.userNameIsRequired'), trigger: 'blur' }],
        email: [{ required: true, message: this.$i18n.t('user.userEmailIsRequired'), trigger: 'blur' }],
        password: [{ required: true, message: this.$i18n.t('user.userPasswordIsRequired'), trigger: 'blur' }],
        repeatPassword: [{ required: true, message: this.$i18n.t('user.userPasswordIsRequired'), trigger: 'blur' }]
      },
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  created: function created() {
    this.getUsers();
  },

  methods: {
    getUsers: function getUsers() {
      var _this = this;

      fetchUsers(this.listQuery.page, this.listQuery.limit).then(function (response) {
        if (!response.data) return;
        _this.userList = [];
        _this.total = response.data.total;
        for (var i = 0; i < response.data.data.length; i++) {
          _this.userList.push({
            'name': response.data.data[i].name,
            'description': response.data.data[i].description,
            'email': response.data.data[i].email,
            'phoneNumber': response.data.data[i].phoneNumber,
            'location': response.data.data[i].location,
            'company': response.data.data[i].company
          });
        }
      });
    },
    handleCreateUser: function handleCreateUser() {
      this.form.name = '';
      this.form.description = '';
      this.form.email = '';
      this.form.phoneNumber = '';
      this.form.location = '';
      this.form.company = '';
      this.form.password = '';
      this.dialogFormVisible = true;
      this.dialogStatus = 'create';
    },
    handleDeleteUser: function handleDeleteUser(row) {
      this.form.name = row.name;
      this.temp.name = row.name;
      this.temp.description = row.description;
      this.temp.email = row.email;
      this.temp.phoneNumber = row.phoneNumber;
      this.temp.location = row.location;
      this.temp.company = row.company;
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    handleUpdateUser: function handleUpdateUser(row) {
      this.form.name = row.name;
      this.form.description = row.description;
      this.form.email = row.email;
      this.form.phoneNumber = row.phoneNumber;
      this.form.location = row.location;
      this.form.company = row.company;
      this.dialogFormVisible = true;
      this.dialogStatus = 'update';
    },
    handleOptions: function handleOptions() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          switch (_this2.dialogStatus) {
            case 'create':
              _this2.createUser();
              break;
            case 'delete':
              _this2.deleteUser();
              break;
            case 'update':
              _this2.updateUser();
              break;
          }
        }
      });
    },
    createUser: function createUser() {
      var _this3 = this;

      var data = {
        'name': this.form.name,
        'description': this.form.description,
        'email': this.form.email,
        'phoneNumber': this.form.phoneNumber,
        'location': this.form.location,
        'company': this.form.company,
        'password': this.form.password
      };
      if (this.form.password !== this.form.repeatPassword) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('uesr.passwordNotification'),
          type: 'error',
          duration: 2000
        });
        return;
      }
      putUser(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this3.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this3.$notify({
          title: 'success',
          message: _this3.$i18n.t('user.creatUserNotification'),
          type: 'success',
          duration: 2000
        });
        _this3.dialogFormVisible = false;
        _this3.getUsers();
      });
    },
    deleteUser: function deleteUser() {
      var _this4 = this;

      var data = {
        'name': this.temp.name,
        'description': this.temp.description,
        'email': this.temp.email,
        'phoneNumber': this.temp.phoneNumber,
        'location': this.temp.location,
        'company': this.temp.company,
        'password': this.temp.password
      };
      _deleteUser(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this4.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this4.$notify({
          title: 'success',
          message: _this4.$i18n.t('user.deleteUserNotification'),
          type: 'success',
          duration: 2000
        });
        _this4.getUsers();
        _this4.dialogFormVisible = false;
      });
    },
    updateUser: function updateUser() {
      var _this5 = this;

      var data = {
        'name': this.form.name,
        'description': this.form.description,
        'email': this.form.email,
        'phoneNumber': this.form.phoneNumber,
        'location': this.form.location,
        'company': this.form.company,
        'password': this.form.password
      };
      _updateUser(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this5.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('user.updateUserNotification'),
          type: 'success',
          duration: 2000
        });
        _this5.getUsers();
        _this5.dialogFormVisible = false;
      });
    }
  }
};