//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { putToken, fetchTokens, updateToken as _updateToken, deleteToken as _deleteToken, getToken } from '@/api/tokens';

export default {
  name: 'TokensInfo',
  data: function data() {
    return {
      tokenList: [],
      tokenTableKey: 0,
      tokenListLoading: false,
      textMap: {
        create: 'New Token',
        delete: 'Delete Token',
        update: 'Update Token'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      form: {
        token: '',
        role: '',
        description: ''
      },
      temp: {
        'token': '',
        'role': '',
        'description': ''
      },
      description: '',
      rules: {
        token: [{ required: true, message: this.$i18n.t('token.newTokenRequiredMessage'), trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getTokens();
  },

  methods: {
    getTokens: function getTokens() {
      var _this = this;

      fetchTokens().then(function (response) {
        if (!response.data) return;
        _this.tokenList = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this.tokenList.push({
            'token': 'view',
            'role': response.data.data[i].role,
            'description': response.data.data[i].description
          });
        }
      });
    },
    handleCreateToken: function handleCreateToken() {
      this.form.token = '';
      this.form.role = '';
      this.form.description = '';
      this.dialogFormVisible = true;
      this.dialogStatus = 'create';
    },
    handleDeleteToken: function handleDeleteToken(row) {
      this.temp.token = row.token;
      this.temp.role = row.role;
      this.temp.description = row.description;
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    handleUpdateToken: function handleUpdateToken(row) {
      this.form.role = row.role;
      this.form.description = row.description;
      this.dialogFormVisible = true;
      this.dialogStatus = 'update';
    },
    handleOptions: function handleOptions() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          switch (_this2.dialogStatus) {
            case 'create':
              _this2.createToken();
              break;
            case 'delete':
              _this2.deleteToken();
              break;
            case 'update':
              _this2.updateToken();
              break;
          }
        }
      });
    },
    createToken: function createToken() {
      var _this3 = this;

      var data = {
        'role': this.form.role,
        'token': '',
        'description': this.form.description
      };
      putToken(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this3.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this3.$notify({
          title: 'success',
          message: _this3.$i18n.t('token.addTokenSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this3.dialogFormVisible = false;
        _this3.getTokens();
      });
    },
    deleteToken: function deleteToken() {
      var _this4 = this;

      _deleteToken(this.temp.role).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this4.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this4.$notify({
          title: 'success',
          message: _this4.$i18n.t('token.deleteTokenSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this4.getTokens();
        _this4.dialogFormVisible = false;
      });
    },
    updateToken: function updateToken() {
      var _this5 = this;

      var data = {
        'role': this.form.role,
        'description': this.form.description
      };
      _updateToken(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this5.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('token.updateTokenSucccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this5.getTokens();
        _this5.dialogFormVisible = false;
      });
    },
    handleGetToken: function handleGetToken(row) {
      var _this6 = this;

      getToken(row.role).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this6.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this6.form.token = response.data.token;
        _this6.form.role = response.data.role;
        _this6.form.description = response.data.description;
        _this6.dialogFormVisible = true;
        _this6.dialogStatus = 'get';
      });
    }
  }
};