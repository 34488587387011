import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchClusters } from '@/api/clusters';
import { fetchIsolationPolicies, updateIsolationPolicies, deleteIsolationPolicies } from '@/api/isolationPolicies';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import MdInput from '@/components/MDinput';
var defaultForm = {
  cluster: '',
  policy: ''
};
export default {
  name: 'NamespaceIsolationPolicy',
  components: {
    Pagination: Pagination,
    MdInput: MdInput
  },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clustersListOptions: [],
      namespaceContent: this.$i18n.t('ip.namespaceDesc'),
      brokerContent: this.$i18n.t('ip.primaryBrokerDesc'),
      secondaryBrokersContent: this.$i18n.t('ip.secondaryBrokerDesc'),
      policyTypeContent: this.$i18n.t('ip.policyTypeDesc'),
      brokerUsageThresholdContent: this.$i18n.t('ip.brokerUsageThresholdDesc'),
      minimalAvailableBrokerContent: this.$i18n.t('ip.minimalAvailableBrokerDesc'),
      form: {
        namespaces: '',
        broker: '',
        brokerUsageThreshold: '',
        minimalAvailableBroker: ''
      },
      autoFailoverPolicy: 'min_available',
      autoFailoverPolicyOptions: [],
      rules: {},
      policiesListOptions: [],
      primaryDynamicTags: [],
      inputVisiblePrimary: false,
      inputValuePrimary: '',
      namespaceDynamicTags: [],
      inputValueNamespace: '',
      inputVisibleNamespace: false,
      secondaryDynamicTags: [],
      inputVisibleSecondary: false,
      inputValueSecondary: '',
      created: false,
      policyName: '',
      textMap: {
        delete: this.$i18n.t('ip.deletePolicyDialogCaption')
      },
      dialogStatus: '',
      dialogFormVisible: false
    };
  },
  created: function created() {
    this.postForm.cluster = this.$route.params && this.$route.params.cluster;
    this.getClusterList();
    if (this.$route.query && this.$route.query.created) {
      this.created = true;
    } else {
      this.postForm.policy = this.$route.params && this.$route.params.namespaceIsolation;
      this.getPoliciesList();
    }
  },

  methods: {
    getClusterList: function getClusterList() {
      var _this = this;

      fetchClusters().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.data.length; i++) {
          _this.clustersListOptions.push(response.data.data[i].cluster);
        }
      });
    },
    getPoliciesList: function getPoliciesList() {
      var _this2 = this;

      fetchIsolationPolicies(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        for (var key in response.data) {
          _this2.policiesListOptions.push(key);
          if (key === _this2.postForm.policy) {
            _this2.namespaceDynamicTags = response.data[key].namespaces;
            _this2.primaryDynamicTags = response.data[key].primary;
            _this2.secondaryDynamicTags = response.data[key].secondary;
            _this2.autoFailoverPolicyOptions.push({
              value: response.data[key].auto_failover_policy.policy_type,
              label: response.data[key].auto_failover_policy.policy_type
            });
            _this2.form.minimalAvailableBroker = response.data[key].auto_failover_policy.parameters.min_limit;
            _this2.form.brokerUsageThreshold = response.data[key].auto_failover_policy.parameters.usage_threshold;
          }
        }
      });
    },
    handleIsolationPolicy: function handleIsolationPolicy() {
      var _this3 = this;

      var policyName = this.postForm.policy;
      if (this.created) {
        if (this.policyName.length <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.policyNameCannotBeEmpty'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (this.namespaceDynamicTags.length <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.regexCannotBeEmpty'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (this.primaryDynamicTags.length <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.primaryBrokerRegexCannotBeEmpty'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (this.secondaryDynamicTags.length <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.secondaryBrokerRegexCannotBeEmpty'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (this.form.minimalAvailableBroker <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.numLimitShouldGreaterThan0'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        if (this.form.brokerUsageThreshold <= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('ip.usageThresholdShouldGreaterThan0'),
            type: 'error',
            duration: 3000
          });
          return;
        }
        policyName = this.policyName;
      }
      var data = {
        'namespaces': this.namespaceDynamicTags,
        'primary': this.primaryDynamicTags,
        'secondary': this.secondaryDynamicTags,
        'auto_failover_policy': {
          'policy_type': 0,
          'parameters': {
            'min_limit': this.form.minimalAvailableBroker,
            'usage_threshold': this.form.brokerUsageThreshold
          }
        }
      };
      updateIsolationPolicies(this.postForm.cluster, policyName, data).then(function (response) {
        _this3.$notify({
          title: 'success',
          message: _this3.$i18n.t('ip.updatePolicySuccessNotification'),
          type: 'success',
          duration: 3000
        });
        if (_this3.created) {
          _this3.$router.push({ path: '/management/clusters/' + _this3.postForm.cluster + '/cluster?tab=isolationPolicies' });
        }
      });
    },
    handleClosePrimary: function handleClosePrimary(tag) {
      this.primaryDynamicTags.splice(this.primaryDynamicTags.indexOf(tag), 1);
    },
    showInputPrimary: function showInputPrimary() {
      var _this4 = this;

      this.inputVisiblePrimary = true;
      this.$nextTick(function (_) {
        _this4.$refs.saveTagInputPrimary.$refs.input.focus();
      });
    },
    handleInputConfirmPrimary: function handleInputConfirmPrimary() {
      var inputValue = this.inputValuePrimary;
      if (this.primaryDynamicTags.indexOf(inputValue) >= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('ip.primaryBrokerRegexAlreadyExists'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      if (inputValue) {
        this.primaryDynamicTags.push(inputValue);
      }
      this.inputVisiblePrimary = false;
      this.inputValuePrimary = '';
    },
    handleCloseNamespace: function handleCloseNamespace(tag) {
      this.namespaceDynamicTags.splice(this.namespaceDynamicTags.indexOf(tag), 1);
    },
    showInputNamespace: function showInputNamespace() {
      var _this5 = this;

      this.inputVisibleNamespace = true;
      this.$nextTick(function (_) {
        _this5.$refs.saveTagInputNamespace.$refs.input.focus();
      });
    },
    handleInputConfirmNamespace: function handleInputConfirmNamespace() {
      var inputValue = this.inputValueNamespace;
      if (this.namespaceDynamicTags.indexOf(inputValue) >= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('ip.nsRegexAlreadyExists'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      if (inputValue) {
        this.namespaceDynamicTags.push(inputValue);
      }
      this.inputVisibleNamespace = false;
      this.inputValueNamespace = '';
    },
    handleCloseSecondary: function handleCloseSecondary(tag) {
      this.secondaryDynamicTags.splice(this.secondaryDynamicTags.indexOf(tag), 1);
    },
    showInputSecondary: function showInputSecondary() {
      var _this6 = this;

      this.inputVisibleSecondary = true;
      this.$nextTick(function (_) {
        _this6.$refs.saveTagInputSecondary.$refs.input.focus();
      });
    },
    handleInputConfirmSecondary: function handleInputConfirmSecondary() {
      var inputValue = this.inputValueSecondary;
      if (this.secondaryDynamicTags.indexOf(inputValue) >= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('ip.secondaryBrokerRegexAlreadyExists'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      if (inputValue) {
        this.secondaryDynamicTags.push(inputValue);
      }
      this.inputVisibleSecondary = false;
      this.inputValueSecondary = '';
    },
    handleDelete: function handleDelete() {
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    deletePolicy: function deletePolicy() {
      var _this7 = this;

      deleteIsolationPolicies(this.postForm.cluster, this.postForm.policy).then(function (response) {
        _this7.$notify({
          title: 'success',
          message: _this7.$i18n.t('ip.deletePolicySuccessNotification'),
          type: 'success',
          duration: 3000
        });
        _this7.$router.push({ path: '/management/clusters/' + _this7.postForm.cluster + '/cluster?tab=isolationPolicies' });
      });
    }
  }
};