//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LangSelect from '@/components/LangSelect';
import { getCsrfToken } from '@/api/tokens';
import { setCsrfToken } from '@/utils/csrfToken';

export default {
  name: 'Login',
  components: { LangSelect: LangSelect },
  data: function data() {
    // const validateUsername = (rule, value, callback) => {
    //   console.log(isvalidUsername(value))
    //   if (!isvalidUsername(value)) {
    //     callback(new Error('Please enter the correct user name'))
    //   } else {
    //     callback()
    //   }
    // }
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      redirect: undefined
    };
  },

  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }

  },
  created: function created() {
    // window.addEventListener('hashchange', this.afterQRScan)
    this.fetchCsrfToken();
  },
  destroyed: function destroyed() {
    window.removeEventListener('hashchange', this.afterQRScan);
  },
  mounted: function mounted() {
    window.addEventListener('message', this.handleMessage);
  },

  methods: {
    showPwd: function showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    handleMessage: function handleMessage(event) {
      var data = event.data;
      if (data.hasOwnProperty('name') && data.hasOwnProperty('accessToken')) {
        // to do set token, track task https://github.com/apache/pulsar-manager/issues/14
      }
    },
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _this.$store.dispatch('LoginByUsername', _this.loginForm).then(function () {
            _this.loading = false;
            _this.$router.push({ path: _this.redirect || '/management/roles' });
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    afterQRScan: function afterQRScan() {
      // const hash = window.location.hash.slice(1)
      // const hashObj = getQueryObject(hash)
      // const originUrl = window.location.origin
      // history.replaceState({}, '', originUrl)
      // const codeMap = {
      //   wechat: 'code',
      //   tencent: 'code'
      // }
      // const codeName = hashObj[codeMap[this.auth_type]]
      // if (!codeName) {
      //   alert('第三方登录失败')
      // } else {
      //   this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
      //     this.$router.push({ path: '/' })
      //   })
      // }
    },
    fetchCsrfToken: function fetchCsrfToken() {
      getCsrfToken().then(function (response) {
        setCsrfToken(response.headers['x-csrf-token']);
      });
    },
    loginWithCasdoor: function loginWithCasdoor() {
      window.location.href = this.getSigninUrl();
    }
  }
};