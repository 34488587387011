import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchClusters } from '@/api/clusters';
import { fetchBrokerStatsMetrics, fetchBrokerStatsTopics } from '@/api/brokerStats';
import { fetchBrokersHealth, fetchBrokers } from '@/api/brokers';
import { fetchIsolationPolicies } from '@/api/isolationPolicies';
import { unloadBundleOnBroker } from '@/api/namespaces';
import { fetchBrokersRuntimeConfiguration } from '@/api/brokers';
import jsonEditor from '@/components/JsonEditor';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import MdInput from '@/components/MDinput';
import { isValidResponse } from '@/utils/http';
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';

var defaultForm = {
  cluster: '',
  broker: ''
};
export default {
  name: 'BrokerInfo',
  components: {
    Pagination: Pagination,
    MdInput: MdInput,
    jsonEditor: jsonEditor
  },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clustersListOptions: [],
      brokersListOptions: [],
      brokerStats: [],
      bundleTableKey: 0,
      bundleList: [],
      isolationPolicyList: [],
      isolationPolicyTableKey: 0,
      dialogFormVisible: false,
      jsonValue: {},
      firstInit: false
    };
  },
  created: function created() {
    this.postForm.cluster = this.$route.params && this.$route.params.cluster;
    this.postForm.broker = this.$route.params && this.$route.params.broker;
    this.firstInit = true;
    this.getBrokerInfo();
    this.getBrokerStats();
    this.getIsolationPolicy();
    this.getClusterList();
    this.getBrokersList();
  },

  methods: {
    getBrokerInfo: function getBrokerInfo() {
      var _this = this;

      fetchBrokerStatsTopics(this.postForm.broker).then(function (response) {
        if (!response.data) return;
        _this.brokerStatsTopic = response.data;
        if (typeof _this.brokerStatsTopic === 'string') {
          // failed to fetch broker stats
          _this.brokerStatsTopic = {};
          _this.$notify({
            title: 'error',
            message: 'Failed to fetch broker stats from broker ' + _this.postForm.broker,
            type: 'error',
            duration: 3000
          });
        }
        for (var tenantNamespace in _this.brokerStatsTopic) {
          var tn = tenantNamespace.split('/');
          for (var bundle in _this.brokerStatsTopic[tenantNamespace]) {
            var ownedNamespace = {};
            ownedNamespace['tenant'] = tn[0];
            ownedNamespace['namespace'] = tn[1];
            ownedNamespace['bundle'] = bundle;
            _this.bundleList.push(ownedNamespace);
          }
        }
      });
    },
    changeBrokerInfo: function changeBrokerInfo() {
      this.$router.push({ path: '/management/brokers/' + this.postForm.cluster + '/' + this.postForm.broker + '/broker' });
    },
    getBrokerStats: function getBrokerStats() {
      var _this2 = this;

      var throughputIn = 0;
      var throughputOut = 0;
      var bandwidthIn = 0;
      var bandwidthOut = 0;
      fetchBrokerStatsMetrics(this.postForm.broker).then(function (response) {
        for (var m = 0; m < response.data.length; m++) {
          if (response.data[m].dimensions.hasOwnProperty('namespace')) {
            if (response.data[m].dimensions.namespace.split('/').length === 2) {
              throughputIn += response.data[m].metrics.brk_in_tp_rate;
              throughputOut += response.data[m].metrics.brk_out_tp_rate;
              bandwidthIn += response.data[m].metrics.brk_in_rate;
              bandwidthOut += response.data[m].metrics.brk_out_rate;
            }
          }
        }
        _this2.brokerStats.push({
          'inBytes': formatBytes(throughputIn),
          'outBytes': formatBytes(throughputOut),
          'inMsg': numberFormatter(bandwidthIn, 2),
          'outMsg': numberFormatter(bandwidthOut, 2)
        });
      });
    },
    getClusterList: function getClusterList() {
      var _this3 = this;

      fetchClusters(this.listQuery).then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.data.length; i++) {
          _this3.clustersListOptions.push(response.data.data[i].cluster);
        }
      });
    },
    getIsolationPolicy: function getIsolationPolicy() {
      var _this4 = this;

      fetchIsolationPolicies(this.postForm.cluster).then(function (res) {
        var tempIsolationPolicy = [];
        for (var policy in res.data) {
          for (var i in res.data[policy].primary) {
            var regexPrimary = new RegExp(res.data[policy].primary[i]);
            if (regexPrimary.test(_this4.postFormbroker)) {
              if (tempIsolationPolicy.indexOf(policy) < 0) {
                tempIsolationPolicy.push(policy);
              }
            }
          }
          for (var j in res.data[policy].secondary) {
            var regexSecondary = new RegExp(res.data[policy].secondary[j]);
            if (regexSecondary.test(_this4.postFormbroker)) {
              if (tempIsolationPolicy.indexOf(policy) < 0) {
                tempIsolationPolicy.push(policy);
              }
            }
          }
          if (tempIsolationPolicy.indexOf(policy) >= 0) {
            _this4.isolationPolicyList.push({
              'cluster': _this4.postForm.cluster,
              'isolationPolicy': policy,
              'primaryBrokers': res.data[policy].primary.length,
              'secondaryBrokers': res.data[policy].secondary.length
            });
          }
        }
      });
    },
    getBrokersList: function getBrokersList() {
      var _this5 = this;

      fetchBrokers(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        if (_this5.firstInit) {
          _this5.firstInit = false;
        } else {
          _this5.postForm.broker = '';
        }
        _this5.brokersListOptions = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this5.brokersListOptions.push(response.data.data[i].broker);
        }
      });
    },
    handleUnloadBundle: function handleUnloadBundle(row) {
      var _this6 = this;

      unloadBundleOnBroker(this.postForm.broker, row.tenant + '/' + row.namespace, row.bundle).then(function (response) {
        if (isValidResponse(response)) {
          _this6.$notify({
            title: 'success',
            message: 'Successfully unload namespace bundle from the broker',
            type: 'success',
            duration: 3000
          });
        } else {
          _this6.$notify({
            title: 'error',
            message: 'Failed to unload namespace bundle from the broker : ' + response.data,
            type: 'error',
            duration: 3000
          });
        }
      });
    },
    handleHeartBeat: function handleHeartBeat() {
      var _this7 = this;

      fetchBrokersHealth(this.postForm.broker).then(function (response) {
        if (isValidResponse(response)) {
          _this7.$notify({
            title: 'success',
            message: 'Health Check success',
            type: 'success',
            duration: 3000
          });
        } else {
          _this7.$notify({
            title: 'error',
            message: 'Health Check failed: \n' + response.data,
            type: 'error',
            duration: 3000
          });
        }
      });
    },
    handleRuntimeConfig: function handleRuntimeConfig() {
      var _this8 = this;

      fetchBrokersRuntimeConfiguration(this.postForm.broker).then(function (response) {
        _this8.dialogFormVisible = true;
        _this8.jsonValue = response.data;
      });
    }
  }
};