import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchTenants, fetchTenantsInfo } from '@/api/tenants';
import { fetchNamespaces, fetchNamespacePolicies, getPermissions, getPersistence, getRetention, setClusters, grantPermissions, revokePermissions, setSubscriptionAuthMode, setPersistence, setBacklogQuota, setEncryptionRequired, setDeduplication, setSchemaAutoupdateStrategy, setSchemaValidationEnforced, setMessageTtl, setRetention, setCompactionThreshold, setOffloadThreshold, setOffloadDeletionLag, setMaxProducersPerTopic, setMaxConsumersPerTopic, setMaxConsumersPerSubscription, setDispatchRate, setSubscriptionDispatchRate, setSubscribeRate, setAntiAffinityGroup, splitBundleOnCluster, unloadBundleOnCluster, unloadOnCluster, clearBacklogOnCluster, deleteNamespace as _deleteNamespace, clearBundleBacklogOnCluster, fetchNamespaceStats } from '@/api/namespaces';
import { putTopic, fetchTopicsStatsByPulsarManager } from '@/api/topics';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import MdInput from '@/components/MDinput';
import { validateEmpty } from '@/utils/validate';
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';

var defaultForm = {
  tenant: '',
  namespace: ''
};
export default {
  name: 'NamespaceInfo',
  components: {
    MdInput: MdInput,
    Pagination: Pagination
  },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      tenantNamespace: '',
      tenantsListOptions: [],
      namespacesListOptions: [],
      activeName: 'overview',
      activeBundleCluster: '',
      namespaceStats: [{
        inMsg: 0,
        outMsg: 0,
        inBytes: 0,
        outBytes: 0
      }],
      replicatedClustersContent: this.$i18n.t('namespace.policy.replicatedClustersContent'),
      replicationClustersValue: [],
      replicationClustersOptions: [],
      dynamicValidateForm: {
        domains: [{
          value: ''
        }],
        email: ''
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      roleValue: [],
      roleMap: {},
      roleMapOptions: {},
      roleOptions: [{
        value: 'consume',
        label: this.$i18n.t('role_actions.consume')
      }, {
        value: 'produce',
        label: this.$i18n.t('role_actions.produce')
      }, {
        value: 'functions',
        label: this.$i18n.t('role_actions.functions')
      }],
      authorizationContent: this.$i18n.t('namespace.policy.authorizationContent'),
      subscriptionAuthenticationMode: '',
      subscriptionAuthenticationModeContent: this.$i18n.t('namespace.policy.subscriptionAuthenticationModeContent'),
      subscriptionAuthenticationModeOptions: [{
        value: 'None',
        label: this.$i18n.t('subscription_auth_mode.none')
      }, {
        value: 'Prefix',
        label: this.$i18n.t('subscription_auth_mode.prefix')
      }],
      replicationFactorContent: this.$i18n.t('namespace.policy.replicationFactorContent'),
      form: {
        ensembleSize: '',
        writeQuorumSize: '',
        readQuorumSize: '',
        markDeleteMaxRate: '',
        backlogQuotasLimit: '',
        backlogRententionPolicy: '',
        encryptionRequire: '',
        deduplication: '',
        autoUpdateStrategy: '',
        messageTTL: '',
        retentionTime: '',
        encryptionRequireRadio: this.$i18n.t('common.disabled'),
        deduplicationRadio: this.$i18n.t('common.disabled'),
        schemaValidationEnforcedRadio: this.$i18n.t('common.disabled'),
        dispatchRatePerTopicBytes: '',
        dispatchRatePerTopicMessage: '',
        dispatchRatePerTopicPeriod: '',
        dispatchRatePerSubBytes: '',
        antiAffinityGroup: '',
        dispatchRatePerSubMessage: '',
        dispatchRatePerSubPeriod: '',
        subscribeRatePerConsumerSub: '',
        subscribeRatePerConsumerPeriod: '',
        maxConsumerPerSub: '',
        topic: '',
        isPersistent: 'Persistent',
        partitions: 0
      },
      rules: {
        topic: [{ required: true, message: 'topic is required', trigger: 'blur' }]
      },
      markDeleteRateContent: this.$i18n.t('namespace.policy.markDeleteRateContent'),
      backlogQuotasLimitContent: this.$i18n.t('namespace.policy.backlogQuotasLimitContent'),
      backlogRententionPolicyContent: this.$i18n.t('namespace.policy.backlogRententionPolicyContent'),
      backlogRententionPolicyRadio: 'consumer_backlog_eviction',
      backlogRententionPolicyOption: [{
        value: 'consumer_backlog_eviction',
        label: this.$i18n.t('backlog_policy.consumer_backlog_eviction')
      }, {
        value: 'producer_exception',
        label: this.$i18n.t('backlog_policy.producer_exception')
      }, {
        value: 'producer_request_hold',
        label: this.$i18n.t('backlog_policy.producer_request_hold')
      }],
      encryptionRequireContent: this.$i18n.t('namespace.policy.encryptionRequireContent'),
      encryptionRequireOption: [{
        value: 'Enabled',
        label: this.$i18n.t('common.enabled')
      }, {
        value: 'Disabled',
        label: this.$i18n.t('common.disabled')
      }],
      deduplicationContent: this.$i18n.t('namespace.policy.deduplicationContent'),
      deduplication: '',
      deduplicationOption: [{
        value: 'Enabled',
        label: this.$i18n.t('common.enabled')
      }, {
        value: 'Disabled',
        label: this.$i18n.t('common.disabled')
      }],
      autoUpdateStrategyContent: this.$i18n.t('namespace.policy.autoUpdateStrategyContent'),
      autoUpdateStrategyOption: [{
        value: 'Full',
        label: this.$i18n.t('schema_bc.full')
      }, {
        value: 'FullTransitive',
        label: this.$i18n.t('schema_bc.full_transitive')
      }, {
        value: 'Forward',
        label: this.$i18n.t('schema_bc.forward')
      }, {
        value: 'ForwardTransitive',
        label: this.$i18n.t('schema_bc.forward_transitive')
      }, {
        value: 'Backward',
        label: this.$i18n.t('schema_bc.backward')
      }, {
        value: 'BackwardTransitive',
        label: this.$i18n.t('schema_bc.backward_transitive')
      }, {
        value: 'AlwaysCompatible',
        label: this.$i18n.t('schema_bc.always')
      }, {
        value: 'AutoUpdateDisabled',
        label: this.$i18n.t('schema_bc.auto_update_disabled')
      }],
      schemaValidationEnforcedContent: this.$i18n.t('namespace.policy.schemaValidationEnforcedContent'),
      schemaValidationEnforced: '',
      messageTTLContent: this.$i18n.t('namespace.policy.messageTTLContent'),
      retentionSizeContent: this.$i18n.t('namespace.policy.retentionSizeContent'),
      retentionTimeContent: this.$i18n.t('namespace.policy.retentionTimeContent'),
      compactionThresholdContent: this.$i18n.t('namespace.policy.compactionThresholdContent'),
      offloadThresholdContent: this.$i18n.t('namespace.policy.offloadThresholdContent'),
      offloadDeletionLagContent: this.$i18n.t('namespace.policy.offloadDeletionLagContent'),
      maxProducersPerTopicContent: this.$i18n.t('namespace.policy.maxProducersPerTopicContent'),
      maxConsumersPerTopicContent: this.$i18n.t('namespace.policy.maxConsumersPerTopicContent'),
      maxConsumerPerSubContent: this.$i18n.t('namespace.policy.maxConsumerPerSubContent'),
      dispatchRatePerTopicContent: this.$i18n.t('namespace.policy.dispatchRatePerTopicContent'),
      dispatchRatePerSubContent: this.$i18n.t('namespace.policy.dispatchRatePerSubContent'),
      subscribeRatePerConsumerContent: this.$i18n.t('namespace.policy.subscribeRatePerConsumerContent'),
      antiAffinityGroupContent: this.$i18n.t('namespace.policy.antiAffinityGroupContent'),
      tableKey: 0,
      topicsListLoading: false,
      topicsTableKey: 0,
      brokerStats: null,
      topics: {},
      localList: [],
      tempTopicsList: [],
      topicsList: [],
      allTopicList: [],
      topicsTotal: 0,
      // total: 0,
      topicsListQuery: {
        page: 1,
        limit: 10
      },
      firstInit: false,
      searchTopic: '',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        create: this.$i18n.t('topic.newTopic'),
        delete: this.$i18n.t('namespace.deleteNamespace')
      },
      currentTabName: '',
      bundleInfoContent: this.$i18n.t('namespace.bundle.bundleInfoContent')
    };
  },
  created: function created() {
    this.postForm.tenant = this.$route.params && this.$route.params.tenant;
    this.postForm.namespace = this.$route.params && this.$route.params.namespace;
    this.tenantNamespace = this.postForm.tenant + '/' + this.postForm.namespace;
    this.firstInit = true;
    if (this.$route.query && this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
      this.currentTabName = this.$route.query.tab;
    }
    this.getRemoteTenantsList();
    this.getNamespacesList(this.postForm.tenant);
    this.getTenantsInfo(this.postForm.tenant);
    this.getPolicies(this.tenantNamespace);
    this.initPermissions(this.tenantNamespace);
    this.initPersistence(this.tenantNamespace);
    this.initRetention(this.tenantNamespace);
    this.getStats();
    this.getTopicsStats();
    this.activeBundleCluster = this.replicationClustersValue.length > 0 ? this.replicationClustersValue[0] : '';
  },

  methods: {
    getNamespaceStats: function getNamespaceStats() {
      var _this = this;

      fetchNamespaceStats(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
        _this.namespaceStats = [];
        if (response.data.hasOwnProperty('inMsg')) {
          _this.namespaceStats.push({
            inMsg: numberFormatter(response.data.inMsg, 2),
            outMsg: numberFormatter(response.data.outMsg, 2),
            inBytes: formatBytes(response.data.msgThroughputIn),
            outBytes: formatBytes(response.data.msgThroughputOut)
          });
        }
      });
    },
    getStats: function getStats() {
      this.getNamespaceStats();
      this.getTopics();
    },
    getTopicsStats: function getTopicsStats() {
      fetchTopicsStatsByPulsarManager(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
      });
    },
    getTopics: function getTopics() {
      var _this2 = this;

      fetchTopicsStatsByPulsarManager(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
        for (var i in response.data.topics) {
          var topicLink = '';
          if (response.data.topics[i]['partitions'] === 0) {
            topicLink = '/management/topics/' + response.data.topics[i]['persistent'] + '/' + _this2.tenantNamespace + '/' + response.data.topics[i]['topic'] + '/topic';
          } else {
            topicLink = '/management/topics/' + response.data.topics[i]['persistent'] + '/' + _this2.tenantNamespace + '/' + response.data.topics[i]['topic'] + '/partitionedTopic';
          }
          var children = [];
          var clusters = response.data.topics[i]['clusters'];
          for (var j in clusters) {
            var clusterTopicInfo = {
              'id': 1000000 * (i + 1) + j,
              'topic': clusters[j]['topic'],
              'partitions': clusters[j]['partitions'],
              'persistent': clusters[j]['persistent'],
              'producers': clusters[j]['producerCount'],
              'subscriptions': clusters[j]['subscriptionCount'],
              'inMsg': numberFormatter(clusters[j]['msgRateIn'], 2),
              'outMsg': numberFormatter(clusters[j]['msgRateOut'], 2),
              'inBytes': formatBytes(clusters[j]['msgThroughputIn']),
              'outBytes': formatBytes(clusters[j]['msgThroughputOut']),
              'storageSize': formatBytes(clusters[j]['storageSize'], 0),
              'tenantNamespace': _this2.tenantNamespace,
              'topicLink': topicLink + '?cluster=' + clusters[j]['topic'] + '&tab='
            };
            children.push(clusterTopicInfo);
          }

          if (clusters.length <= 0) {
            var tempCluster = {
              'id': 1000000 * (i + 1),
              'topic': '-',
              'partitions': _this2.form.partitions,
              'persistent': _this2.form.isPersistent,
              'producers': 0,
              'subscriptions': 0,
              'inMsg': 0,
              'outMsg': 0,
              'inBytes': 0,
              'outBytes': 0,
              'storageSize': 0,
              'tenantNamespace': _this2.tenantNamespace,
              'topicLink': topicLink
            };
            if (_this2.replicationClustersValue.length <= 0) {
              children.push(tempCluster);
            }
            for (var c in _this2.replicationClustersValue) {
              tempCluster.topic = _this2.replicationClustersValue[c];
              children.push(tempCluster);
            }
          }

          var topicInfo = {
            'id': i,
            'topic': response.data.topics[i]['topic'],
            'partitions': response.data.topics[i]['partitions'],
            'persistent': response.data.topics[i]['persistent'],
            'producers': response.data.topics[i]['producers'],
            'subscriptions': response.data.topics[i]['subscriptions'],
            'inMsg': numberFormatter(response.data.topics[i]['inMsg'], 2),
            'outMsg': numberFormatter(response.data.topics[i]['outMsg'], 2),
            'inBytes': formatBytes(response.data.topics[i]['inBytes']),
            'outBytes': formatBytes(response.data.topics[i]['outBytes']),
            'storageSize': formatBytes(response.data.topics[i]['storageSize'], 0),
            'children': children,
            'tenantNamespace': _this2.tenantNamespace,
            'topicLink': topicLink + '?tab='
          };
          _this2.topicsListLoading = false;
          _this2.topicsList.push(topicInfo);
          _this2.allTopicList.push(topicInfo);
        }
      });
    },
    handleFilterTopic: function handleFilterTopic() {
      if (this.tempTopicsList.length <= 0) {
        for (var t = 0; t < this.allTopicList.length; t++) {
          this.tempTopicsList.push(this.allTopicList[t]);
        }
      }
      if (!validateEmpty(this.searchTopic)) {
        this.searchList = [];
        for (var i = 0; i < this.allTopicList.length; i++) {
          if (this.allTopicList[i]['topic'].indexOf(this.searchTopic) !== -1) {
            this.searchList.push(this.allTopicList[i]);
          }
        }
        this.topicsList = this.searchList;
      } else {
        this.topicsList = this.tempTopicsList;
      }
    },
    getPolicies: function getPolicies(tenantNamespace) {
      var _this3 = this;

      fetchNamespacePolicies(tenantNamespace).then(function (response) {
        if (!response.data) return;
        _this3.initPoliciesOptions(response.data);
      });
    },
    initPersistence: function initPersistence(tenantNamespace) {
      var _this4 = this;

      getPersistence(tenantNamespace).then(function (response) {
        if (!response.data) return;
        _this4.form.ensembleSize = String(response.data.bookkeeperEnsemble);
        _this4.form.writeQuorumSize = String(response.data.bookkeeperWriteQuorum);
        _this4.form.readQuorumSize = String(response.data.bookkeeperAckQuorum);
        _this4.form.markDeleteMaxRate = String(response.data.managedLedgerMaxMarkDeleteRate);
      });
    },
    initPermissions: function initPermissions(tenantNamespace) {
      var _this5 = this;

      getPermissions(tenantNamespace).then(function (response) {
        if (!response.data) return;
        for (var key in response.data) {
          _this5.dynamicTags.push(key);
          _this5.roleMap[key] = response.data[key];
          _this5.roleMapOptions[key] = _this5.roleOptions;
        }
      });
    },
    initRetention: function initRetention(tenantNamespace) {
      var _this6 = this;

      getRetention(tenantNamespace).then(function (response) {
        if (!response.data) return;
        _this6.form.retentionSize = String(response.data.retentionSizeInMB);
        _this6.form.retentionTime = String(response.data.retentionTimeInMinutes);
      });
    },
    getTenantsInfo: function getTenantsInfo(tenant) {
      var _this7 = this;

      fetchTenantsInfo(tenant).then(function (response) {
        _this7.replicationClustersOptions = [];
        for (var i = 0; i < response.data.allowedClusters.length; i++) {
          _this7.replicationClustersOptions.push({
            value: response.data.allowedClusters[i],
            label: response.data.allowedClusters[i]
          });
        }
      });
    },
    initPoliciesOptions: function initPoliciesOptions(policies) {
      this.replicationClustersValue = policies.replication_clusters;
      this.activeBundleCluster = this.activeBundleCluster || this.replicationClustersValue.length > 0 ? this.replicationClustersValue[0] : '';
      this.subscriptionAuthenticationMode = policies.subscription_auth_mode;
      if (policies.backlog_quota_map.destination_storage) {
        this.form.backlogQuotasLimit = String(policies.backlog_quota_map.destination_storage.limit);
        this.form.backlogRententionPolicy = String(policies.backlog_quota_map.destination_storage.policy);
      }
      if (policies.encryption_required) {
        this.form.encryptionRequireRadio = 'Enabled';
      }
      if (policies.hasOwnProperty('deduplicationRadio')) {
        this.form.deduplicationRadio = policies.deduplicationRadio;
      }
      this.form.autoUpdateStrategy = policies.schema_auto_update_compatibility_strategy;
      if (policies.schema_validation_enforced) {
        this.form.schemaValidationEnforcedRadio = 'Enabled';
      }
      this.form.messageTTL = String(policies.message_ttl_in_seconds);
      this.form.compactionThreshold = String(policies.compaction_threshold);
      this.form.offloadThreshold = String(policies.offload_threshold);
      if (policies.hasOwnProperty('offload_deletion_lag_ms')) {
        this.form.offloadDeletionLag = String(policies.offload_deletion_lag_ms);
      }
      this.form.maxProducersPerTopic = String(policies.max_producers_per_topic);
      this.form.maxConsumersPerTopic = String(policies.max_consumers_per_topic);
      this.form.maxConsumerPerSub = String(policies.max_consumers_per_subscription);
      for (var t in policies.topicDispatchRate) {
        this.form.dispatchRatePerTopicMessage = String(policies.topicDispatchRate[t].dispatchThrottlingRateInMsg);
        this.form.dispatchRatePerTopicBytes = String(policies.topicDispatchRate[t].dispatchThrottlingRateInByte);
        this.form.dispatchRatePerTopicPeriod = String(policies.topicDispatchRate[t].ratePeriodInSecond);
      }
      for (var s in policies.subscriptionDispatchRate) {
        this.form.dispatchRatePerSubBytes = String(policies.subscriptionDispatchRate[s].dispatchThrottlingRateInByte);
        this.form.dispatchRatePerSubMessage = String(policies.subscriptionDispatchRate[s].dispatchThrottlingRateInMsg);
        this.form.dispatchRatePerSubPeriod = String(policies.subscriptionDispatchRate[s].ratePeriodInSecond);
      }
      for (var c in policies.clusterSubscribeRate) {
        this.form.subscribeRatePerConsumerSub = String(policies.clusterSubscribeRate[c].subscribeThrottlingRatePerConsumer);
        this.form.subscribeRatePerConsumerPeriod = String(policies.clusterSubscribeRate[c].ratePeriodInSecond);
      }
      if (policies.hasOwnProperty('antiAffinityGroup')) {
        this.form.antiAffinityGroup = policies.antiAffinityGroup;
      }
      // this.listQuery.limit = policies.bundles.numBundles
      // this.listQuery.page = 1
      // this.total = policies.bundles.numBundles
      for (var n = 0; n < policies.bundles.numBundles; n++) {
        this.localList.push({ 'bundle': policies.bundles.boundaries[n] + '_' + policies.bundles.boundaries[n + 1] });
      }
    },
    handleClick: function handleClick(tab, event) {
      this.currentTabName = tab.name;
      this.$router.push({ query: { 'tab': tab.name } });
    },
    handleBundleTabClick: function handleBundleTabClick(tab, event) {
      this.activeBundleCluster = tab.name;
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this8 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this8.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this9 = this;

      fetchNamespaces(tenant, this.query).then(function (response) {
        var namespace = [];
        _this9.namespacesListOptions = [];
        if (_this9.firstInit) {
          _this9.firstInit = false;
        } else {
          _this9.postForm.namespace = '';
        }
        for (var i = 0; i < response.data.data.length; i++) {
          namespace = response.data.data[i].namespace;
          _this9.namespacesListOptions.push(namespace);
        }
      });
    },
    getNamespaceInfo: function getNamespaceInfo(tenant, namespace) {
      this.$router.push({ path: '/management/namespaces/' + tenant + '/' + namespace + '/namespace?tab=' + this.currentTabName });
    },
    handleFilterBundle: function handleFilterBundle() {},
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert('submit!');
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose: function handleClose(tag) {
      var _this10 = this;

      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      revokePermissions(this.tenantNamespace, tag).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: _this10.$i18n.t('namespace.notification.removeRoleSuccess'),
          type: 'success',
          duration: 3000
        });
        delete _this10.roleMap[tag];
        delete _this10.roleMapOptions[tag];
      });
    },
    showInput: function showInput() {
      var _this11 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this11.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var _this12 = this;

      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.roleMap.hasOwnProperty(inputValue)) {
          this.$message({
            message: this.$i18n.t('namespace.policy.roleAlreadyExists'),
            type: 'error'
          });
          this.inputVisible = false;
          this.inputValue = '';
          return;
        }
        grantPermissions(this.tenantNamespace, inputValue, this.roleMap[inputValue]).then(function (response) {
          _this12.$notify({
            title: 'success',
            message: _this12.$i18n.t('namespace.notification.addRoleSuccess'),
            type: 'success',
            duration: 3000
          });
          _this12.dynamicTags.push(inputValue);
          _this12.roleMap[inputValue] = [];
          _this12.roleMapOptions[inputValue] = _this12.roleOptions;
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleChangeOptions: function handleChangeOptions(role) {
      var _this13 = this;

      grantPermissions(this.tenantNamespace, role, this.roleMap[role]).then(function (response) {
        _this13.$notify({
          title: 'success',
          message: _this13.$i18n.t('namespace.notification.addRoleActionsSuccess'),
          type: 'success',
          duration: 3000
        });
      });
      this.$forceUpdate();
    },
    revokeAllRole: function revokeAllRole() {},
    handlePersistence: function handlePersistence() {
      var _this14 = this;

      var data = {
        'bookkeeperAckQuorum': parseInt(this.form.readQuorumSize),
        'bookkeeperEnsemble': parseInt(this.form.ensembleSize),
        'bookkeeperWriteQuorum': parseInt(this.form.writeQuorumSize),
        'managedLedgerMaxMarkDeleteRate': parseInt(this.form.markDeleteMaxRate)
      };
      setPersistence(this.tenantNamespace, data).then(function (response) {
        _this14.$notify({
          title: 'success',
          message: _this14.$i18n.t('namespace.notification.updatePersistenceSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleBacklogQuota: function handleBacklogQuota() {
      var _this15 = this;

      var data = {
        'limit': this.form.backlogQuotasLimit,
        'policy': this.form.backlogRententionPolicy
      };
      setBacklogQuota(this.tenantNamespace, data).then(function (response) {
        _this15.$notify({
          title: 'success',
          message: _this15.$i18n.t('namespace.notification.updateBacklogQuotaSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleSplitBundle: function handleSplitBundle(row) {
      var _this16 = this;

      splitBundleOnCluster(this.activeBundleCluster, this.tenantNamespace, row.bundle, false).then(function (response) {
        _this16.$notify({
          title: 'success',
          message: _this16.$i18n.t('namespace.notification.splitBundleSuccess'),
          type: 'success',
          duration: 3000
        });
        _this16.localList = [];
        _this16.getPolicies(_this16.tenantNamespace);
      });
    },
    handleUnloadAll: function handleUnloadAll() {
      var _this17 = this;

      unloadOnCluster(this.activeBundleCluster, this.tenantNamespace).then(function (response) {
        _this17.$notify({
          title: 'success',
          message: _this17.$i18n.t('namespace.notification.unloadAllBundlesSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleUnloadBundle: function handleUnloadBundle(row) {
      var _this18 = this;

      unloadBundleOnCluster(this.activeBundleCluster, this.tenantNamespace, row.bundle).then(function (response) {
        _this18.$notify({
          title: 'success',
          message: _this18.$i18n.t('namespace.notification.unloadBundleSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    hanldeClearAllBacklog: function hanldeClearAllBacklog() {
      var _this19 = this;

      clearBacklogOnCluster(this.activeBundleCluster, this.tenantNamespace).then(function (response) {
        _this19.$notify({
          title: 'success',
          message: _this19.$i18n.t('namespace.notification.clearBacklogSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleClearBundleBacklog: function handleClearBundleBacklog(row) {
      var _this20 = this;

      clearBundleBacklogOnCluster(this.activeBundleCluster, this.tenantNamespace, row.bundle).then(function (response) {
        _this20.$notify({
          title: 'success',
          message: _this20.$i18n.t('namespace.notification.clearBundleBacklogSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleReplicationsClusters: function handleReplicationsClusters() {
      var _this21 = this;

      setClusters(this.tenantNamespace, this.replicationClustersValue).then(function (response) {
        _this21.$notify({
          title: 'success',
          message: _this21.$i18n.t('namespace.notification.updateReplicatedClustersSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleSubscriptionAuthMode: function handleSubscriptionAuthMode() {
      var _this22 = this;

      var subAuthMode = 0;
      if (this.subscriptionAuthenticationMode === 'Prefix') {
        subAuthMode = 1;
      }
      setSubscriptionAuthMode(this.tenantNamespace, subAuthMode).then(function (response) {
        _this22.$notify({
          title: 'success',
          message: _this22.$i18n.t('namespace.notification.updateSubscriptionAuthModeSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleEncryption: function handleEncryption() {
      var _this23 = this;

      var encryptionRequire = false;
      var successMessage = this.$i18n.t('namespace.notification.disableEncryptionSuccess');
      if (this.form.encryptionRequireRadio === 'Enabled') {
        encryptionRequire = true;
        successMessage = this.$i18n.t('namespace.notification.enableEncryptionSuccess');
      }
      setEncryptionRequired(this.tenantNamespace, encryptionRequire).then(function (response) {
        _this23.$notify({
          title: 'success',
          message: successMessage,
          type: 'success',
          duration: 3000
        });
      });
    },
    handleDeduplication: function handleDeduplication() {
      var _this24 = this;

      var deduplication = false;
      var successMessage = this.$i18n.t('namespace.notification.disableDeduplicationSuccess');
      if (this.form.deduplicationRadio === 'Enabled') {
        deduplication = true;
        successMessage = this.$i18n.t('namespace.notification.enableDeduplicationSuccess');
      }
      setDeduplication(this.tenantNamespace, deduplication).then(function (response) {
        _this24.$notify({
          title: 'success',
          message: successMessage,
          type: 'success',
          duration: 3000
        });
      });
    },
    handleSchemaAutoUpdateStrategy: function handleSchemaAutoUpdateStrategy() {
      var _this25 = this;

      var strategy = 3;
      if (this.form.autoUpdateStrategy === 'AutoUpdateDisabled') {
        strategy = 0;
      } else if (this.form.autoUpdateStrategy === 'Backward') {
        strategy = 1;
      } else if (this.form.autoUpdateStrategy === 'Forward') {
        strategy = 2;
      } else if (this.form.autoUpdateStrategy === 'Full') {
        strategy = 3;
      } else if (this.form.autoUpdateStrategy === 'AlwaysCompatible') {
        strategy = 4;
      } else if (this.form.autoUpdateStrategy === 'BackwardTransitive') {
        strategy = 5;
      } else if (this.form.autoUpdateStrategy === 'ForwardTransitive') {
        strategy = 6;
      } else if (this.form.autoUpdateStrategy === 'FullTransitive') {
        strategy = 7;
      }
      setSchemaAutoupdateStrategy(this.tenantNamespace, strategy).then(function (response) {
        _this25.$notify({
          title: 'success',
          message: _this25.$i18n.t('namespace.notification.updateSchemaAutoUpdateStrategySuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleSchemaValidationEnforced: function handleSchemaValidationEnforced() {
      var _this26 = this;

      var schemaValidation = false;
      var successMessage = this.$i18n.t('namespace.notification.disableSchemaValidationEnforcedSuccess');
      if (this.form.schemaValidationEnforcedRadio === 'Enabled') {
        schemaValidation = true;
        successMessage = this.$i18n.t('namespace.notification.enableSchemaValidationEnforcedSuccess');
      }
      setSchemaValidationEnforced(this.tenantNamespace, schemaValidation).then(function (response) {
        _this26.$notify({
          title: 'success',
          message: successMessage,
          type: 'success',
          duration: 3000
        });
      });
    },
    handleMessageTTL: function handleMessageTTL() {
      var _this27 = this;

      setMessageTtl(this.tenantNamespace, parseInt(this.form.messageTTL)).then(function (response) {
        _this27.$notify({
          title: 'success',
          message: _this27.$i18n.t('namespace.notification.updateMessageTTLSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleRetention: function handleRetention() {
      var _this28 = this;

      var data = { 'retentionSizeInMB': parseInt(this.form.retentionSize), 'retentionTimeInMinutes': parseInt(this.form.retentionTime) };
      setRetention(this.tenantNamespace, data).then(function (response) {
        _this28.$notify({
          title: 'success',
          message: _this28.$i18n.t('namespace.notification.updateRetentionSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleCompactionThreshold: function handleCompactionThreshold() {
      var _this29 = this;

      setCompactionThreshold(this.tenantNamespace, this.form.compactionThreshold).then(function (response) {
        _this29.$notify({
          title: 'success',
          message: _this29.$i18n.t('namespace.notification.updateCompactionThresholdSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleOffloadThreshold: function handleOffloadThreshold() {
      var _this30 = this;

      setOffloadThreshold(this.tenantNamespace, this.form.offloadThreshold).then(function (response) {
        _this30.$notify({
          title: 'success',
          message: _this30.$i18n.t('namespace.notification.updateOffloadThresholdSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleOffloadDeletionLag: function handleOffloadDeletionLag() {
      var _this31 = this;

      setOffloadDeletionLag(this.tenantNamespace, this.form.offloadDeletionLag).then(function (response) {
        _this31.$notify({
          title: 'success',
          message: _this31.$i18n.t('namespace.notification.updateOffloadDeletionLagSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleMaxProducersPerTopic: function handleMaxProducersPerTopic() {
      var _this32 = this;

      setMaxProducersPerTopic(this.tenantNamespace, this.form.maxProducersPerTopic).then(function (response) {
        _this32.$notify({
          title: 'success',
          message: _this32.$i18n.t('namespace.notification.setMaxProducersSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleMaxConsumersPerTopic: function handleMaxConsumersPerTopic() {
      var _this33 = this;

      setMaxConsumersPerTopic(this.tenantNamespace, this.form.maxConsumersPerTopic).then(function (response) {
        _this33.$notify({
          title: 'success',
          message: _this33.$i18n.t('namespace.notification.setMaxConsumersSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleMaxConsuemrsPerSubscription: function handleMaxConsuemrsPerSubscription() {
      var _this34 = this;

      setMaxConsumersPerSubscription(this.tenantNamespace, this.form.maxConsumerPerSub).then(function (response) {
        _this34.$notify({
          title: 'success',
          message: _this34.$i18n.t('namespace.notification.setMaxConsumersPerSubscriptionSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleDispatchRatePerTopic: function handleDispatchRatePerTopic() {
      var _this35 = this;

      var data = {
        'dispatchThrottlingRateInByte': this.form.dispatchRatePerTopicBytes,
        'ratePeriodInSecond': this.form.dispatchRatePerTopicPeriod,
        'dispatchThrottlingRateInMsg': this.form.dispatchRatePerTopicMessage
      };
      setDispatchRate(this.tenantNamespace, data).then(function (response) {
        _this35.$notify({
          title: 'success',
          message: _this35.$i18n.t('namespace.notification.setDispatchRateSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleDispatchRatePerSub: function handleDispatchRatePerSub() {
      var _this36 = this;

      var data = {
        'dispatchThrottlingRateInByte': this.form.dispatchRatePerSubBytes,
        'ratePeriodInSecond': this.form.dispatchRatePerSubPeriod,
        'dispatchThrottlingRateInMsg': this.form.dispatchRatePerSubMessage
      };
      setSubscriptionDispatchRate(this.tenantNamespace, data).then(function (response) {
        _this36.$notify({
          title: 'success',
          message: _this36.$i18n.t('namespace.notification.setDispatchRatePerSubscriptionSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleSubscribeRate: function handleSubscribeRate() {
      var _this37 = this;

      var data = {
        'subscribeThrottlingRatePerConsumer': this.form.subscribeRatePerConsumerSub,
        'ratePeriodInSecond': this.form.subscribeRatePerConsumerPeriod
      };
      setSubscribeRate(this.tenantNamespace, data).then(function (response) {
        _this37.$notify({
          title: 'success',
          message: _this37.$i18n.t('namespace.notification.setSubscribeRateSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleAntiAffinityGroup: function handleAntiAffinityGroup() {
      var _this38 = this;

      setAntiAffinityGroup(this.tenantNamespace, this.form.antiAffinityGroup).then(function (response) {
        _this38.$notify({
          title: 'success',
          message: _this38.$i18n.t('namespace.notification.setAntiAffinityGroupSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleDeleteNamespace: function handleDeleteNamespace() {
      this.dialogStatus = 'delete';
      this.dialogFormVisible = true;
    },
    deleteNamespace: function deleteNamespace() {
      var _this39 = this;

      _deleteNamespace(this.tenantNamespace).then(function (response) {
        _this39.$notify({
          title: 'success',
          message: _this39.$i18n.t('namespace.deleteNsSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this39.$router.push({ path: '/management/tenants/tenantInfo/' + _this39.postForm.tenant + '?tab=namespaces' });
      });
    },
    handleCreateTopic: function handleCreateTopic() {
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.form.topic = '';
      this.form.isPersistent = 'Persistent';
      this.form.partitions = 0;
    },
    generalCreateTopic: function generalCreateTopic(persistent) {
      var _this40 = this;

      putTopic(persistent, this.postForm.tenant, this.postForm.namespace, this.form.topic, parseInt(this.form.partitions)).then(function () {
        _this40.$notify({
          title: 'success',
          message: _this40.$i18n.t('topic.notification.createTopicSuccess'),
          type: 'success',
          duration: 2000
        });
        _this40.dialogFormVisible = false;
        _this40.topicsList = [];
        _this40.allTopicList = [];
        _this40.tempTopicsList = [];
        _this40.getTopics();
      });
    },
    createTopic: function createTopic() {
      var _this41 = this;

      // if (this.form.topic === null || this.form.topic.length <= 0) {
      //   this.$notify({
      //     title: 'error',
      //     message: 'Topic name is incorrect',
      //     type: 'error',
      //     duration: 3000
      //   })
      //   return
      // }
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this41.form.isPersistent === 'Persistent') {
            _this41.generalCreateTopic('persistent');
          } else if (_this41.form.isPersistent === 'Non-persistent') {
            _this41.generalCreateTopic('non-persistent');
          }
        }
      });
    }
  }
};