
export default {
  name: 'Index',
  watch: {
    $route: {
      handler: function handler(route) {
        var _this = this;

        this.$store.dispatch('LoginByCasdoor', route.query.code, route.query.state).then(function () {
          _this.$router.push({ path: '/' });
        }).catch(function () {
          console.log('login error!!');
        });
      },
      immediate: true
    }

  }
};