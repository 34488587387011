import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchClusters } from '@/api/clusters';
import { fetchTenants, fetchTenantsInfo, updateTenant, deleteTenant as _deleteTenant } from '@/api/tenants';
import { fetchNamespaces, putNamespace, deleteNamespace as _deleteNamespace } from '@/api/namespaces';
import { validateEmpty } from '@/utils/validate';
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';

var defaultForm = {
  tenant: ''
};

export default {
  name: 'TenantInfo',
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      dynamicRoles: [],
      inputVisible: false,
      inputValue: '',
      adminRoles: '',
      allowedClustersContent: this.$i18n.t('tenant.allowedClustersContent'),
      adminRolesContent: this.$i18n.t('tenant.adminRolesContent'),
      clusterValue: [],
      clusterOptions: [],
      activeName: 'configuration',
      tenantsListOptions: [],
      listQuery: {
        namespace: '',
        page: 1,
        limit: 20
      },
      listLoading: false,
      tableKey: 0,
      total: 0,
      listNamespaces: [],
      temp: {
        tenant: '',
        namespace: '',
        limit: '',
        actions: [],
        clusters: []
      },
      dialogFormVisible: false,
      dialogStatus: '',
      rules: {
        namespace: [{ required: true, message: this.$i18n.t('namespace.namespaceNameIsRequired'), trigger: 'blur' }]
      },
      currentTabName: '',
      textMap: {
        createNamespace: this.$i18n.t('namespace.newNamespace'),
        deleteNamespace: this.$i18n.t('namespace.deleteNamespace'),
        deleteTenant: this.$i18n.t('tenant.deleteTenant')
      },
      tempNamespacesList: [],
      searchNamespace: '',
      searchList: []
    };
  },
  created: function created() {
    this.postForm.tenant = this.$route.params && this.$route.params.tenant;
    if (this.$route.query && this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
      this.currentTabName = this.$route.query.tab;
    }
    this.getRemoteTenantsList();
    this.getNamespacesList();
    this.getClustersList();
    this.getTenantsInfo();
  },

  methods: {
    handleClose: function handleClose(tag) {
      var _this = this;

      this.dynamicRoles.splice(this.dynamicRoles.indexOf(tag), 1);
      var data = {};
      data.adminRoles = this.dynamicRoles;
      data.allowedClusters = this.clusterValue;
      updateTenant(this.postForm.tenant, data).then(function () {
        _this.$notify({
          title: 'success',
          message: _this.$i18n.t('tenant.updateRolesSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    showInput: function showInput() {
      var _this2 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this3 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this3.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var _this4 = this;

      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.dynamicRoles.indexOf(this.inputValue) >= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('tenant.roleAlreadyExists'),
            type: 'error',
            duration: 2000
          });
          return;
        }
        this.dynamicRoles.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
      var data = {};
      data.adminRoles = this.dynamicRoles;
      data.allowedClusters = this.clusterValue;
      updateTenant(this.postForm.tenant, data).then(function () {
        _this4.$notify({
          title: 'success',
          message: _this4.$i18n.t('tenant.updateRolesSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    getClustersList: function getClustersList() {
      var _this5 = this;

      fetchClusters().then(function (response) {
        for (var i in response.data.data) {
          _this5.clusterOptions.push({
            value: response.data.data[i].cluster,
            label: response.data.data[i].cluster
          });
        }
      });
    },
    getTenantsInfo: function getTenantsInfo() {
      var _this6 = this;

      fetchTenantsInfo(this.postForm.tenant).then(function (response) {
        _this6.clusterValue = response.data.allowedClusters;
        _this6.dynamicRoles = response.data.adminRoles;
      });
    },
    handleSelectClusters: function handleSelectClusters() {
      var _this7 = this;

      var data = {};
      data.allowedClusters = this.clusterValue;
      data.adminRoles = this.dynamicRoles;
      updateTenant(this.postForm.tenant, data).then(function () {
        _this7.$notify({
          title: 'success',
          message: _this7.$i18n.t('tenant.updateClustersSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    handleDeleteTenant: function handleDeleteTenant() {
      this.dialogStatus = 'deleteTenant';
      this.dialogFormVisible = true;
    },
    deleteTenant: function deleteTenant() {
      var _this8 = this;

      _deleteTenant(this.postForm.tenant).then(function (response) {
        _this8.$notify({
          title: 'success',
          message: _this8.$i18n.t('tenant.deleteTenantSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this8.$router.push({ path: '/management/tenants' });
      });
    },
    getNamespacesList: function getNamespacesList() {
      var _this9 = this;

      fetchNamespaces(this.postForm.tenant, this.listQuery).then(function (response) {
        _this9.listNamespaces = [];
        for (var i = 0; i < response.data.data.length; i++) {
          var data = {
            'tenant': _this9.postForm.tenant,
            'namespace': response.data.data[i].namespace,
            'topics': response.data.data[i].topics,
            'inMsg': numberFormatter(0, 2),
            'outMsg': numberFormatter(0, 2),
            'inBytes': formatBytes(0),
            'outBytes': formatBytes(0),
            'storageSize': formatBytes(0, 0)
          };
          if (response.data.data[i].hasOwnProperty('inMsg')) {
            data['inMsg'] = numberFormatter(response.data.data[i]['inMsg'], 2);
            data['outMsg'] = numberFormatter(response.data.data[i]['outMsg'], 2);
            data['inBytes'] = numberFormatter(response.data.data[i]['inBytes']);
            data['outBytes'] = numberFormatter(response.data.data[i]['outBytes']);
            data['storageSize'] = numberFormatter(response.data.data[i]['storageSize'], 2);
          }
          _this9.listNamespaces.push(data);
        }
        _this9.total = _this9.listNamespaces.length;
        // Just to simulate the time of the request
        setTimeout(function () {
          _this9.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleClick: function handleClick(tab, event) {
      this.currentTabName = tab.name;
      this.$router.push({ query: { 'tab': tab.name } });
    },
    handleFilterNamespace: function handleFilterNamespace() {
      if (this.tempNamespacesList.length <= 0) {
        for (var t = 0; t < this.listNamespaces.length; t++) {
          this.tempNamespacesList.push(this.listNamespaces[t]);
        }
      }
      if (!validateEmpty(this.searchNamespace)) {
        this.searchList = [];
        for (var i = 0; i < this.listNamespaces.length; i++) {
          if (this.listNamespaces[i]['namespace'].indexOf(this.searchNamespace) !== -1) {
            this.searchList.push(this.listNamespaces[i]);
          }
        }
        this.listNamespaces = this.searchList;
      } else {
        this.listNamespaces = this.tempNamespacesList;
      }
    },
    handleCreateNamespace: function handleCreateNamespace() {
      this.temp.namespace = '';
      this.dialogStatus = 'createNamespace';
      this.dialogFormVisible = true;
    },
    createNamespace: function createNamespace() {
      var _this10 = this;

      putNamespace(this.postForm.tenant, this.temp.namespace, this.temp).then(function () {
        _this10.listNamespaces = [];
        _this10.getNamespacesList();
        _this10.dialogFormVisible = false;
        _this10.$notify({
          title: 'success',
          message: _this10.$i18n.t('namespace.createNsSuccessNotification'),
          type: 'success',
          duration: 2000
        });
      });
    },
    handleOptions: function handleOptions() {
      var _this11 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          switch (_this11.dialogStatus) {
            case 'createNamespace':
              _this11.createNamespace();
              break;
            case 'deleteNamespace':
              _this11.deleteNamespace();
              break;
            case 'deleteTenant':
              _this11.deleteTenant();
              break;
          }
        }
      });
    },
    handleDeleteNamespace: function handleDeleteNamespace(row) {
      this.dialogStatus = 'deleteNamespace';
      this.dialogFormVisible = true;
      this.temp.tenant = row.tenant;
      this.temp.namespace = row.namespace;
    },
    deleteNamespace: function deleteNamespace() {
      var _this12 = this;

      var tenantNamespace = this.temp.tenant + '/' + this.temp.namespace;
      _deleteNamespace(tenantNamespace).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: _this12.$i18n.t('namespace.deleteNsSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this12.dialogFormVisible = false;
        _this12.listNamespaces = [];
        _this12.getNamespacesList();
      });
    }
  }
};