import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import codeEditor from '@/components/CodeEditor'
import yaml from 'js-yaml';
import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces } from '@/api/namespaces';
import jsonEditor from '@/components/JsonEditor';
import { validateEmpty } from '@/utils/validate';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { fetchSources, createSource, updateSource, getSourceStatus, startSourceInstance, stopSourceInstance, restartSourceInstance, deleteSource, fetchSourcesStats, getSourceStatusInstance, getBuiltinSources } from '@/api/sources';
import { fetchTopics, fetchPersistentPartitonsTopics, fetchNonPersistentPartitonsTopics } from '@/api/topics';

var defaultForm = {
  tenant: '',
  namespace: ''
};
export default {
  name: 'Sources',
  components: {
    // codeEditor
    jsonEditor: jsonEditor,
    Pagination: Pagination
  },
  data: function data() {
    return {
      codeValue: null,
      postForm: _Object$assign({}, defaultForm),
      tenantsListOptions: [],
      namespacesListOptions: [],
      outputTopicsListOptions: [],
      guanrateesListOptions: [],
      instancesListOptions: [],
      // codeMode: 'text/x-cython',
      localList: [],
      searchList: [],
      tenant: '',
      namespace: '',
      jsonValue: {},
      tableKey: 0,
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      list: null,
      currentFile: '',
      triggerFile: '',
      currentTriggerFileName: '',
      currentSource: '',
      dialogStatus: '',
      listQuery: {
        topic: '',
        page: 1,
        limit: 10
      },
      temp: {
        source: '',
        instanceId: 0,
        className: '',
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        parallelism: '',
        schemaType: '',
        currentFile: '',
        currentFileName: '',
        currentConfigFile: '',
        currentConfigFileName: '',
        sourceConfig: '',
        triggerValue: '',
        triggerInput: '',
        deClassName: '',
        guarantees: ''
      },
      rules: {
        source: [{ required: true, message: 'sources name is required', trigger: 'blur' }],
        className: [{ required: true, message: 'className is required', trigger: 'blur' }],
        outputTopic: [{ required: true, message: 'outputTopic is required', trigger: 'blur' }]
      }
    };
  },
  mounted: function mounted() {
    this.guanrateesListOptions = ['ATLEAST_ONCE', 'ATMOST_ONCE', 'EFFECTIVELY_ONCE'];
  },
  created: function created() {
    this.getSources();
    this.getRemoteTenantsList();
  },

  methods: {
    getSources: function getSources() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0);
      } else {
        this.listLoading = true;
        if (this.postForm.tenant.length > 0) {
          this.tenant = this.postForm.tenant;
        }
        if (this.postForm.namespace.length > 0) {
          this.namespace = this.postForm.namespace;
        }
        if (this.tenant.length <= 0 || this.namespace.length <= 0) {
          this.tenant = 'public';
          this.namespace = 'default';
        }
        fetchSources(this.tenant, this.namespace).then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            _this.localList.push({ 'source': response.data[i], 'stats': 'stats', 'status': 'status' });
          }
          _this.total = _this.localList.length;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.topic)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['source'].indexOf(this.listQuery.topic) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    handleFilter: function handleFilter(tenant, namespace) {
      this.tenant = tenant;
      this.namespace = namespace;
      this.getSources();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.resetTemp();
      this.$nextTick(function () {
        _this2.$refs['temp'].clearValidate();
      });
    },
    getCurrentRow: function getCurrentRow(item) {
      this.currentSource = item.source;
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      if (this.currentSource.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one source in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      deleteSource(this.tenant, this.namespace, this.currentSource).then(function (response) {
        _this3.$notify({
          title: 'success',
          message: 'delete source success',
          type: 'success',
          duration: 2000
        });
        _this3.localList = [];
        _this3.getSources();
      });
    },
    confirmCreateSource: function confirmCreateSource() {
      var _this4 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var formData = _this4.prepareSourceParams(_this4.temp.source);
          createSource(_this4.tenant, _this4.namespace, _this4.temp.source, formData).then(function (response) {
            _this4.$notify({
              title: 'success',
              message: 'create source success',
              type: 'success',
              duration: 2000
            });
            _this4.dialogFormVisible = false;
            _this4.localList = [];
            _this4.getSources();
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      this.resetTemp();
      if (this.currentSource.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one source in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
    },
    handleAction: function handleAction(action, row) {
      var _this5 = this;

      this.dialogStatus = action;
      this.dialogFormVisible = true;
      this.instancesListOptions = [];
      this.currentSource = row.source;
      getSourceStatus(this.tenant, this.namespace, row.source).then(function (response) {
        for (var i = 0; i < response.data.instances.length; i++) {
          _this5.instancesListOptions.push(response.data.instances[i]['instanceId']);
        }
      });
    },
    handleOptions: function handleOptions() {
      switch (this.dialogStatus) {
        case 'create':
          this.confirmCreateSource();
          break;
        case 'start':
          this.confirmStart();
          break;
        case 'stop':
          this.confirmStop();
          break;
        case 'restart':
          this.confirmRestart();
          break;
        case 'update':
          this.confirmUpdate();
          break;
        case 'status':
          this.confirmStatus();
          break;
      }
    },
    loadTextFromFile: function loadTextFromFile(ev) {
      var _this6 = this;

      var file = ev.target.files[0];
      console.log(ev.target);
      console.log(ev.target.files);
      this.temp.currentFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this6.temp.currentFile = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },
    loadSourceConfigFile: function loadSourceConfigFile(ev) {
      var _this7 = this;

      var file = ev.target.files[0];
      this.temp.currentConfigFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this7.temp.currentConfigFile = e.target.result;
      };
      reader.readAsText(file);
    },
    handleGet: function handleGet(row) {
      var _this8 = this;

      fetchSourcesStats(this.tenant, this.namespace, row.source).then(function (response) {
        _this8.jsonValue = response.data;
      });
    },

    // handleGetStatus(row) {
    //   getSourceStatus(this.tenant, this.namespace, row.source).then(response => {
    //     this.jsonValue = response.data
    //   })
    // },
    confirmStart: function confirmStart() {
      var _this9 = this;

      startSourceInstance(this.tenant, this.namespace, this.currentSource, this.temp.instanceId).then(function (response) {
        _this9.$notify({
          title: 'success',
          message: 'start source success',
          type: 'success',
          duration: 2000
        });
        _this9.dialogFormVisible = false;
      });
    },
    confirmStop: function confirmStop() {
      var _this10 = this;

      stopSourceInstance(this.tenant, this.namespace, this.currentSource, this.temp.instanceId).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: 'stop source success',
          type: 'success',
          duration: 2000
        });
        _this10.dialogFormVisible = false;
      });
    },
    confirmRestart: function confirmRestart() {
      var _this11 = this;

      restartSourceInstance(this.tenant, this.namespace, this.currentSource, this.temp.instanceId).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: 'restart source success',
          type: 'success',
          duration: 2000
        });
        _this11.dialogFormVisible = false;
      });
    },
    confirmStatus: function confirmStatus() {
      var _this12 = this;

      getSourceStatusInstance(this.tenant, this.namespace, this.currentSource, this.temp.instanceId).then(function (response) {
        _this12.jsonValue = response.data;
        _this12.$notify({
          title: 'success',
          message: 'get status source success',
          type: 'success',
          duration: 2000
        });
        _this12.dialogFormVisible = false;
      });
    },
    prepareSourceParams: function prepareSourceParams(sourceName) {
      var formData = new FormData();
      var sourceConfig = {
        'topicName': 'my-topic',
        'className': this.temp.className,
        'tenant': this.tenant,
        'namespace': this.namespace
      };
      if (sourceName.length > 0) {
        sourceConfig['name'] = sourceName;
      }
      var blob = new Blob([this.temp.currentFile], { type: 'application/octet-stream' });
      sourceConfig['archive'] = this.temp.currentFileName;
      formData.append('data', blob, this.temp.currentFileName);
      if (this.temp.schemaType.length > 0) {
        sourceConfig['schemaType'] = this.temp.schemaType;
      }
      if (this.temp.cpu.length > 0) {
        sourceConfig['resources'] = { 'cpu': parseInt(this.temp.cpu) };
      }
      if (this.temp.disk.length > 0) {
        sourceConfig['resources'] = { 'disk': this.temp.disk };
      }
      if (this.temp.ram.length > 0) {
        sourceConfig['resources'] = { 'ram': this.temp.ram };
      }
      if (this.temp.parallelism.length > 0) {
        sourceConfig['parallelism'] = parseInt(this.temp.parallelism);
      }
      if (this.temp.guarantees.length > 0) {
        sourceConfig['processingGuarantees'] = this.temp.guarantees;
      }
      if (this.temp.sourceConfig.length > 0) {
        sourceConfig['configs'] = JSON.parse(this.temp.sourceConfig);
      }
      if (this.temp.currentConfigFile.length > 0) {
        var yamlFile = yaml.load(this.temp.currentConfigFile);
        var yamlJSON = JSON.parse(_JSON$stringify(yamlFile));
        sourceConfig['configs'] = yamlJSON['configs'];
      }
      formData.append('sourceConfig', _JSON$stringify(sourceConfig));
      return formData;
    },
    confirmUpdate: function confirmUpdate() {
      var _this13 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var formData = _this13.prepareSourceParams('');
          updateSource(_this13.tenant, _this13.namespace, _this13.currentSource, formData).then(function (response) {
            _this13.$notify({
              title: 'success',
              message: 'update source success',
              type: 'success',
              duration: 2000
            });
            _this13.dialogFormVisible = false;
          });
        }
      });
    },
    getSourcesList: function getSourcesList() {
      this.getSources();
    },
    builtinSources: function builtinSources() {
      var _this14 = this;

      getBuiltinSources().then(function (response) {
        _this14.jsonValue = response.data;
      });
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this15 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        _this15.tenantsListOptions = response.data;
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this16 = this;

      this.tenant = tenant;
      fetchNamespaces(tenant, this.query).then(function (response) {
        var namespace = [];
        for (var i = 0; i < response.data.length; i++) {
          namespace = response.data[i].split('/');
          // namespace.splice(1, namespace.length).join('/')
          _this16.namespacesListOptions.push(namespace.splice(1, namespace.length).join('/'));
        }
      });
    },
    getTopicsList: function getTopicsList() {
      var _this17 = this;

      this.outputTopicsListOptions = [];
      fetchPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.outputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchNonPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.outputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchTopics(this.tenant, this.namespace, this.listQuery).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.outputTopicsListOptions.push(response.data[i]);
        }
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        source: '',
        instanceId: 0,
        className: '',
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        parallelism: '',
        schemaType: '',
        currentFile: '',
        currentFileName: '',
        currentConfigFile: '',
        currentConfigFileName: '',
        sourceConfig: '',
        triggerValue: '',
        triggerInput: '',
        deClassName: '',
        guarantees: ''
      };
    }
  }
};