var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("tenant.searchTenant") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.tenant,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "tenant", $$v)
              },
              expression: "listQuery.tenant"
            }
          }),
          _vm._v(" "),
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-search" },
            on: { click: _vm.handleFilter }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(_vm._s(_vm.$t("tenant.newTenant")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 }
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("tenant.name"),
                      "min-width": "50px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "link-type",
                                attrs: {
                                  to:
                                    "/management/tenants/tenantInfo/" +
                                    scope.row.tenant +
                                    "?tab=namespaces"
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(scope.row.tenant))])]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("namespace.namespaceNumber"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "link-type",
                                attrs: {
                                  to:
                                    "/management/tenants/tenantInfo/" +
                                    scope.row.tenant +
                                    "?tab=namespaces"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.namespace))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("tenant.allowedClustersLabel"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.allowedClusters, function(
                            tag
                          ) {
                            return _c(
                              "span",
                              { key: tag, staticClass: "list-el-tag" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(tag) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("tenant.adminRolesLabel"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.adminRoles, function(tag) {
                            return _c(
                              "el-tag",
                              {
                                key: tag,
                                staticClass: "list-el-tag",
                                attrs: { effect: "dark" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(tag) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inMsg"), align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-download",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.inMsg))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.outMsg"), align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-upload2",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.outMsg))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inBytes"), align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-download",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.inBytes))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("common.outBytes"),
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-upload2",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.outBytes))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("common.storageSize"),
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.storageSize))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("table.actions"),
                      align: "center",
                      "class-name": "small-padding fixed-width"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/management/tenants/tenantInfo/" +
                                    scope.row.tenant
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary", size: "mini" } },
                                  [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    _vm.handleDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("table.delete")))]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("table.tenant"), prop: "tenant" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("tenant.selectTenantMessage")
                        },
                        model: {
                          value: _vm.form.tenant,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tenant", $$v)
                          },
                          expression: "form.tenant"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("tenant.allowedClustersLabel"),
                        prop: "clusters"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("cluster.selectClusterMessage"),
                            multiple: ""
                          },
                          model: {
                            value: _vm.form.clusters,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "clusters", $$v)
                            },
                            expression: "form.clusters"
                          }
                        },
                        _vm._l(_vm.clusterListOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        })
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("tenant.adminRolesLabel"),
                        prop: "roles"
                      }
                    },
                    [
                      _vm._l(_vm.form.dynamicRoles, function(tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            attrs: {
                              "disable-transitions": false,
                              closable: ""
                            },
                            on: {
                              close: function($event) {
                                _vm.handleClose(tag)
                              }
                            }
                          },
                          [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                        )
                      }),
                      _vm._v(" "),
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            attrs: { size: "small" },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              }
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue"
                            }
                          })
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput }
                            },
                            [_vm._v("+ New Role")]
                          )
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "delete"
                ? _c("el-form-item", [
                    _c("h4", [_vm._v(_vm._s(_vm.deleteTenantMessage))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.handleOptions()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }