import _Promise from 'babel-runtime/core-js/promise';
/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { loginByUsername, logout } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { setName, removeName } from '@/utils/username';
import { removeEnvironment } from '@/utils/environment';
import { removeCsrfToken } from '@/utils/csrfToken';
import { Message } from 'element-ui';
import { setTenant, removeTenant } from '../../utils/tenant';
import { getUserInfo } from '@/api/users';
import { loginByCasdoor } from '../../api/login';

var user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
      state.introduction = introduction;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    }
  },

  actions: {
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit;

      var username = userInfo.username.trim();
      return new _Promise(function (resolve, reject) {
        loginByUsername(username, userInfo.password).then(function (response) {
          if (response.data.hasOwnProperty('error') && response.data.error.length >= 0) {
            Message({
              message: 'The username or password is incorrect',
              type: 'error',
              duration: 5 * 1000
            });
            reject('login error');
          }
          commit('SET_TOKEN', response.headers.token);
          setToken(response.headers.token);
          setName(response.headers.username);
          setTenant(response.headers.tenant);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    LoginByCasdoor: function LoginByCasdoor(_ref2, code, state) {
      var commit = _ref2.commit;

      return new _Promise(function (resolve, reject) {
        loginByCasdoor(code, state).then(function (response) {
          if (response.data.hasOwnProperty('error') && response.data.error.length >= 0) {
            reject('login error');
          }
          commit('SET_TOKEN', response.headers.token);
          setToken(response.headers.token);
          setName(response.headers.username);
          setTenant(response.headers.tenant);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // 获取用户信息
    GetUserInfo: function GetUserInfo(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;

      return new _Promise(function (resolve, reject) {
        getUserInfo().then(function (response) {
          commit('SET_ROLES', response.data.roles);
          commit('SET_NAME', 'admin');
          commit('SET_INTRODUCTION', 'Pulsar Manager');
          resolve(response);
        });
      });
    },


    // 登出
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;

      return new _Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          removeToken();
          removeCsrfToken();
          removeName();
          removeTenant();
          removeEnvironment();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // 前端 登出
    FedLogOut: function FedLogOut(_ref5) {
      var commit = _ref5.commit;

      return new _Promise(function (resolve) {
        commit('SET_TOKEN', '');
        removeToken();
        removeName();
        removeTenant();
        removeEnvironment();
        resolve();
      });
    },
    SetEnvironment: function SetEnvironment(_ref6, environment) {
      var commit = _ref6.commit;

      return new _Promise(function (resolve) {
        commit('ENVIRONMENT', environment);
      });
    },


    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref7, role) {
      var commit = _ref7.commit,
          dispatch = _ref7.dispatch;

      return new _Promise(function (resolve) {
        commit('SET_TOKEN', role);
        setToken(role);
        // getUserInfo(role).then(response => {
        //   const data = response.data
        //   commit('SET_ROLES', data.roles)
        //   commit('SET_NAME', data.name)
        //   commit('SET_AVATAR', data.avatar)
        //   commit('SET_INTRODUCTION', data.introduction)
        //   dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
        //   resolve()
        // })
      });
    }
  }
};

export default user;