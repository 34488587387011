//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { putEnvironment, fetchEnvironments, deleteEnvironment as _deleteEnvironment, updateEnvironment as _updateEnvironment } from '@/api/environments';
import { setEnvironment } from '@/utils/environment';
import store from '@/store';

export default {
  name: 'EnvironmentInfo',
  data: function data() {
    return {
      environmentList: [],
      environmentTableKey: 0,
      environmentListLoading: false,
      textMap: {
        create: this.$i18n.t('env.newEnvDialogCaption'),
        delete: this.$i18n.t('env.deleteEnvDialogCaption'),
        update: this.$i18n.t('env.updateEnvDialogCaption')
      },
      dialogFormVisible: false,
      dialogStatus: '',
      form: {
        environment: '',
        broker: '',
        bookie: ''
      },
      temp: {
        'name': '',
        'broker': '',
        'bookie': ''
      },
      superUser: false,
      roles: [],
      rules: {
        environment: [{ required: true, message: this.$i18n.t('env.envNameIsRequired'), trigger: 'blur' }],
        broker: [{ required: true, message: this.$i18n.t('env.serviceUrlIsRequired'), trigger: 'blur' }],
        bookie: [{ required: true, message: this.$i18n.t('env.bookieUrlIsRequired'), trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getEnvironments();
    this.roles = store.getters && store.getters.roles;
    if (this.roles.includes('super')) {
      this.superUser = true;
    } else {
      this.superUser = false;
    }
  },

  methods: {
    getEnvironments: function getEnvironments() {
      var _this = this;

      fetchEnvironments().then(function (response) {
        if (!response.data) return;
        _this.environmentList = [];
        for (var i = 0; i < response.data.data.length; i++) {
          _this.environmentList.push({
            'environment': response.data.data[i].name,
            'broker': response.data.data[i].broker,
            'bookie': response.data.data[i].bookie
          });
        }
      });
    },
    handleCreateEnvironment: function handleCreateEnvironment() {
      this.form.environment = '';
      this.form.broker = '';
      this.dialogFormVisible = true;
      this.dialogStatus = 'create';
    },
    handleDeleteEnvironment: function handleDeleteEnvironment(row) {
      this.temp.name = row.environment;
      this.temp.broker = row.broker;
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    handleUpdateEnvironment: function handleUpdateEnvironment(row) {
      this.form.environment = row.environment;
      this.form.broker = row.broker;
      this.form.bookie = row.bookie;
      this.dialogFormVisible = true;
      this.dialogStatus = 'update';
    },
    handleOptions: function handleOptions() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          switch (_this2.dialogStatus) {
            case 'create':
              _this2.createEnvironment();
              break;
            case 'delete':
              _this2.deleteEnvironment();
              break;
            case 'update':
              _this2.updateEnvironment();
              break;
          }
        }
      });
    },
    createEnvironment: function createEnvironment() {
      var _this3 = this;

      var data = {
        'name': this.form.environment,
        'broker': this.form.broker,
        'bookie': this.form.bookie
      };
      putEnvironment(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this3.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this3.$notify({
          title: 'success',
          message: _this3.$i18n.t('env.addEnvSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this3.dialogFormVisible = false;
        _this3.getEnvironments();
      });
    },
    deleteEnvironment: function deleteEnvironment() {
      var _this4 = this;

      var data = {
        'name': this.temp.name,
        'broker': this.temp.broker
      };
      _deleteEnvironment(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this4.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this4.$notify({
          title: 'success',
          message: _this4.$i18n.t('env.deleteEnvSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this4.getEnvironments();
        _this4.dialogFormVisible = false;
      });
    },
    updateEnvironment: function updateEnvironment() {
      var _this5 = this;

      var data = {
        'name': this.form.environment,
        'broker': this.form.broker,
        'bookie': this.form.bookie
      };
      _updateEnvironment(data).then(function (response) {
        if (!response.data) return;
        if (response.data.hasOwnProperty('error')) {
          _this5.$notify({
            title: 'error',
            message: response.data.error,
            type: 'error',
            duration: 2000
          });
          return;
        }
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('env.updateEnvSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this5.getEnvironments();
        _this5.dialogFormVisible = false;
      });
    },
    handleSetEnvironment: function handleSetEnvironment(environment) {
      setEnvironment(environment);
      if (this.roles.includes('super')) {
        this.$router.push({ path: '/management/tenants' });
      } else {
        this.$router.push({ path: '/management/admin/tenants/tenantInfo' });
      }
    }
  }
};