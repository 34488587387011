import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import MdInput from '@/components/MDinput'
import { fetchClusters, getClusterDomainName, updateClusterDomainName, listClusterDomainName, deleteClusterDomainName } from '@/api/clusters';
import { fetchBrokersByDirectBroker } from '@/api/brokers';

var defaultForm = {
  cluster: '',
  failureDomainName: ''
};
export default {
  name: 'FailureDomainInfo',
  // components: {
  //   MdInput
  // },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clustersListOptions: [],
      brokersContent: this.$i18n.t('broker.brokerContent'),
      brokerValue: [],
      brokerOptions: [],
      failureDomainListOptions: [],
      firstInit: false,
      textMap: {
        delete: this.$i18n.t('fd.deleteFd')
      },
      dialogStatus: '',
      dialogFormVisible: false,
      deleteFdMessage: this.$i18n.t('fd.deleteFdMessage')
    };
  },
  created: function created() {
    this.postForm.cluster = this.$route.params && this.$route.params.cluster;
    this.postForm.failureDomainName = this.$route.params && this.$route.params.failureDomainName;
    this.firstInit = true;
    this.getClusterList();
    this.initBrokerValue();
    this.initSelectBrokers();
    this.getFailureDomainsList();
  },

  methods: {
    initBrokerValue: function initBrokerValue() {
      var _this = this;

      getClusterDomainName(this.postForm.cluster, this.postForm.failureDomainName).then(function (response) {
        if (!response.data) return;
        _this.brokerValue = response.data.brokers;
      });
    },
    getClusterList: function getClusterList() {
      var _this2 = this;

      fetchClusters(this.listQuery).then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.data.length; i++) {
          _this2.clustersListOptions.push(response.data.data[i].cluster);
        }
      });
    },
    getFailureDomainsList: function getFailureDomainsList() {
      var _this3 = this;

      listClusterDomainName(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        if (_this3.firstInit) {
          _this3.firstInit = false;
        } else {
          _this3.postForm.failureDomainName = '';
        }
        _this3.failureDomainListOptions = [];
        for (var key in response.data) {
          _this3.failureDomainListOptions.push(key);
        }
      });
    },
    initSelectBrokers: function initSelectBrokers() {
      var _this4 = this;

      fetchBrokersByDirectBroker(this.postForm.cluster).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this4.brokerOptions.push({
            value: response.data[i],
            label: response.data[i]
          });
        }
      });
    },
    handleSelectBrokers: function handleSelectBrokers() {
      var _this5 = this;

      var data = {
        'brokers': this.brokerValue
      };
      updateClusterDomainName(this.postForm.cluster, this.postForm.failureDomainName, data).then(function (response) {
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('fd.updateFdSuccessNotification'),
          type: 'success',
          duration: 3000
        });
      });
    },
    getFailureDomainInfo: function getFailureDomainInfo() {
      this.$router.push({ path: '/management/clusters/' + this.postForm.cluster + '/' + this.postForm.failureDomainName + '/failureDomainName' });
    },
    handleDelete: function handleDelete() {
      this.dialogFormVisible = true;
      this.dialogStatus = 'delete';
    },
    deleteDomain: function deleteDomain() {
      var _this6 = this;

      deleteClusterDomainName(this.postForm.cluster, this.postForm.failureDomainName).then(function (response) {
        _this6.$notify({
          title: 'success',
          message: _this6.$i18n.t('fd.deleteFdSuccessNotification'),
          type: 'success',
          duration: 3000
        });
        _this6.dialogFormVisible = false;
        _this6.$router.push({ path: '/management/clusters/' + _this6.postForm.cluster + '/cluster?tab=failureDomains' });
      });
    }
  }
};