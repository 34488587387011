var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "createPost-container" },
      [
        _c(
          "el-form",
          {
            staticClass: "form-container",
            attrs: { inline: true, model: _vm.postForm }
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "postInfo-container-item",
                attrs: { label: "Cluster" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "select cluster" },
                    on: {
                      change: function($event) {
                        _vm.getBrokersList()
                      }
                    },
                    model: {
                      value: _vm.postForm.cluster,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "cluster", $$v)
                      },
                      expression: "postForm.cluster"
                    }
                  },
                  _vm._l(_vm.clustersListOptions, function(item, index) {
                    return _c("el-option", {
                      key: item + index,
                      attrs: { label: item, value: item }
                    })
                  })
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "postInfo-container-item",
                attrs: { label: "Broker" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "select broker" },
                    on: {
                      change: function($event) {
                        _vm.changeBrokerInfo()
                      }
                    },
                    model: {
                      value: _vm.postForm.broker,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "broker", $$v)
                      },
                      expression: "postForm.broker"
                    }
                  },
                  _vm._l(_vm.brokersListOptions, function(item, index) {
                    return _c("el-option", {
                      key: item + index,
                      attrs: { label: item, value: item }
                    })
                  })
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleHeartBeat }
              },
              [_vm._v("Heartbeat")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleRuntimeConfig }
              },
              [_vm._v("Runtime Config")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.brokerStats, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: _vm.$t("common.inMsg"), prop: "inMsg" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: _vm.$t("common.outMsg"), prop: "outMsg" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: _vm.$t("common.inBytes"), prop: "inBytes" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: _vm.$t("common.outBytes"), prop: "outBytes" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", [_vm._v("Owned Namespaces")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            key: _vm.bundleTableKey,
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.bundleList,
              border: "",
              fit: "",
              "highlight-current-row": ""
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("tenant.label"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to:
                              "/management/tenants/tenantInfo/" +
                              scope.row.tenant +
                              "?tab=namespaces"
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(scope.row.tenant))])]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("namespace.label"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to:
                              "/management/namespaces/" +
                              scope.row.tenant +
                              "/" +
                              scope.row.namespace +
                              "/namespace?tab=overview"
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(scope.row.namespace))])]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("namespace.bundle.label"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.bundle))])]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("namespace.bundle.operation"),
                align: "center",
                "class-name": "small-padding fixed-width"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "medium",
                            type: "danger",
                            icon: "el-icon-download"
                          },
                          on: {
                            click: function($event) {
                              _vm.handleUnloadBundle(scope.row)
                            }
                          }
                        },
                        [_vm._v("Unload")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", [_vm._v("Namespace Isolation Policies")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.isolationPolicyList,
              border: "",
              fit: "",
              "highlight-current-row": ""
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("ip.nameLabel"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to:
                              "/management/clusters/" +
                              scope.row.cluster +
                              "/" +
                              scope.row.isolationPolicy +
                              "/namespaceIsolationPolicy"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.isolationPolicy))
                          ])
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("ip.numPBLabel"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.primaryBrokers))])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("ip.numSBLabel"),
                align: "center",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.secondaryBrokers))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogFormVisible },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormVisible = $event
              }
            }
          },
          [_c("jsonEditor", { attrs: { value: _vm.jsonValue } })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }