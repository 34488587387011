import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import codeEditor from '@/components/CodeEditor'
import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces } from '@/api/namespaces';
import jsonEditor from '@/components/JsonEditor';
import { validateEmpty } from '@/utils/validate';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { fetchFunctions, fetchFunctionStats, fetchFunctionStatus, createFunction as _createFunction, deleteFunction, startFunctionInstance, stopFunctionInstance, restartFunctionInstance, updateFunction, triggerFunction } from '@/api/functions';
import { fetchTopics, fetchPersistentPartitonsTopics, fetchNonPersistentPartitonsTopics } from '@/api/topics';

var defaultForm = {
  tenant: '',
  namespace: ''
};
export default {
  name: 'Functions',
  components: {
    // codeEditor
    jsonEditor: jsonEditor,
    Pagination: Pagination
  },
  data: function data() {
    return {
      codeValue: null,
      postForm: _Object$assign({}, defaultForm),
      tenantsListOptions: [],
      namespacesListOptions: [],
      inputTopicsListOptions: [],
      outputTopicsListOptions: [],
      guanrateesListOptions: [],
      instancesListOptions: [],
      triggerInputTopicsListOptions: [],
      // codeMode: 'text/x-cython',
      localList: [],
      searchList: [],
      tenant: '',
      namespace: '',
      jsonValue: {},
      tableKey: 0,
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      list: null,
      currentFile: '',
      triggerFile: '',
      currentTriggerFileName: '',
      currentFunction: '',
      dialogStatus: '',
      listQuery: {
        topic: '',
        page: 1,
        limit: 10
      },
      temp: {
        autoAck: false,
        function: '',
        instanceId: 0,
        className: '',
        inputTopic: '',
        outputTopic: '',
        language: false,
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        serdeInput: '',
        serdeOutput: '',
        letterTopic: '',
        guarantees: '',
        fqfn: '',
        logTopic: '',
        parallelism: '',
        retainOrdering: false,
        schemaType: '',
        slidingIntervalCount: '',
        slidingIntervalDurationMs: '',
        maxMessageRetries: '',
        subName: '',
        timeoutMs: '',
        windowLengthCount: '',
        windowLengthDurationMs: '',
        topicsPattern: '',
        currentFile: '',
        currentFileName: '',
        currentJsonFile: '',
        userConfig: '',
        triggerValue: '',
        triggerInput: ''
      },
      rules: {
        function: [{ required: true, message: 'functions name is required', trigger: 'blur' }],
        className: [{ required: true, message: 'className is required', trigger: 'blur' }],
        inputTopic: [{ required: true, message: 'inputTopic is required', trigger: 'blur' }],
        outputTopic: [{ required: true, message: 'outputTopic is required', trigger: 'blur' }]
      }
    };
  },
  mounted: function mounted() {
    this.guanrateesListOptions = ['ATLEAST_ONCE', 'ATMOST_ONCE', 'EFFECTIVELY_ONCE'];
  },
  created: function created() {
    this.getFunctions();
    this.getRemoteTenantsList();
  },

  methods: {
    getFunctions: function getFunctions() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0);
      } else {
        this.listLoading = true;
        if (this.postForm.tenant.length > 0) {
          this.tenant = this.postForm.tenant;
        }
        if (this.postForm.namespace.length > 0) {
          this.namespace = this.postForm.namespace;
        }
        if (this.tenant.length <= 0 || this.namespace.length <= 0) {
          this.tenant = 'public';
          this.namespace = 'default';
        }
        fetchFunctions(this.tenant, this.namespace).then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            _this.localList.push({ 'function': response.data[i], 'stats': 'stats', 'status': 'status' });
          }
          _this.total = _this.localList.length;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.topic)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['function'].indexOf(this.listQuery.topic) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    handleFilter: function handleFilter() {
      this.getFunctions();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.resetTemp();
      this.$nextTick(function () {
        _this2.$refs['temp'].clearValidate();
      });
    },
    getCurrentRow: function getCurrentRow(item) {
      this.currentFunction = item.function;
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      if (this.currentFunction.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one function in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      deleteFunction(this.tenant, this.namespace, this.currentFunction).then(function (response) {
        _this3.$notify({
          title: 'success',
          message: 'delete functions success',
          type: 'success',
          duration: 2000
        });
        _this3.localList = [];
        _this3.getFunctions();
      });
    },
    createFunction: function createFunction() {
      var _this4 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var formData = _this4.prepareFunctionParams(_this4.temp.function);
          _createFunction(_this4.tenant, _this4.namespace, _this4.temp.function, formData).then(function (response) {
            _this4.$notify({
              title: 'success',
              message: 'create functions success',
              type: 'success',
              duration: 2000
            });
            _this4.dialogFormVisible = false;
            _this4.localList = [];
            _this4.getFunctions();
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      this.resetTemp();
      if (this.currentFunction.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one function in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
    },
    handleTrigger: function handleTrigger() {
      if (this.currentFunction.length <= 0) {
        this.$notify({
          title: 'error',
          message: 'Please select any one function in table',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.dialogStatus = 'trigger';
      this.dialogFormVisible = true;
    },
    handleAction: function handleAction(action, row) {
      var _this5 = this;

      this.dialogStatus = action;
      this.dialogFormVisible = true;
      this.instancesListOptions = [];
      this.currentFunction = row.function;
      fetchFunctionStatus(this.tenant, this.namespace, row.function).then(function (response) {
        for (var i = 0; i < response.data.instances.length; i++) {
          _this5.instancesListOptions.push(response.data.instances[i]['instanceId']);
        }
      });
    },
    handleOptions: function handleOptions() {
      switch (this.dialogStatus) {
        case 'create':
          this.createFunction();
          break;
        case 'start':
          this.confirmStart();
          break;
        case 'stop':
          this.confirmStop();
          break;
        case 'restart':
          this.confirmRestart();
          break;
        case 'update':
          this.confirmUpdate();
          break;
        case 'trigger':
          this.confirmTrigger();
          break;
      }
    },
    loadTextFromFile: function loadTextFromFile(ev) {
      var _this6 = this;

      var file = ev.target.files[0];
      this.temp.currentFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this6.temp.currentFile = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },
    loadTriggerFile: function loadTriggerFile(ev) {
      var _this7 = this;

      var file = ev.target.files[0];
      this.temp.currentTriggerFileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this7.temp.triggerFile = e.target.result;
      };
      reader.readAsText(file);
    },
    handleGetStats: function handleGetStats(row) {
      var _this8 = this;

      fetchFunctionStats(this.tenant, this.namespace, row.function).then(function (response) {
        _this8.jsonValue = response.data;
      });
    },
    handleGetStatus: function handleGetStatus(row) {
      var _this9 = this;

      fetchFunctionStatus(this.tenant, this.namespace, row.function).then(function (response) {
        _this9.jsonValue = response.data;
      });
    },
    confirmStart: function confirmStart() {
      var _this10 = this;

      startFunctionInstance(this.tenant, this.namespace, this.currentFunction, this.temp.instanceId).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: 'start function success',
          type: 'success',
          duration: 2000
        });
        _this10.dialogFormVisible = false;
      });
    },
    confirmStop: function confirmStop() {
      var _this11 = this;

      stopFunctionInstance(this.tenant, this.namespace, this.currentFunction, this.temp.instanceId).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: 'stop function success',
          type: 'success',
          duration: 2000
        });
        _this11.dialogFormVisible = false;
      });
    },
    confirmRestart: function confirmRestart() {
      var _this12 = this;

      restartFunctionInstance(this.tenant, this.namespace, this.currentFunction, this.temp.instanceId).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: 'restart function success',
          type: 'success',
          duration: 2000
        });
        _this12.dialogFormVisible = false;
      });
    },
    prepareFunctionParams: function prepareFunctionParams(functionName) {
      var data = {
        'inputs': [this.temp.inputTopic],
        'output': this.temp.outputTopic,
        'className': this.temp.className
      };
      if (functionName.length > 0) {
        data['name'] = functionName;
      }
      var formData = new FormData();
      var blob = new Blob([this.temp.currentFile], { type: 'application/octet-stream' });
      formData.append('data', blob, this.temp.currentFileName);
      if (this.temp.autoAck) {
        formData.append('autoAck', this.temp.autoAck);
      }
      if (this.temp.language) {
        data['jar'] = this.temp.currentFileName;
      } else {
        data['py'] = this.temp.currentFileName;
      }
      if (this.temp.schemaType.length > 0) {
        data['outputSchemaType'] = this.temp.outputSchemaType;
      }
      if (this.temp.cpu.length > 0) {
        data['resources'] = { 'cpu': parseInt(this.temp.cpu) };
      }
      if (this.temp.disk.length > 0) {
        data['resources'] = { 'disk': this.temp.disk };
      }
      if (this.temp.ram.length > 0) {
        data['resources'] = { 'ram': this.temp.ram };
      }
      if (this.temp.schemaInput.length > 0) {
        data['customSerdeInputs'] = this.temp.schemaInput;
      }
      if (this.temp.serdeInput.length > 0) {
        data['customSchemaInputs'] = this.temp.serdeInput;
      }
      if (this.temp.serdeOutput.length > 0) {
        data['customSchemaInputs'] = this.temp.serdeOutput;
      }
      if (this.temp.letterTopic.length > 0) {
        data['deadLetterTopic'] = this.temp.deadLetterTopic;
      }
      if (this.temp.fqfn.length > 0) {
        data['fqfn'] = this.temp.fqfn;
      }
      if (this.temp.logTopic.length > 0) {
        data['logTopic'] = this.temp.logTopic;
      }
      if (this.temp.maxMessageRetries.length > 0) {
        data['maxMessageRetries'] = parseInt(this.temp.maxMessageRetries);
      }
      if (this.temp.parallelism.length > 0) {
        data['parallelism'] = parseInt(this.temp.parallelism);
      }
      if (this.temp.retainOrdering.length > 0) {
        data['retainOrdering'] = this.temp.retainOrdering;
      }
      if (this.temp.schemaType.length > 0) {
        data['schemaType'] = this.temp.outputSchemaType;
      }
      if (this.temp.slidingIntervalCount.length > 0) {
        data['windowConfig'] = { 'slidingIntervalCount': this.temp.slidingIntervalCount };
      }
      if (this.temp.slidingIntervalDurationMs.length > 0) {
        data['windowConfig'] = { 'slidingIntervalDurationMs': this.temp.slidingIntervalDurationMs };
      }
      if (this.temp.windowLengthCount.length > 0) {
        data['windowConfig'] = { 'windowLengthCount': this.temp.windowLengthCount };
      }
      if (this.temp.windowLengthDurationMs > 0) {
        data['windowConfig'] = { 'windowLengthDurationMs': parseInt(this.temp.windowLengthDurationMs) };
      }
      if (this.temp.topicsPattern.length > 0) {
        data['topicsPattern'] = this.temp.topicsPattern;
      }
      if (this.temp.guarantees.length > 0) {
        data['processingGuarantees'] = this.temp.guarantees;
      }
      if (this.temp.userConfig.length > 0) {
        data['userConfig'] = this.temp.userConfig;
      }
      if (this.temp.timeoutMs.length > 0) {
        data['timeoutMs'] = parseInt(this.temp.timeoutMs);
      }
      if (this.temp.subName.length > 0) {
        data['subName'] = this.temp.subName;
      }
      formData.append('functionConfig', _JSON$stringify(data));
      return formData;
    },
    confirmUpdate: function confirmUpdate() {
      var _this13 = this;

      var formData = this.prepareFunctionParams('');
      updateFunction(this.tenant, this.namespace, this.currentFunction, formData).then(function (response) {
        _this13.$notify({
          title: 'success',
          message: 'update function success',
          type: 'success',
          duration: 2000
        });
        _this13.dialogFormVisible = false;
      });
    },
    confirmTrigger: function confirmTrigger() {
      var _this14 = this;

      var formData = new FormData();
      var blob = new Blob([this.temp.triggerFile], { type: 'application/octet-stream' });
      formData.append('dataStream', blob, this.temp.currentTriggerFileName);
      formData.append('topic', this.temp.triggerInput);
      formData.append('data', this.temp.triggerValue);
      triggerFunction(this.tenant, this.namespace, this.currentFunction, formData).then(function (response) {
        _this14.$notify({
          title: 'success',
          message: 'trigger set success for function',
          type: 'success',
          duration: 2000
        });
        _this14.dialogFormVisible = false;
      });
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this15 = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        _this15.tenantsListOptions = response.data;
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this16 = this;

      this.tenant = tenant;
      fetchNamespaces(tenant, this.query).then(function (response) {
        var namespace = [];
        for (var i = 0; i < response.data.length; i++) {
          namespace = response.data[i].split('/');
          // namespace.splice(1, namespace.length).join('/')
          _this16.namespacesListOptions.push(namespace.splice(1, namespace.length).join('/'));
        }
      });
    },
    getFunctionsList: function getFunctionsList(tenant, namespace) {
      fetchFunctions(tenant, namespace).then(function (response) {});
    },
    getTopicsList: function getTopicsList() {
      var _this17 = this;

      this.inputTopicsListOptions = [];
      this.outputTopicsListOptions = [];
      this.triggerInputTopicsListOptions = [];
      fetchPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
          _this17.outputTopicsListOptions.push(response.data[i]);
          _this17.triggerInputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchNonPersistentPartitonsTopics(this.tenant, this.namespace).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
          _this17.outputTopicsListOptions.push(response.data[i]);
          _this17.triggerInputTopicsListOptions.push(response.data[i]);
        }
      });
      fetchTopics(this.tenant, this.namespace, this.listQuery).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this17.inputTopicsListOptions.push(response.data[i]);
          _this17.outputTopicsListOptions.push(response.data[i]);
          _this17.triggerInputTopicsListOptions.push(response.data[i]);
        }
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        autoAck: false,
        function: '',
        instanceId: 0,
        className: '',
        inputTopic: '',
        outputTopic: '',
        language: false,
        cpu: '',
        disk: '',
        ram: '',
        schemaInput: '',
        serdeInput: '',
        serdeOutput: '',
        letterTopic: '',
        guarantees: '',
        fqfn: '',
        logTopic: '',
        parallelism: '',
        retainOrdering: false,
        schemaType: '',
        slidingIntervalCount: '',
        slidingIntervalDurationMs: '',
        maxMessageRetries: '',
        subName: '',
        timeoutMs: '',
        windowLengthCount: '',
        windowLengthDurationMs: '',
        topicsPattern: '',
        currentFile: '',
        currentFileName: '',
        currentJsonFile: '',
        userConfig: ''
      };
    }
  }
};