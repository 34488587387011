//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  beforeCreate: function beforeCreate() {
    var _$route = this.$route,
        params = _$route.params,
        query = _$route.query;
    var path = params.path;

    this.$router.replace({ path: '/' + path, query: query });
  },

  render: function render(h) {
    return h(); // avoid warning message
  }
};