import _toConsumableArray from 'babel-runtime/helpers/toConsumableArray';
import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import _Object$assign from 'babel-runtime/core-js/object/assign';
/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var tagsView = {
  state: {
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
      if (state.visitedViews.some(function (v) {
        return v.path === view.path;
      })) return;
      state.visitedViews.push(_Object$assign({}, view, {
        title: view.meta.title || 'no-name'
      }));
    },
    ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
      if (state.cachedViews.includes(view.name)) return;
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
    },

    DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = _getIterator(state.visitedViews.entries()), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var i = _ref2[0];
          var v = _ref2[1];

          if (v.path === view.path) {
            state.visitedViews.splice(i, 1);
            break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = _getIterator(state.cachedViews), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var i = _step2.value;

          if (i === view.name) {
            var index = state.cachedViews.indexOf(i);
            state.cachedViews.splice(index, 1);
            break;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },

    DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = _getIterator(state.visitedViews.entries()), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var _ref3 = _step3.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var i = _ref4[0];
          var v = _ref4[1];

          if (v.path === view.path) {
            state.visitedViews = state.visitedViews.slice(i, i + 1);
            break;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    },
    DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = _getIterator(state.cachedViews), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var i = _step4.value;

          if (i === view.name) {
            var index = state.cachedViews.indexOf(i);
            state.cachedViews = state.cachedViews.slice(index, index + 1);
            break;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    },

    DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
      state.visitedViews = [];
    },
    DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
      state.cachedViews = [];
    },

    UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = _getIterator(state.visitedViews), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var v = _step5.value;

          if (v.path === view.path) {
            v = _Object$assign(v, view);
            break;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }
    }

  },
  actions: {
    addView: function addView(_ref5, view) {
      var dispatch = _ref5.dispatch;

      dispatch('addVisitedView', view);
      dispatch('addCachedView', view);
    },
    addVisitedView: function addVisitedView(_ref6, view) {
      var commit = _ref6.commit;

      commit('ADD_VISITED_VIEW', view);
    },
    addCachedView: function addCachedView(_ref7, view) {
      var commit = _ref7.commit;

      commit('ADD_CACHED_VIEW', view);
    },
    delView: function delView(_ref8, view) {
      var dispatch = _ref8.dispatch,
          state = _ref8.state;

      return new _Promise(function (resolve) {
        dispatch('delVisitedView', view);
        dispatch('delCachedView', view);
        resolve({
          visitedViews: [].concat(_toConsumableArray(state.visitedViews)),
          cachedViews: [].concat(_toConsumableArray(state.cachedViews))
        });
      });
    },
    delVisitedView: function delVisitedView(_ref9, view) {
      var commit = _ref9.commit,
          state = _ref9.state;

      return new _Promise(function (resolve) {
        commit('DEL_VISITED_VIEW', view);
        resolve([].concat(_toConsumableArray(state.visitedViews)));
      });
    },
    delCachedView: function delCachedView(_ref10, view) {
      var commit = _ref10.commit,
          state = _ref10.state;

      return new _Promise(function (resolve) {
        commit('DEL_CACHED_VIEW', view);
        resolve([].concat(_toConsumableArray(state.cachedViews)));
      });
    },
    delOthersViews: function delOthersViews(_ref11, view) {
      var dispatch = _ref11.dispatch,
          state = _ref11.state;

      return new _Promise(function (resolve) {
        dispatch('delOthersVisitedViews', view);
        dispatch('delOthersCachedViews', view);
        resolve({
          visitedViews: [].concat(_toConsumableArray(state.visitedViews)),
          cachedViews: [].concat(_toConsumableArray(state.cachedViews))
        });
      });
    },
    delOthersVisitedViews: function delOthersVisitedViews(_ref12, view) {
      var commit = _ref12.commit,
          state = _ref12.state;

      return new _Promise(function (resolve) {
        commit('DEL_OTHERS_VISITED_VIEWS', view);
        resolve([].concat(_toConsumableArray(state.visitedViews)));
      });
    },
    delOthersCachedViews: function delOthersCachedViews(_ref13, view) {
      var commit = _ref13.commit,
          state = _ref13.state;

      return new _Promise(function (resolve) {
        commit('DEL_OTHERS_CACHED_VIEWS', view);
        resolve([].concat(_toConsumableArray(state.cachedViews)));
      });
    },
    delAllViews: function delAllViews(_ref14, view) {
      var dispatch = _ref14.dispatch,
          state = _ref14.state;

      return new _Promise(function (resolve) {
        dispatch('delAllVisitedViews', view);
        dispatch('delAllCachedViews', view);
        resolve({
          visitedViews: [].concat(_toConsumableArray(state.visitedViews)),
          cachedViews: [].concat(_toConsumableArray(state.cachedViews))
        });
      });
    },
    delAllVisitedViews: function delAllVisitedViews(_ref15) {
      var commit = _ref15.commit,
          state = _ref15.state;

      return new _Promise(function (resolve) {
        commit('DEL_ALL_VISITED_VIEWS');
        resolve([].concat(_toConsumableArray(state.visitedViews)));
      });
    },
    delAllCachedViews: function delAllCachedViews(_ref16) {
      var commit = _ref16.commit,
          state = _ref16.state;

      return new _Promise(function (resolve) {
        commit('DEL_ALL_CACHED_VIEWS');
        resolve([].concat(_toConsumableArray(state.cachedViews)));
      });
    },
    updateVisitedView: function updateVisitedView(_ref17, view) {
      var commit = _ref17.commit;

      commit('UPDATE_VISITED_VIEW', view);
    }
  }
};

export default tagsView;