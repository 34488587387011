var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: "Tenant" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("tenant.selectTenantMessage"),
                        filterable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.getNamespacesList(_vm.postForm.tenant)
                        }
                      },
                      model: {
                        value: _vm.postForm.tenant,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "tenant", $$v)
                        },
                        expression: "postForm.tenant"
                      }
                    },
                    _vm._l(_vm.tenantsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.namespace"), name: "namespaces" } },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t("namespace.searchNamespaces")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilterNamespace($event)
                      }
                    },
                    model: {
                      value: _vm.searchNamespace,
                      callback: function($$v) {
                        _vm.searchNamespace = $$v
                      },
                      expression: "searchNamespace"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.handleFilterNamespace }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleCreateNamespace }
                    },
                    [_vm._v(_vm._s(_vm.$t("namespace.newNamespace")))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading"
                            }
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.listNamespaces,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("namespace.name"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace?tab=overview"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.namespace))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.topicNumber"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace?tab=topics"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.topics))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inMsg"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outMsg"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inBytes"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outBytes"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-upload2",
                                      staticStyle: { "margin-right": "2px" }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.storageSize"),
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.storageSize))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("table.actions"),
                              align: "center",
                              "class-name": "small-padding fixed-width"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/management/namespaces/" +
                                            scope.row.tenant +
                                            "/" +
                                            scope.row.namespace +
                                            "/namespace"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    scope.row.status != "deleted"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleDeleteNamespace(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("table.delete"))
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("tabs.configuration"),
                name: "configuration"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.$t("tenant.tenantInfo")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("Clusters")]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "split-line" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "component-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "section-title" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("tenant.allowedClustersLabel"))
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: _vm.allowedClustersContent,
                                    effect: "dark",
                                    placement: "top"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-info" })]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "500px",
                                "margin-top": "20px"
                              },
                              attrs: {
                                placeholder: _vm.$t("cluster.selectCluster"),
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  _vm.handleSelectClusters()
                                }
                              },
                              model: {
                                value: _vm.clusterValue,
                                callback: function($$v) {
                                  _vm.clusterValue = $$v
                                },
                                expression: "clusterValue"
                              }
                            },
                            _vm._l(_vm.clusterOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            })
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v(_vm._s(_vm.$t("tenant.permissions")))]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "split-line" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "component-item" }, [
                        _c(
                          "div",
                          { staticClass: "section-title" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("tenant.adminRolesLabel")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.adminRolesContent,
                                  effect: "dark",
                                  placement: "top"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "section-content" },
                          [
                            _vm._l(_vm.dynamicRoles, function(tag) {
                              return _c(
                                "el-tag",
                                {
                                  key: tag,
                                  attrs: {
                                    "disable-transitions": false,
                                    closable: ""
                                  },
                                  on: {
                                    close: function($event) {
                                      _vm.handleClose(tag)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(tag) +
                                      "\n              "
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.inputVisible
                              ? _c("el-input", {
                                  ref: "saveTagInput",
                                  staticClass: "input-new-tag",
                                  attrs: { size: "small" },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleInputConfirm($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.inputValue,
                                    callback: function($$v) {
                                      _vm.inputValue = $$v
                                    },
                                    expression: "inputValue"
                                  }
                                })
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "button-new-tag",
                                    attrs: {
                                      size: "small",
                                      icon: "el-icon-plus"
                                    },
                                    on: { click: _vm.showInput }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("tenant.newRole")))]
                                )
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { color: "#E57470" } }, [
                        _vm._v(_vm._s(_vm.$t("common.dangerZone")))
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "danger-line" }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "danger" },
                          on: { click: _vm.handleDeleteTenant }
                        },
                        [_vm._v(_vm._s(_vm.$t("tenant.deleteTenant")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "temp",
              attrs: {
                model: _vm.temp,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "createNamespace"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("table.namespace"),
                        prop: "namespace"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("namespace.inputNamespaceMessage")
                        },
                        model: {
                          value: _vm.temp.namespace,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "namespace", $$v)
                          },
                          expression: "temp.namespace"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteNamespace"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("namespace.deleteNamespaceMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteTenant"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("tenant.deleteTenantMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.handleOptions()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }