//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Prometheus',
  data: function data() {
    return {
      src: '',
      iframe: 'no'
    };
  },
  created: function created() {
    if (process.env.PROMETHEUS_ADDRESS.length > 0) {
      this.iframe = 'yes';
      this.src = process.env.PROMETHEUS_ADDRESS;
    }
  }
};