var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: { inline: true, model: _vm.postForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("tenant.label") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "select tenant", filterable: "" },
                      on: {
                        change: function($event) {
                          _vm.getNamespacesList(_vm.postForm.tenant)
                        }
                      },
                      model: {
                        value: _vm.postForm.tenant,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "tenant", $$v)
                        },
                        expression: "postForm.tenant"
                      }
                    },
                    _vm._l(_vm.tenantsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("namespace.label") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "select namespace",
                        filterable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.getTopicsList()
                        }
                      },
                      model: {
                        value: _vm.postForm.namespace,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "namespace", $$v)
                        },
                        expression: "postForm.namespace"
                      }
                    },
                    _vm._l(_vm.namespacesListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("topic.label") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "select topic", filterable: "" },
                      on: {
                        change: function($event) {
                          _vm.generatePartitions()
                        }
                      },
                      model: {
                        value: _vm.postForm.topic,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "topic", $$v)
                        },
                        expression: "postForm.topic"
                      }
                    },
                    _vm._l(_vm.topicsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("topic.partition") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.partitionDisabled,
                        filterable: "",
                        placeholder: "select partition"
                      },
                      on: {
                        change: function($event) {
                          _vm.onPartitionChanged()
                        }
                      },
                      model: {
                        value: _vm.postForm.partition,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "partition", $$v)
                        },
                        expression: "postForm.partition"
                      }
                    },
                    _vm._l(_vm.partitionsListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.replicatedClusters.length > 0
            ? _c(
                "el-form",
                {
                  staticClass: "form-container",
                  attrs: { inline: true, model: _vm.clusterForm }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("table.cluster") } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function($event) {
                              _vm.onClusterChanged()
                            }
                          },
                          model: {
                            value: _vm.clusterForm.cluster,
                            callback: function($$v) {
                              _vm.$set(_vm.clusterForm, "cluster", $$v)
                            },
                            expression: "clusterForm.cluster"
                          }
                        },
                        _vm._l(_vm.replicatedClusters, function(cluster) {
                          return _c("el-radio-button", {
                            key: cluster,
                            attrs: { label: cluster }
                          })
                        })
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.overview"), name: "overview" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        { staticStyle: { height: "305px" } },
                        [
                          _c("h4", [_vm._v(_vm._s(_vm.$t("topic.info")))]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.infoData,
                                "show-header": false,
                                border: ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.column"),
                                  prop: "infoColumn"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.data"),
                                  prop: "data"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-top": "15px" },
                              attrs: {
                                type: "danger",
                                icon: "el-icon-download"
                              },
                              on: { click: _vm.handleUnload }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("topic.unload")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.topicStats, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inMsg"), prop: "inMsg" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.outMsg"), prop: "outMsg" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("common.inBytes"), prop: "inBytes" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("common.outBytes"),
                      prop: "outBytes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.$t("topic.producer.producers")))]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.producersListLoading,
                              expression: "producersListLoading"
                            }
                          ],
                          key: _vm.producerTableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.producersList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.producerId"),
                              "min-width": "50px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.producerId))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.producerName"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.producerName))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inMsg"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.inBytes"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.inBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.avgMsgSize"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.avgMsgSize))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.address"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.address))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.producer.since"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.since))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("topic.subscription.subscriptions")))
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { type: "success" },
                  on: { click: _vm.handleCreateSub }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("topic.subscription.newSub")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.subscriptionsListLoading,
                              expression: "subscriptionsListLoading"
                            }
                          ],
                          key: _vm.subscriptionTableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.subscriptionsList,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.subscription.name"),
                              "min-width": "50px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link-type",
                                        attrs: {
                                          to: scope.row.subscriptionLink
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.subscription))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.subscription.type"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [_vm._v(_vm._s(scope.row.type))])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outMsg"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outMsg))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("common.outBytes"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.outBytes))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.subscription.msgExpired"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.msgExpired))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("topic.subscription.backlog"),
                              "min-width": "30px",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.backlog))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown",
                                      {
                                        on: {
                                          command: function($event) {
                                            _vm.handleCommand(
                                              scope.row.subscription
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "el-dropdown-link" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-more"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown"
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "link-type",
                                                attrs: {
                                                  to:
                                                    scope.row.subscriptionLink +
                                                    "?topTab=backlogOperation&leftTab=skip"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: { command: "skip" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "topic.subscription.skip"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "link-type",
                                                attrs: {
                                                  to:
                                                    scope.row.subscriptionLink +
                                                    "?topTab=backlogOperation&leftTab=expire"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: { command: "expire" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "topic.subscription.expire"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "link-type",
                                                attrs: {
                                                  to:
                                                    scope.row.subscriptionLink +
                                                    "?topTab=backlogOperation&leftTab=clear"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: { command: "clear" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "topic.subscription.clear"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "link-type",
                                                attrs: {
                                                  to:
                                                    scope.row.subscriptionLink +
                                                    "?topTab=backlogOperation&leftTab=reset"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: { command: "reset" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "topic.subscription.reset"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "link-type",
                                                attrs: {
                                                  to:
                                                    scope.row.subscriptionLink +
                                                    "?topTab=backlogOperation&leftTab=peek"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: { command: "peek" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "topic.subscription.peek"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "unsub" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "topic.subscription.unsub"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.nonPersistent === false
            ? _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("tabs.storage"), name: "storage" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "always" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("topic.subscription.storageSize")
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "circle",
                                  attrs: { type: "primary" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "200%" } },
                                    [_vm._v(_vm._s(_vm.storageSize))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "always" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("topic.subscription.entries")
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "circle",
                                  attrs: { type: "primary" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "200%" } },
                                    [_vm._v(_vm._s(_vm.entries))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "always" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("topic.subscription.segments")
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "circle",
                                  attrs: { type: "primary" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "200%" } },
                                    [_vm._v(_vm._s(_vm.segments))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h4", [_vm._v(_vm._s(_vm.$t("topic.segment.label")))]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            xs: { span: 24 },
                            sm: { span: 24 },
                            md: { span: 24 },
                            lg: { span: 24 },
                            xl: { span: 24 }
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.segmentsListLoading,
                                  expression: "segmentsListLoading"
                                }
                              ],
                              key: _vm.segmentTableKey,
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.segmentsList,
                                border: "",
                                fit: "",
                                "highlight-current-row": ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.segment.ledgerId"),
                                  "min-width": "50px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.ledgerId))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.segment.entries"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.entries))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.segment.size"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.size))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.segment.status"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.status))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.segment.offload"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.offload))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h4", [_vm._v(_vm._s(_vm.$t("topic.cursor.cursors")))]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            xs: { span: 24 },
                            sm: { span: 24 },
                            md: { span: 24 },
                            lg: { span: 24 },
                            xl: { span: 24 }
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.cursorListLoading,
                                  expression: "cursorListLoading"
                                }
                              ],
                              key: _vm.cursorTableKey,
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.cursorsList,
                                border: "",
                                fit: "",
                                "highlight-current-row": ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.cursor.label"),
                                  "min-width": "50px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.name))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "topic.cursor.markDeletePosition"
                                  ),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.markDeletePosition)
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.cursor.readPosition"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.readPosition))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.cursor.waitingReadOp"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.waitingReadOp)
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("topic.cursor.pendingReadOp"),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.pendingReadOps)
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "topic.cursor.numberOfEntriesSinceFirstNotAckedMessage"
                                  ),
                                  "min-width": "30px",
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .numberOfEntriesSinceFirstNotAckedMessage
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.policies"), name: "policies" } },
            [
              _c(
                "h4",
                [
                  _vm._v(
                    _vm._s(_vm.$t("topic.policy.authentication")) + "\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.authorizationContent,
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-info" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "split-line" }),
              _vm._v(" "),
              _c(
                "el-form",
                [
                  _vm._l(_vm.dynamicTags, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        staticClass: "role-el-tag",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { label: tag, "disable-transitions": false }
                      },
                      [
                        _c("div", [
                          _c("span", [_vm._v(" " + _vm._s(tag) + " ")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: {
                              multiple: "",
                              placeholder: "Please Select Options"
                            },
                            on: {
                              change: function($event) {
                                _vm.handleChangeOptions(tag)
                              }
                            },
                            model: {
                              value: _vm.roleMap[tag],
                              callback: function($$v) {
                                _vm.$set(_vm.roleMap, tag, $$v)
                              },
                              expression: "roleMap[tag]"
                            }
                          },
                          _vm._l(_vm.roleMapOptions[tag], function(item) {
                            return _c("el-option", {
                              key: item.value,
                              staticStyle: { width: "300px" },
                              attrs: { label: item.label, value: item.value }
                            })
                          })
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.handleClose(tag)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("topic.delete")))]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            staticStyle: {
                              "margin-right": "10px",
                              width: "200px",
                              "vertical-align": "top"
                            },
                            attrs: { size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              }
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showInput()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("topic.addRole")))]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("h4", { staticStyle: { color: "#E57470" } }, [
                _vm._v(_vm._s(_vm.$t("common.dangerZone")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "danger-line" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDeleteTopic }
                },
                [_vm._v(_vm._s(_vm.$t("topic.deleteTopic")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _vm.dialogStatus === "delete"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("topic.deleteTopicMessage")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "createSub"
                ? _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Please input sub name" },
                        model: {
                          value: _vm.subName,
                          callback: function($$v) {
                            _vm.subName = $$v
                          },
                          expression: "subName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "deleteSub"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(_vm.$t("topic.subscription.deleteSubConfirm"))
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.dialogStatus === "delete"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteTopic }
                        },
                        [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dialogStatus === "createSub"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createSub }
                        },
                        [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dialogStatus === "deleteSub"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteSub }
                        },
                        [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }