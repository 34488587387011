import _Object$keys from 'babel-runtime/core-js/object/keys';
/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import Vue from 'vue';

import Cookies from 'js-cookie';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/index.scss'; // global css

import App from './App';
import router from './router';
import store from './store';

import _i18n from './lang'; // Internationalization
import './icons'; // icon
import './errorLog'; // error log
import './permission'; // permission control

import * as filters from './filters'; // global filters

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});

// register global utility filters.
_Object$keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

import Casdoor from 'casdoor-vue-sdk';
import VueCompositionAPI from '@vue/composition-api';
var config = {
  serverUrl: "http://localhost:7001",
  clientId: "6ba06c1e1a30929fdda7",
  organizationName: "casbin",
  appName: "plusar",
  redirectPath: "/#callback"
};
Vue.use(VueCompositionAPI);
Vue.use(Casdoor, config);
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});