import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchClusters, updateCluster, listClusterDomainName, deleteCluster as _deleteCluster, createClusterDomainName, deleteClusterDomainName } from '@/api/clusters';
import { fetchBrokerStatsMetrics } from '@/api/brokerStats';
import { fetchBrokers, fetchBrokersByDirectBroker } from '@/api/brokers';
import { fetchIsolationPolicies, deleteIsolationPolicies } from '@/api/isolationPolicies';
import { getBookiesList as _getBookiesList } from '@/api/bookies';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import MdInput from '@/components/MDinput';
import { validateEmpty, validateServiceUrl } from '@/utils/validate';
import { formatBytes, getQueryObject } from '@/utils/index';
import { numberFormatter } from '@/filters/index';
import permission from '@/directive/permission/index.js';

var defaultForm = {
  cluster: ''
};
export default {
  name: 'ClusterInfo',
  directives: { permission: permission },
  components: {
    Pagination: Pagination,
    MdInput: MdInput
  },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      localList: [],
      listQuery: {
        cluster: '',
        page: 1,
        limit: 10
      },
      activeName: 'brokers',
      clustersListOptions: [],
      form: {
        httpServiceUrl: '',
        httpsServiceUrl: '',
        brokerServiceUrl: '',
        brokerServiceUrlTls: ''
      },
      brokerOptions: [],
      temp: {
        domainName: '',
        policyName: '',
        brokerValue: []
      },
      rules: {
        httpServiceUrl: [{ required: true, message: this.$i18n.t('cluster.serviceUrlIsRequired'), trigger: 'change' }, { validator: validateServiceUrl('http:', false), trigger: 'blur' }],
        httpsServiceUrl: [{ validator: validateServiceUrl('https:', true), trigger: 'blur' }],
        brokerServiceUrl: [{ required: true, message: this.$i18n.t('cluster.serviceUrlIsRequired'), trigger: 'change' }, { validator: validateServiceUrl('pulsar:', false), trigger: 'blur' }],
        brokerServiceUrlTls: [{ validator: validateServiceUrl('pulsar+ssl:', true), trigger: 'blur' }],
        domainName: [{ required: true, message: this.$i18n.t('fd.domainNameIsRequired'), trigger: 'change' }]
      },
      failureDomainList: [],
      brokersList: [],
      brokerTableKey: 0,
      isolationTableKey: 0,
      isolationPoliciesList: [],
      isolationPolicyKey: '',
      failureDomainsKey: '',
      dialogFormVisible: false,
      dialogStatus: '',
      bookieTableKey: 0,
      bookiesList: [],
      textMap: {
        createFailureDomain: this.$i18n.t('fd.createFdTitle'),
        deleteCluster: this.$i18n.t('cluster.deleteClusterDialogCaption'),
        deleteDomain: this.$i18n.t('fd.deleteDomainDialogCaption'),
        deletePolicy: this.$i18n.t('ip.deletePolicyDialogCaption')
      },
      tempIsolationPolicyList: [],
      tempFailureDomainList: [],
      deleteClusterMessage: this.$i18n.t('cluster.deleteClusterMessage'),
      deleteFdMessage: this.$i18n.t('fd.deleteFdMessage'),
      deletePolicyMessage: this.$i18n.t('ip.deletePolicyMessage'),
      currentActiveTab: '',
      key: 1
    };
  },
  created: function created() {
    this.postForm.cluster = this.$route.params && this.$route.params.cluster;
    this.getClusterList();
    this.getBrokerList();
    if (this.$route.query && this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
      if (this.activeName === 'isolationPolicies') {
        this.getNamespaceIsolationPolicy();
      }
    }
    this.getFailureDomainsList();
    this.getBookiesList();
  },

  methods: {
    getBookiesList: function getBookiesList() {
      var _this = this;

      _getBookiesList(this.postForm.cluster).then(function (response) {
        if (response.data.enableBookieHttp) {
          for (var i = 0; i < response.data.data.length; i++) {
            var bookieInfo = {};
            if (response.data.data[i].status === 'rw') {
              bookieInfo['status'] = 'Writable';
            } else if (response.data.data[i].status === 'ro') {
              bookieInfo['status'] = 'Readonly';
            }
            var storage = (parseInt(response.data.data[i].storage[1]) - parseInt(response.data.data[i].storage[0])) / parseInt(response.data.data[i].storage[1]);
            bookieInfo['storage'] = storage.toFixed(2) * 100;
            bookieInfo['bookie'] = response.data.data[i].bookie;
            bookieInfo['cluster'] = _this.postForm.cluster;
            _this.bookiesList.push(bookieInfo);
          }
        }
      });
    },
    getClusterList: function getClusterList() {
      var _this2 = this;

      fetchClusters(this.listQuery).then(function (response) {
        for (var i = 0; i < response.data.data.length; i++) {
          _this2.clustersListOptions.push(response.data.data[i].cluster);
          if (response.data.data[i].cluster === _this2.postForm.cluster) {
            _this2.form.httpServiceUrl = response.data.data[i].serviceUrl;
            _this2.form.httpsServiceUrl = response.data.data[i].serviceUrlTls;
            _this2.form.brokerServiceUrl = response.data.data[i].brokerServiceUrl;
            _this2.form.brokerServiceUrlTls = response.data.data[i].brokerServiceUrlTls;
          }
        }
      });
    },
    getBrokerList: function getBrokerList() {
      var _this3 = this;

      fetchBrokers(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        var tempBroker = {};
        for (var i = 0; i < response.data.data.length; i++) {
          var brokerInfo = {};
          brokerInfo['cluster'] = _this3.postForm.cluster;
          brokerInfo['broker'] = response.data.data[i].broker;
          brokerInfo['ownedNamespaces'] = 0;
          brokerInfo['throughputIn'] = formatBytes(0);
          brokerInfo['throughputOut'] = formatBytes(0);
          brokerInfo['msgRateOut'] = numberFormatter(0, 2);
          brokerInfo['msgRateIn'] = numberFormatter(0, 2);
          brokerInfo['failureDomain'] = response.data.data[i].failureDomain;
          _this3.brokersList.push(brokerInfo);
          tempBroker[response.data.data[i].broker] = i;
          fetchBrokerStatsMetrics(response.data.data[i].broker).then(function (res) {
            var url = getQueryObject(res.request.responseURL);
            var throughputIn = 0;
            var throughputOut = 0;
            var msgRateIn = 0;
            var msgRateOut = 0;
            var numberNamespaces = 0;
            for (var m = 0; m < res.data.length; m++) {
              if (res.data[m].dimensions.hasOwnProperty('namespace')) {
                if (res.data[m].dimensions.namespace.split('/').length === 2) {
                  throughputIn += res.data[m].metrics.brk_in_tp_rate;
                  throughputOut += res.data[m].metrics.brk_out_tp_rate;
                  msgRateIn += res.data[m].metrics.brk_in_rate;
                  msgRateOut += res.data[m].metrics.brk_out_rate;
                  numberNamespaces += 1;
                }
              }
            }
            _this3.brokersList[tempBroker[url['broker']]]['ownedNamespaces'] = numberNamespaces;
            _this3.brokersList[tempBroker[url['broker']]]['throughputIn'] = formatBytes(throughputIn);
            _this3.brokersList[tempBroker[url['broker']]]['throughputOut'] = formatBytes(throughputOut);
            _this3.brokersList[tempBroker[url['broker']]]['msgRateOut'] = numberFormatter(msgRateOut, 2);
            _this3.brokersList[tempBroker[url['broker']]]['msgRateIn'] = numberFormatter(msgRateIn, 2);
          });
        }
      });
    },
    getNamespaceIsolationPolicy: function getNamespaceIsolationPolicy() {
      var _this4 = this;

      fetchIsolationPolicies(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        _this4.isolationPoliciesList = [];
        _this4.tempIsolationPolicyList = [];
        for (var key in response.data) {
          var policy = {
            'cluster': _this4.postForm.cluster,
            'isolationPolicy': key,
            'numberOfPrimaryBrokers': response.data[key].primary.length,
            'numberOfSecondaryBrokers': response.data[key].secondary.length
          };
          _this4.isolationPoliciesList.push(policy);
          _this4.tempIsolationPolicyList.push(policy);
        }
      });
    },
    getClusterInfo: function getClusterInfo() {
      this.$router.push({ path: '/management/clusters/' + this.postForm.cluster + '/cluster?tab=' + this.activeName });
    },
    handleClick: function handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name === 'isolationPolicies') {
        this.getNamespaceIsolationPolicy();
      }
      this.$router.push({ query: { 'tab': tab.name } });
    },
    handleServiceUrl: function handleServiceUrl() {
      var _this5 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var data = {
            'serviceUrl': _this5.form.httpServiceUrl,
            'serviceUrlTls': _this5.form.httpsServiceUrl,
            'brokerServiceUrl': _this5.form.brokerServiceUrl,
            'brokerServiceUrlTls': _this5.form.brokerServiceUrlTls
          };
          updateCluster(_this5.postForm.cluster, data).then(function () {
            _this5.$notify({
              title: 'success',
              message: _this5.$i18n.t('cluster.updateClusterSuccessNotification'),
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    getFailureDomainsList: function getFailureDomainsList() {
      var _this6 = this;

      listClusterDomainName(this.postForm.cluster).then(function (response) {
        if (!response.data) return;
        _this6.failureDomainList = [];
        _this6.tempFailureDomainList = [];
        for (var key in response.data) {
          var domain = {
            'cluster': _this6.postForm.cluster,
            'domain': key,
            'brokers': response.data[key].brokers.length
          };
          _this6.failureDomainList.push(domain);
          _this6.tempFailureDomainList.push(domain);
        }
      });
    },
    getSelectBrokers: function getSelectBrokers() {
      var _this7 = this;

      fetchBrokersByDirectBroker(this.postForm.cluster).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          _this7.brokerOptions.push({
            value: response.data[i],
            label: response.data[i]
          });
        }
      });
    },
    newFailureDomain: function newFailureDomain() {
      var _this8 = this;

      this.temp.domainName = '';
      this.temp.brokerValue = [];
      this.brokerOptions = [];
      this.getSelectBrokers();
      this.dialogFormVisible = true;
      this.dialogStatus = 'createFailureDomain';
      this.$nextTick(function () {
        _this8.$refs['temp'].clearValidate();
      });
    },
    handleCreateFailureDomain: function handleCreateFailureDomain() {
      var _this9 = this;

      this.$refs['temp'].validate(function (valid) {
        if (valid) {
          var data = {
            'brokers': _this9.temp.brokerValue
          };
          createClusterDomainName(_this9.postForm.cluster, _this9.temp.domainName, data).then(function (response) {
            _this9.$notify({
              title: 'success',
              message: _this9.$i18n.t('fd.createFdSuccessNotification'),
              type: 'success',
              duration: 3000
            });
            _this9.dialogFormVisible = false;
            _this9.getFailureDomainsList();
          });
        }
      });
    },
    handleCreatePolicy: function handleCreatePolicy() {
      this.$router.push({ path: '/management/clusters/' + this.postForm.cluster + '/create/namespaceIsolationPolicy?created=true' });
    },
    handleFilter: function handleFilter() {},
    handleDelete: function handleDelete() {
      this.dialogFormVisible = true;
      this.dialogStatus = 'deleteCluster';
    },
    deleteCluster: function deleteCluster() {
      var _this10 = this;

      _deleteCluster(this.postForm.cluster).then(function (response) {
        _this10.$notify({
          title: 'success',
          message: _this10.$i18n.t('cluster.deleteClusterSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this10.dialogFormVisible = false;
        _this10.$router.push({ path: '/management/clusters' });
      });
    },
    handleFailureDomainFilter: function handleFailureDomainFilter() {
      if (!validateEmpty(this.failureDomainsKey)) {
        var searchFailureDomainList = [];
        for (var i = 0; i < this.tempFailureDomainList.length; i++) {
          if (this.tempFailureDomainList[i]['domain'].indexOf(this.failureDomainsKey) !== -1) {
            searchFailureDomainList.push(this.tempFailureDomainList[i]);
          }
        }
        this.failureDomainList = searchFailureDomainList;
      } else {
        this.failureDomainList = this.tempFailureDomainList;
      }
    },
    handleDeleteFailureDomain: function handleDeleteFailureDomain(row) {
      this.dialogFormVisible = true;
      this.dialogStatus = 'deleteDomain';
      this.temp.domainName = row.domain;
    },
    deleteDomain: function deleteDomain() {
      var _this11 = this;

      deleteClusterDomainName(this.postForm.cluster, this.temp.domainName).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: _this11.$i18n.t('fd.deleteFdSuccessNotification'),
          type: 'success',
          duration: 3000
        });
        _this11.dialogFormVisible = false;
        _this11.getFailureDomainsList();
      });
    },
    handleDeletePolicy: function handleDeletePolicy(row) {
      this.dialogFormVisible = true;
      this.dialogStatus = 'deletePolicy';
      this.temp.policyName = row.isolationPolicy;
    },
    deletePolicy: function deletePolicy() {
      var _this12 = this;

      deleteIsolationPolicies(this.postForm.cluster, this.temp.policyName).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: _this12.$i18n.t('ip.deletePolicySuccessNotification'),
          type: 'success',
          duration: 3000
        });
        _this12.dialogFormVisible = false;
        _this12.getNamespaceIsolationPolicy();
      });
    },
    handlePolicyFilter: function handlePolicyFilter() {
      if (!validateEmpty(this.isolationPolicyKey)) {
        var searchIsolationPolicyList = [];
        for (var i = 0; i < this.tempIsolationPolicyList.length; i++) {
          if (this.tempIsolationPolicyList[i]['isolationPolicy'].indexOf(this.isolationPolicyKey) !== -1) {
            searchIsolationPolicyList.push(this.tempIsolationPolicyList[i]);
          }
        }
        this.isolationPoliciesList = searchIsolationPolicyList;
      } else {
        this.isolationPoliciesList = this.tempIsolationPolicyList;
      }
    }
  }
};