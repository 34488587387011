/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import store from '@/store';

var _document = document,
    body = _document.body;

var WIDTH = 1024;
var RATIO = 3;

export default {
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        store.dispatch('closeSideBar', { withoutAnimation: false });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.isMobile();
    if (isMobile) {
      store.dispatch('toggleDevice', 'mobile');
      store.dispatch('closeSideBar', { withoutAnimation: true });
    }
  },

  methods: {
    isMobile: function isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - RATIO < WIDTH;
    },
    resizeHandler: function resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.isMobile();
        store.dispatch('toggleDevice', isMobile ? 'mobile' : 'desktop');

        if (isMobile) {
          store.dispatch('closeSideBar', { withoutAnimation: true });
        }
      }
    }
  }
};