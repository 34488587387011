//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Page404',
  computed: {
    message: function message() {
      return 'NOT FOUND';
    }
  }
};