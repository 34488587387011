var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("ip.heading")))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-container",
              attrs: {
                inline: true,
                model: _vm.postForm,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: { label: _vm.$t("common.clusterLabel") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("cluster.selectCluster") },
                      on: {
                        change: function($event) {
                          _vm.getClusterList(_vm.postForm.cluster)
                        }
                      },
                      model: {
                        value: _vm.postForm.cluster,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "cluster", $$v)
                        },
                        expression: "postForm.cluster"
                      }
                    },
                    _vm._l(_vm.clustersListOptions, function(item, index) {
                      return _c("el-option", {
                        key: item + index,
                        attrs: { label: item, value: item }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.created === false
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "postInfo-container-item",
                      attrs: { label: _vm.$t("ip.label") }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: _vm.$t("ip.selectIp") },
                          on: {
                            change: function($event) {
                              _vm.postForm.cluster
                            }
                          },
                          model: {
                            value: _vm.postForm.policy,
                            callback: function($$v) {
                              _vm.$set(_vm.postForm, "policy", $$v)
                            },
                            expression: "postForm.policy"
                          }
                        },
                        _vm._l(_vm.policiesListOptions, function(item, index) {
                          return _c("el-option", {
                            key: item + index,
                            attrs: { label: item, value: item }
                          })
                        })
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.created === true
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "postInfo-container-item",
                      attrs: { label: _vm.$t("ip.label") }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: _vm.$t("ip.newIpName"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.policyName,
                          callback: function($$v) {
                            _vm.policyName = $$v
                          },
                          expression: "policyName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t("common.namespacesLabel")))]),
          _vm._v(" "),
          _c("hr", { staticClass: "split-line" }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._v(_vm._s(_vm.$t("ip.selectNsLabel")) + "\n      "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.namespaceContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.namespaceDynamicTags, function(ntag, index) {
            return _c(
              "el-tag",
              {
                key: "namespace-" + index,
                attrs: { "disable-transitions": false, closable: "" },
                on: {
                  close: function($event) {
                    _vm.handleCloseNamespace(ntag)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(ntag) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm.inputVisibleNamespace
            ? _c("el-input", {
                ref: "saveTagInputNamespace",
                staticClass: "input-new-tag",
                attrs: { size: "small" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleInputConfirmNamespace($event)
                  }
                },
                model: {
                  value: _vm.inputValueNamespace,
                  callback: function($$v) {
                    _vm.inputValueNamespace = $$v
                  },
                  expression: "inputValueNamespace"
                }
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { icon: "el-icon-plus", size: "small" },
                  on: { click: _vm.showInputNamespace }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("common.regex")) + "\n    ")]
              ),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("ip.pbHeading")))]),
          _vm._v(" "),
          _c("hr", { staticClass: "split-line" }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._v(_vm._s(_vm.$t("ip.selectPbLabel")) + "\n      "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.brokerContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.primaryDynamicTags, function(ptag, index) {
            return _c(
              "el-tag",
              {
                key: "primary-" + index,
                attrs: { "disable-transitions": false, closable: "" },
                on: {
                  close: function($event) {
                    _vm.handleClosePrimary(ptag)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(ptag) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm.inputVisiblePrimary
            ? _c("el-input", {
                ref: "saveTagInputPrimary",
                staticClass: "input-new-tag",
                attrs: { size: "small" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleInputConfirmPrimary($event)
                  }
                },
                model: {
                  value: _vm.inputValuePrimary,
                  callback: function($$v) {
                    _vm.inputValuePrimary = $$v
                  },
                  expression: "inputValuePrimary"
                }
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { icon: "el-icon-plus", size: "small" },
                  on: { click: _vm.showInputPrimary }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("common.regex")) + "\n    ")]
              ),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("ip.sbHeading")))]),
          _vm._v(" "),
          _c("hr", { staticClass: "split-line" }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._v(_vm._s(_vm.$t("ip.selectSbLabel")) + "\n      "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.secondaryBrokersContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.secondaryDynamicTags, function(stag, index) {
            return _c(
              "el-tag",
              {
                key: "secondary-" + index,
                attrs: { "disable-transitions": false, closable: "" },
                on: {
                  close: function($event) {
                    _vm.handleCloseSecondary(stag)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(stag) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm.inputVisibleSecondary
            ? _c("el-input", {
                ref: "saveTagInputSecondary",
                staticClass: "input-new-tag",
                attrs: { size: "small" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleInputConfirmSecondary($event)
                  }
                },
                model: {
                  value: _vm.inputValueSecondary,
                  callback: function($$v) {
                    _vm.inputValueSecondary = $$v
                  },
                  expression: "inputValueSecondary"
                }
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { icon: "el-icon-plus", size: "small" },
                  on: { click: _vm.showInputSecondary }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("common.regex")) + "\n    ")]
              ),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("ip.afpHeading")))]),
          _vm._v(" "),
          _c("hr", { staticClass: "split-line" }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._v(_vm._s(_vm.$t("ip.ptHeading")) + "\n      "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: _vm.policyTypeContent,
                    effect: "dark",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-info" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { "margin-top": "20px", width: "300px" },
              attrs: { placeholder: _vm.$t("ip.selectAfpPh") },
              model: {
                value: _vm.autoFailoverPolicy,
                callback: function($$v) {
                  _vm.autoFailoverPolicy = $$v
                },
                expression: "autoFailoverPolicy"
              }
            },
            _vm._l(_vm.autoFailoverPolicyOptions, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "ensembleSize" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("ip.brokerUsageThresholdLabel")) + " (%)"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.brokerUsageThresholdContent,
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-info" })]
                  ),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "md-input-style",
                    attrs: {
                      placeholder: _vm.$t("ip.brokerUsageThresholdPh"),
                      name: "brokerUsageThreshold"
                    },
                    model: {
                      value: _vm.form.brokerUsageThreshold,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "brokerUsageThreshold", $$v)
                      },
                      expression: "form.brokerUsageThreshold"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "writeQuorumSize" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ip.minimalAvailableBrokerLabel")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.minimalAvailableBrokerContent,
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-info" })]
                  ),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "md-input-style",
                    attrs: {
                      placeholder: _vm.$t("ip.minimalAvailableBrokerPh"),
                      name: "minimalAvailableBroker"
                    },
                    model: {
                      value: _vm.form.minimalAvailableBroker,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "minimalAvailableBroker", $$v)
                      },
                      expression: "form.minimalAvailableBroker"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.created === true
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleIsolationPolicy }
                    },
                    [_vm._v("Create Policy")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.created === false
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleIsolationPolicy }
                    },
                    [_vm._v("Update Policy")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.created === false
        ? _c(
            "div",
            [
              _c("h4", { staticStyle: { color: "#E57470" } }, [
                _vm._v(_vm._s(_vm.$t("common.dangerZone")))
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "danger-line" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDelete }
                },
                [_vm._v("Delete Policy")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            title: _vm.textMap[_vm.dialogStatus],
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c("el-form", { attrs: { "label-position": "top" } }, [
            _vm.dialogStatus === "delete"
              ? _c(
                  "div",
                  [
                    _c("el-form-item", [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("ip.deletePolicyMessage")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.deletePolicy()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogFormVisible = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }