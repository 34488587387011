var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.superUser
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleCreateEnvironment }
            },
            [_vm._v(_vm._s(_vm.$t("env.buttonNewEnv")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 }
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.environmentListLoading,
                      expression: "environmentListLoading"
                    }
                  ],
                  key: _vm.environmentTableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.environmentList,
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("env.colHeadingEnv"),
                      "min-width": "50px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "link-type",
                                attrs: { to: "#" },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.handleSetEnvironment(
                                      scope.row.environment
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.environment))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("env.colHeadingServiceUrl"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.broker))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("env.colHeadingBookieUrl"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.bookie))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm.superUser
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("table.actions"),
                          align: "center",
                          "class-name": "small-padding fixed-width"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        _vm.handleUpdateEnvironment(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("table.edit")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        _vm.handleDeleteEnvironment(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("table.delete")))]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("env.newEnvNameLabel"),
                        prop: "environment"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("env.newEnvNamePlaceHolder")
                        },
                        model: {
                          value: _vm.form.environment,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "environment", $$v)
                          },
                          expression: "form.environment"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("env.newEnvServiceUrlLabel"),
                        prop: "broker"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("env.newEnvServiceUrlPlaceHolder")
                        },
                        model: {
                          value: _vm.form.broker,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "broker", $$v)
                          },
                          expression: "form.broker"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("env.newEnvBookieUrlLabel"),
                        prop: "bookie"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("env.newEnvBookieUrlPlaceHolder")
                        },
                        model: {
                          value: _vm.form.bookie,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bookie", $$v)
                          },
                          expression: "form.bookie"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("env.updateEnvNameLabel") } },
                    [
                      _c(
                        "el-tag",
                        { attrs: { type: "primary", size: "medium" } },
                        [_vm._v(_vm._s(_vm.form.environment))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("env.updateEnvServiceUrlLabel"),
                        prop: "broker"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "env.updateEnvServiceUrlPlaceHolder"
                          )
                        },
                        model: {
                          value: _vm.form.broker,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "broker", $$v)
                          },
                          expression: "form.broker"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("env.updateEnvBookieUrlLabel"),
                        prop: "bookie"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "env.updateEnvBookieUrlPlaceHolder"
                          )
                        },
                        model: {
                          value: _vm.form.bookie,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bookie", $$v)
                          },
                          expression: "form.bookie"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "delete"
                ? _c("el-form-item", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("env.deleteEnvDialogText")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.handleOptions()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }