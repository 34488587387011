import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/views/layout/Layout';

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export var constantRouterMap = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/callback',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/callback/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}];

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return { y: 0 };
  },
  routes: constantRouterMap
});

export var asyncRouterMap = [{
  path: '/environments',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/management/environments/index'));
    });
  },
  hidden: true,
  meta: {
    roles: ['super', 'admin']
  }
}, {
  path: '',
  component: Layout,
  redirect: 'management/tenants',
  meta: {
    title: 'Dashboard',
    icon: 'dashboard',
    roles: ['super']
  },
  hidden: true,
  children: [{
    path: 'prometheus',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/prometheus/index'));
      });
    },
    name: 'Prometheus',
    meta: { title: 'prometheus', noCache: true }
  }, {
    path: 'grafana',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/grafana/index'));
      });
    },
    name: 'Grafana',
    meta: { title: 'grafana', noCache: true }
  }]
}, {
  path: '/management',
  component: Layout,
  name: 'Management',
  redirect: 'management/roles',
  meta: {
    title: 'Management',
    icon: 'component',
    roles: ['super', 'admin']
  },
  children: [{
    path: 'clusters',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/clusters/index'));
      });
    },
    name: 'Clusters',
    meta: {
      title: 'Clusters',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'clusters/:cluster/cluster',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/clusters/cluster'));
      });
    },
    name: 'ClusterInfo',
    meta: {
      title: 'ClusterInfo',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'clusters/:cluster/:failureDomainName/failureDomainName',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/clusters/failureDomain'));
      });
    },
    name: 'FailureDomainInfo',
    meta: {
      title: 'FailureDomainInfo',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'clusters/:cluster/:namespaceIsolation/namespaceIsolationPolicy',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/namespaceIsolations/namespaceIsolationPolicy'));
      });
    },
    name: 'NamespaceIsolationPolicy',
    meta: {
      title: 'NamespaceIsolationPolicy',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'brokers/:cluster/:broker/broker',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/brokers/broker'));
      });
    },
    name: 'BrokerInfo',
    meta: {
      title: 'BrokerInfo',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'tenants',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/tenants/index'));
      });
    },
    name: 'Tenants',
    meta: {
      title: 'Tenants',
      noCache: true,
      roles: ['super']
    }
  }, {
    path: 'tenants/tenantInfo/:tenant',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/tenants/tenant'));
      });
    },
    name: 'Tenant',
    meta: {
      title: 'TenantInfo',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'admin/tenants/tenantInfo',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/admin/tenants/tenant'));
      });
    },
    name: 'Tenant Admin',
    meta: {
      title: 'TenantInfo',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: true
  }, {
    path: 'tenants/tenantInfo/public?tab=namespaces',
    name: 'Namespaces',
    meta: {
      title: 'Namespaces',
      noCache: true,
      roles: ['super']
    }
  }, {
    path: 'namespaces/public/default/namespace?tab=topics',
    name: 'Topics',
    meta: {
      title: 'Topics',
      noCache: true,
      roles: ['super']
    }
  }, {
    path: 'topics/:persistent/:tenant/:namespace/:topic/topic',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/topics/topic'));
      });
    },
    name: 'TopicInfo',
    meta: {
      title: 'TopicInfo',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: true
  }, {
    path: 'topics/:persistent/:tenant/:namespace/:topic/partitionedTopic',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/topics/partitionedTopic'));
      });
    },
    name: 'ParititionTopicInfo',
    meta: {
      title: 'ParititionTopicInfo',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: true
  }, {
    path: 'subscriptions/:persistent/:tenant/:namespace/:topic/:subscription/subscription',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/subscriptions/subscription'));
      });
    },
    name: 'SubscriptionInfo',
    meta: {
      title: 'SubscriptionInfo',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: true
  }, {
    path: 'namespaces/:tenant',
    name: 'NamespacesTenant',
    meta: {
      title: 'Namespaces',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'namespaces/:tenant/:namespace/namespace',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/namespaces/namespace'));
      });
    },
    name: 'NamespacesInfo',
    meta: {
      title: 'NamespacesInfo',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: true
  }, {
    path: 'functions',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/functions'));
      });
    },
    name: 'Functions',
    meta: {
      title: 'Functions',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'sources',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/sources'));
      });
    },
    name: 'Sources',
    meta: {
      title: 'Sources',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'sinks',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/sinks'));
      });
    },
    name: 'Sinks',
    meta: {
      title: 'Sinks',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: 'bookies',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/bookies'));
      });
    },
    name: 'Bookies',
    meta: {
      title: 'Bookies',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: '/users',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/users/index'));
      });
    },
    name: 'Users',
    meta: {
      title: 'Users',
      noCache: true,
      roles: ['super']
    },
    hidden: true
  }, {
    path: '/tokens',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/management/tokens/index'));
      });
    },
    name: 'Tokens',
    meta: {
      title: 'Tokens',
      noCache: true,
      roles: ['super', 'admin']
    },
    hidden: false
  }]
}, {
  path: 'external-link',
  component: Layout,
  children: [{
    path: 'https://pulsar.apache.org',
    meta: { title: 'Apache Pulsar', icon: 'link' }
  }]
}, { path: '*', redirect: '/404', hidden: true }];