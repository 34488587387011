import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CodeMirror from 'codemirror';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';
require('script-loader!jsonlint');
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';

export default {
  name: 'JsonEditor',
  /* eslint-disable vue/require-prop-types */
  props: ['value'],
  data: function data() {
    return {
      jsonEditor: false
    };
  },

  watch: {
    value: function value(_value) {
      var editor_value = this.jsonEditor.getValue();
      if (_value !== editor_value) {
        this.jsonEditor.setValue(_JSON$stringify(this.value, null, 2));
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.jsonEditor = CodeMirror.fromTextArea(this.$refs.textarea, {
      lineNumbers: true,
      mode: 'application/json',
      gutters: ['CodeMirror-lint-markers'],
      theme: 'rubyblue',
      lint: true
    });

    this.jsonEditor.setValue(_JSON$stringify(this.value, null, 2));
    this.jsonEditor.on('change', function (cm) {
      _this.$emit('changed', cm.getValue());
      _this.$emit('input', cm.getValue());
    });
  },

  methods: {
    getValue: function getValue() {
      return this.jsonEditor.getValue();
    }
  }
};