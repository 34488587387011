import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { putTenant, fetchTenants, deleteTenant } from '@/api/tenants';
import { fetchClusters } from '@/api/clusters';
import { validateEmpty } from '@/utils/validate';
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';

var defaultForm = {
  cluster: ''
};
export default {
  name: 'Tenants',
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clusterListOptions: [],
      tableKey: 0,
      list: null,
      localList: [],
      searchList: [],
      total: 0,
      listLoading: true,
      listQuery: {
        tenant: '',
        page: 1,
        limit: 10
      },
      form: {
        tenant: '',
        clusters: [],
        dynamicRoles: []
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        create: this.$i18n.t('tenant.newTenant'),
        delete: this.$i18n.t('tenant.deleteTenant')
      },
      rules: {
        tenant: [{ required: true, message: this.$i18n.t('tenant.tenantIsRequired'), trigger: 'blur' }],
        clusters: [{ required: true, message: this.$i18n.t('tenant.clusterIsRequired'), trigger: 'blur' }]
      },
      inputVisible: false,
      inputValue: '',
      deleteTenantMessage: this.$i18n.t('tenant.deleteTenantMessage')
    };
  },
  created: function created() {
    this.getTenants();
  },

  methods: {
    getTenants: function getTenants() {
      var _this = this;

      if (this.localList.length > 0) {
        setTimeout(function () {
          _this.localPaging();
        }, 0);
      } else {
        this.listLoading = true;
        fetchTenants().then(function (response) {
          for (var i = 0; i < response.data.total; i++) {
            var allowedClusters = '';
            var adminRoles = '';
            if (response.data.data[i]['allowedClusters'].length > 0) {
              allowedClusters = response.data.data[i]['allowedClusters'];
            }
            if (response.data.data[i]['adminRoles'].length > 0) {
              adminRoles = response.data.data[i]['adminRoles'];
            }
            var adminRolesArray = [];
            if (adminRoles !== '') {
              adminRolesArray = adminRoles.split(',');
            }
            var allowedClustersArray = [];
            if (allowedClusters !== '') {
              allowedClustersArray = allowedClusters.split(',');
            }
            var data = {
              'tenant': response.data.data[i]['tenant'],
              'namespace': response.data.data[i]['namespaces'],
              'allowedClusters': allowedClustersArray,
              'adminRoles': adminRolesArray,
              'inMsg': numberFormatter(0, 2),
              'outMsg': numberFormatter(0, 2),
              'inBytes': formatBytes(0),
              'outBytes': formatBytes(0),
              'storageSize': formatBytes(0, 0)
            };
            if (response.data.data[i].hasOwnProperty('inMsg')) {
              data['inMsg'] = numberFormatter(response.data.data[i]['inMsg'], 2);
              data['outMsg'] = numberFormatter(response.data.data[i]['outMsg'], 2);
              data['inBytes'] = numberFormatter(response.data.data[i]['inBytes']);
              data['outBytes'] = numberFormatter(response.data.data[i]['outBytes']);
              data['storageSize'] = numberFormatter(response.data.data[i]['storageSize'], 2);
            }
            _this.localList.push(data);
          }
          _this.total = response.data.total;
          _this.listQuery.page = response.data.pageNum;
          _this.listQuery.limit = response.data.pageSize;
          _this.list = _this.localList.slice((_this.listQuery.page - 1) * _this.listQuery.limit, _this.listQuery.limit * _this.listQuery.page);
          // this.localPaging()
          // Just to simulate the time of the request
          setTimeout(function () {
            _this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    localPaging: function localPaging() {
      this.listLoading = true;
      if (!validateEmpty(this.listQuery.tenant)) {
        this.searchList = [];
        for (var i = 0; i < this.localList.length; i++) {
          if (this.localList[i]['tenant'].indexOf(this.listQuery.tenant) !== -1) {
            this.searchList.push(this.localList[i]);
          }
        }
        this.total = this.searchList.length;
        this.list = this.searchList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      } else {
        this.total = this.localList.length;
        this.list = this.localList.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.limit * this.listQuery.page);
      }
      this.listLoading = false;
    },
    handleFilter: function handleFilter() {
      this.getTenants();
    },
    resetForm: function resetForm() {
      this.form = {
        tenant: '',
        adminRoles: '',
        allowedClusters: '',
        clusters: []
      };
    },
    handleOptions: function handleOptions() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          switch (_this2.dialogStatus) {
            case 'create':
              _this2.createData();
              break;
            case 'delete':
              _this2.deleteData();
              break;
          }
        }
      });
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.form.clusters = [];
      this.clusterListOptions = [];
      this.form.dynamicRoles = [];
      fetchClusters(this.listQuery).then(function (response) {
        for (var i = 0; i < response.data.data.length; i++) {
          _this3.clusterListOptions.push({ 'value': response.data.data[i].cluster, 'label': response.data.data[i].cluster });
        }
      });
      this.$nextTick(function () {
        _this3.$refs['form'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var data = {
            allowedClusters: _this4.form.clusters,
            adminRoles: _this4.form.dynamicRoles
          };
          putTenant(_this4.form.tenant, data).then(function (response) {
            _this4.form.adminRoles = 'empty';
            _this4.form.allowedClusters = 'empty';
            _this4.localList = [];
            _this4.getTenants();
            _this4.dialogFormVisible = false;
            _this4.$notify({
              title: 'success',
              message: _this4.$i18n.t('tenant.createTenantSuccessNotification'),
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      this.dialogStatus = 'delete';
      this.dialogFormVisible = true;
      this.form.tenant = row.tenant;
    },
    deleteData: function deleteData() {
      var _this5 = this;

      deleteTenant(this.form.tenant).then(function (response) {
        _this5.$notify({
          title: 'success',
          message: _this5.$i18n.t('tenant.deleteTenantSuccessNotification'),
          type: 'success',
          duration: 2000
        });
        _this5.dialogFormVisible = false;
        _this5.localList = [];
        _this5.getTenants();
      });
    },
    handleClose: function handleClose(tag) {
      this.form.dynamicRoles.splice(this.form.dynamicRoles.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this6 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this6.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.form.dynamicRoles.indexOf(this.inputValue) >= 0) {
          this.$notify({
            title: 'error',
            message: this.$i18n.t('tenant.roleAlreadyExists'),
            type: 'error',
            duration: 2000
          });
          return;
        }
        this.form.dynamicRoles.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }
};