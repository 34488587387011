//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateTitle } from '@/utils/i18n';
import pathToRegexp from 'path-to-regexp';

export default {
  data: function data() {
    return {
      levelList: null
    };
  },

  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },

  methods: {
    generateTitle: generateTitle,
    getBreadcrumb: function getBreadcrumb() {
      var params = this.$route.params;

      var matched = this.$route.matched.filter(function (item) {
        if (item.name) {
          // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
          var toPath = pathToRegexp.compile(item.path);
          item.path = toPath(params);
          return true;
        }
      });
      var route = [];
      for (var i = 0; i < matched.length; i++) {
        var path = matched[i].path;
        if (path === '/management') {
          route.push({
            'path': matched[i].path,
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.management')
            }
          });
        }
        var pathList = path.split('/');
        if (pathList.indexOf('brokers') === 2) {
          route.push({
            'path': '/management/clusters',
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusters')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': '/management/clusters/' + pathList[3] + '/cluster?tab=brokers',
            'meta': {
              'title': this.$i18n.t('breadcrumb.brokers')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': matched[i].path,
            'meta': {
              'title': this.$i18n.t('breadcrumb.brokerInfo')
            },
            'redirect': matched[i].redirect
          });
        } else if (pathList.indexOf('namespaceIsolationPolicy') === 5) {
          route.push({
            'path': '/management/clusters',
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusters')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': '/management/clusters/' + pathList[3] + '/cluster?tab=isolationPolicies',
            'meta': {
              'title': this.$i18n.t('breadcrumb.isolationPolicies')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': matched[i].path,
            'meta': {
              'title': this.$i18n.t('breadcrumb.isolationPolicyInfo')
            },
            'redirect': matched[i].redirect
          });
        } else if (pathList.indexOf('failureDomainName') === 5) {
          route.push({
            'path': '/management/clusters',
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusters')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': '/management/clusters/' + pathList[3] + '/cluster?tab=failureDomains',
            'meta': {
              'title': this.$i18n.t('breadcrumb.failureDomains')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': matched[i].path,
            'meta': {
              'title': this.$i18n.t('breadcrumb.failureDomainInfo')
            },
            'redirect': matched[i].redirect
          });
        } else if (pathList.indexOf('cluster') === 4) {
          route.push({
            'path': '/management/clusters',
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusters')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': matched[i].path,
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusterInfo')
            },
            'redirect': matched[i].redirect
          });
        } else if (pathList.indexOf('clusters') === 2) {
          route.push({
            'path': '/management/clusters',
            'meta': {
              'title': this.$i18n.t('breadcrumb.clusters')
            },
            'redirect': matched[i].redirect
          });
        } else if (pathList.indexOf('subscriptions') === 2) {
          route.push({
            'path': '/management/tenants',
            'meta': {
              'title': this.$i18n.t('breadcrumb.tenants')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': '/management/tenants/tenantInfo/' + pathList[4] + '?tab=namespaces',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.namespaces')
            }
          });
          route.push({
            'path': '/management/namespaces/' + pathList[4] + '/' + pathList[5] + '/namespace' + '?tab=topics',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.topics')
            }
          });
          route.push({
            'path': '/management/topics/' + pathList[3] + '/' + pathList[4] + '/' + pathList[5] + '/' + pathList[6] + '/topic',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.subscriptions')
            }
          });
          route.push({
            'path': matched[i].path,
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.subscriptionInfo')
            }
          });
        } else if (pathList.indexOf('topics') === 2) {
          route.push({
            'path': '/management/tenants',
            'meta': {
              'title': this.$i18n.t('breadcrumb.tenants')
            },
            'redirect': matched[i].redirect
          });
          route.push({
            'path': '/management/tenants/tenantInfo/' + pathList[4] + '?tab=namespaces',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.namespaces')
            }
          });
          route.push({
            'path': '/management/namespaces/' + pathList[4] + '/' + pathList[5] + '/namespace' + '?tab=topics',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.topics')
            }
          });
          route.push({
            'path': matched[i].path,
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.topicInfo')
            }
          });
        } else if (pathList.indexOf('namespaces') === 2) {
          route.push({
            'path': '/management/tenants',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.tenants')
            }
          });
          route.push({
            'path': '/management/tenants/tenantInfo/' + pathList[3] + '?tab=namespaces',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.namespaces')
            }
          });
          route.push({
            'path': matched[i].path,
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.namespaceInfo')
            }
          });
        } else if (pathList.indexOf('tenantInfo') === 3) {
          route.push({
            'path': '/management/tenants',
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.tenants')
            }
          });
          route.push({
            'path': matched[i].path,
            'redirect': matched[i].redirect,
            'meta': {
              'title': this.$i18n.t('breadcrumb.tenantInfo')
            }
          });
        }
      }
      // if (first && first.name.trim().toLocaleLowerCase() !== 'Management'.toLocaleLowerCase()) {
      //   matched = [{ path: '/management', meta: { title: 'management' }}].concat(matched)
      // }
      this.levelList = route;
    }
  }
};