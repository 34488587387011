import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchTenants } from '@/api/tenants';
import { fetchNamespaces, getClusters } from '@/api/namespaces';
import { fetchTopicsByPulsarManager, getBundleRangeOnCluster, getTopicBrokerOnCluster, unloadOnCluster, fetchTopicStats, fetchTopicStatsInternal, terminateOnCluster, compactOnCluster, compactionStatusOnCluster, offloadOnCluster, offloadStatusOnCluster, deleteTopicOnCluster, getPermissionsOnCluster, grantPermissionsOnCluster, revokePermissionsOnCluster } from '@/api/topics';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { formatBytes } from '@/utils/index';
import { numberFormatter } from '@/filters/index';
import { putSubscriptionOnCluster, deleteSubscriptionOnCluster } from '@/api/subscriptions';
import { validateSizeString } from '@/utils/validate';

var defaultForm = {
  persistent: '',
  tenant: '',
  namespace: '',
  topic: '',
  partition: ''
};
var defaultClusterForm = {
  cluster: ''
};
export default {
  name: 'TopicInfo',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      postForm: _Object$assign({}, defaultForm),
      clusterForm: _Object$assign({}, defaultClusterForm),
      replicatedClusters: [],
      tenantsListOptions: [],
      namespacesListOptions: [],
      topicsListOptions: [],
      activeName: 'overview',
      topicStats: [],
      infoData: [],
      terminateStatus: '',
      compaction: 'NOT RUN',
      offload: 'NOT RUN',
      producersListLoading: false,
      producerTableKey: 0,
      producersList: [],
      producersTotal: 0,
      producersListQuery: {
        page: 1,
        limit: 0
      },
      subscriptionsListLoading: false,
      subscriptionTableKey: 0,
      subscriptionsList: [],
      subscriptionsTotal: 0,
      subscriptionsListQuery: {
        page: 1,
        limit: 0
      },
      segmentsListLoading: false,
      segmentTableKey: 0,
      segmentsList: [],
      storageSize: 0,
      entries: 0,
      segments: 0,
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      roleValue: [],
      roleMap: {},
      roleMapOptions: {},
      roleOptions: [{
        value: 'consume',
        label: this.$i18n.t('role_actions.consume')
      }, {
        value: 'produce',
        label: this.$i18n.t('role_actions.produce')
      }, {
        value: 'functions',
        label: this.$i18n.t('role_actions.functions')
      }],
      authorizationContent: this.$i18n.t('topic.policy.authorizationContent'),
      topicName: '',
      firstInit: false,
      firstInitTopic: false,
      cursorTableKey: 0,
      cursorsList: [],
      cursorListLoading: false,
      currentTabName: '',
      nonPersistent: false,
      textMap: {
        delete: this.$i18n.t('topic.deleteTopic'),
        createSub: this.$i18n.t('topic.subscription.sub'),
        deleteSub: this.$i18n.t('topic.subscription.unsub')
      },
      subName: '',
      dialogFormVisible: false,
      dialogStatus: '',
      topicPartitions: {},
      partitionDisabled: false,
      partitionsListOptions: [],
      routeTopic: '',
      routeTopicPartition: -1,
      routeCluster: '',
      loaded: false,
      offloadThreshold: '0K',
      offloadDisabled: true
    };
  },
  created: function created() {
    this.postForm.persistent = this.$route.params && this.$route.params.persistent;
    this.postForm.tenant = this.$route.params && this.$route.params.tenant;
    this.postForm.namespace = this.$route.params && this.$route.params.namespace;
    this.postForm.topic = this.$route.params && this.$route.params.topic;
    this.clusterForm.cluster = this.$route.query && this.$route.query.cluster;
    this.routeCluster = this.clusterForm.cluster;
    this.tenantNamespaceTopic = this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic;
    if (this.postForm.topic.indexOf('-partition-') > 0) {
      var splitTopic = this.postForm.topic.split('-partition-');
      this.postForm.partition = splitTopic[1];
      this.postForm.topic = splitTopic[0];
    } else {
      this.postForm.partition = '-1';
      this.partitionDisabled = true;
    }
    this.routeTopic = this.postForm.topic;
    this.routeTopicPartition = this.postForm.partition;
    if (this.$route.query) {
      if (this.$route.query.tab) {
        this.activeName = this.$route.query.tab;
      } else {
        this.activeName = 'overview';
      }
    }
    this.currentTabName = this.activeName;
    if (this.postForm.persistent === 'persistent') {
      this.nonPersistent = false;
    } else if (this.postForm.persistent === 'non-persistent') {
      this.nonPersistent = true;
    }
    this.topicName = this.postForm.persistent + '://' + this.tenantNamespaceTopic;
    this.firstInit = true;
    this.firstInitTopic = true;
    this.getRemoteTenantsList();
    this.getNamespacesList(this.postForm.tenant);
    this.getTopicsList();
    this.initBundleRange();
    this.initTopicBroker();
    this.initTopicStats();
    this.getReplicatedClusters();
    if (!this.nonPersistent) {
      this.initTerminateAndSegments();
      this.getCompactionStatus();
      this.getOffloadStatus();
    }
    this.loaded = true;
    this.initPermissions();
  },

  methods: {
    onClusterChanged: function onClusterChanged() {
      if (this.loaded && this.routeCluster !== this.clusterForm.cluster) {
        this.reload();
      }
    },
    onPartitionChanged: function onPartitionChanged() {
      if (this.loaded && parseInt(this.routeTopicPartition) !== parseInt(this.postForm.partition)) {
        this.reload();
      }
    },
    generatePartitions: function generatePartitions() {
      var partitions = parseInt(this.topicPartitions[this.postForm.topic]);
      this.partitionsListOptions = [];
      if (partitions > 0) {
        this.partitionDisabled = false;
        if (this.postForm.partition === '-1') {
          this.postForm.partition = '';
        }
        for (var i = 0; i < partitions; i++) {
          this.partitionsListOptions.push(i);
        }
      } else {
        this.partitionDisabled = true;
        this.postForm.partition = '-1';
        if (this.loaded && this.routeTopic !== this.postForm.topic) {
          this.reload();
        }
      }
    },
    getRemoteTenantsList: function getRemoteTenantsList() {
      var _this = this;

      fetchTenants().then(function (response) {
        if (!response.data) return;
        for (var i = 0; i < response.data.total; i++) {
          _this.tenantsListOptions.push(response.data.data[i].tenant);
        }
      });
    },
    getOffloadStatus: function getOffloadStatus() {
      var _this2 = this;

      offloadStatusOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this2.offload = response.data.status;
        _this2.offloadDisabled = _this2.offload === 'RUNNING';
      });
    },
    getCompactionStatus: function getCompactionStatus() {
      var _this3 = this;

      compactionStatusOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this3.compaction = response.data.status;
      });
    },
    initTopicStats: function initTopicStats() {
      var _this4 = this;

      fetchTopicStats(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this4.topicStats = [];
        _this4.topicStats.push({
          inMsg: numberFormatter(response.data.msgRateIn, 2),
          outMsg: numberFormatter(response.data.msgRateOut, 2),
          inBytes: formatBytes(response.data.msgThroughputIn),
          outBytes: formatBytes(response.data.msgThroughputOut)
        });
        for (var i in response.data.publishers) {
          _this4.producersList.push({
            'producerId': response.data.publishers[i].producerId,
            'producerName': response.data.publishers[i].producerName,
            'inMsg': numberFormatter(response.data.publishers[i].msgRateIn, 2),
            'inBytes': formatBytes(response.data.publishers[i].msgThroughputIn),
            'avgMsgSize': numberFormatter(response.data.publishers[i].averageMsgSize, 2),
            'address': response.data.publishers[i].address,
            'since': response.data.publishers[i].connectedSince
          });
        }
        _this4.subscriptionsList = [];
        for (var s in response.data.subscriptions) {
          var type = '';
          if (response.data.subscriptions[s].hasOwnProperty('type')) {
            type = response.data.subscriptions[s].type;
          }
          _this4.subscriptionsList.push({
            'subscription': s,
            'outMsg': numberFormatter(response.data.subscriptions[s].msgRateOut, 2),
            'outBytes': formatBytes(response.data.subscriptions[s].msgThroughputOut),
            'msgExpired': numberFormatter(response.data.subscriptions[s].msgRateExpired, 2),
            'backlog': response.data.subscriptions[s].msgBacklog,
            'type': type,
            // subscriptions/:persistent/:tenant/:namespace/:topic/:subscription/subscription
            'subscriptionLink': '/management/subscriptions/' + _this4.postForm.persistent + '/' + _this4.getFullTopic() + '/' + s + '/subscription'
          });
        }
        _this4.storageSize = formatBytes(response.data.storageSize, 0);
      });
    },
    initBundleRange: function initBundleRange() {
      var _this5 = this;

      getBundleRangeOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this5.infoData.push({
          infoColumn: 'bundle',
          data: response.data
        });
      });
    },
    initTopicBroker: function initTopicBroker() {
      var _this6 = this;

      getTopicBrokerOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this6.infoData.push({
          infoColumn: 'broker',
          data: response.data.brokerUrl
        });
        _this6.infoData.push({
          infoColumn: 'httpUrl',
          data: response.data.httpUrl
        });
      });
    },
    initTerminateAndSegments: function initTerminateAndSegments() {
      var _this7 = this;

      fetchTopicStatsInternal(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        _this7.terminateStatus = response.data.state;
        _this7.segments = response.data.ledgers.length;
        var lastIndex = _this7.segments - 1;
        for (var i in response.data.ledgers) {
          if (lastIndex === parseInt(i)) {
            _this7.segmentsList.push({
              'ledgerId': response.data.ledgers[i]['ledgerId'],
              'entries': response.data.currentLedgerEntries,
              'size': numberFormatter(response.data.currentLedgerSize, 2),
              'offload': response.data.ledgers[i]['offloaded'],
              'status': 'opening'
            });
          } else {
            _this7.segmentsList.push({
              'ledgerId': response.data.ledgers[i]['ledgerId'],
              'entries': response.data.ledgers[i]['entries'],
              'size': numberFormatter(response.data.ledgers[i]['size'], 2),
              'offload': response.data.ledgers[i]['offloaded'],
              'status': 'closing'
            });
          }
        }
        _this7.entries = numberFormatter(response.data.numberOfEntries, 0);
        for (var c in response.data.cursors) {
          _this7.cursorsList.push({
            'name': c,
            'markDeletePosition': response.data.cursors[c].markDeletePosition,
            'readPosition': response.data.cursors[c].readPosition,
            'waitingReadOp': response.data.cursors[c].waitingReadOp,
            'pendingReadOps': response.data.cursors[c].pendingReadOps,
            'numberOfEntriesSinceFirstNotAckedMessage': response.data.cursors[c].numberOfEntriesSinceFirstNotAckedMessage
          });
        }
      });
    },
    getNamespacesList: function getNamespacesList(tenant) {
      var _this8 = this;

      // reset the namespaces, topics and clusters list
      this.namespacesListOptions = [];
      this.topicsListOptions = [];
      this.replicatedClusters = [];
      if (this.firstInit) {
        this.firstInit = false;
      } else {
        this.postForm.namespace = '';
        this.postForm.topic = '';
        this.clusterForm.cluster = this.routeCluster || '';
      }
      fetchNamespaces(tenant, this.query).then(function (response) {
        if (!response.data) return;
        var namespace = [];
        for (var i = 0; i < response.data.data.length; i++) {
          namespace = response.data.data[i].namespace;
          _this8.namespacesListOptions.push(namespace);
        }
      });
    },
    getReplicatedClusters: function getReplicatedClusters() {
      var _this9 = this;

      if (this.postForm.tenant && this.postForm.namespace) {
        getClusters(this.postForm.tenant, this.postForm.namespace).then(function (response) {
          if (!response.data) {
            return;
          }
          _this9.replicatedClusters = response.data;
          if (response.data.length > 0) {
            _this9.clusterForm.cluster = _this9.routeCluster || _this9.replicatedClusters[0];
          }
        });
      }
    },
    getTopicsList: function getTopicsList() {
      var _this10 = this;

      this.getReplicatedClusters();
      this.topicsListOptions = [];
      this.partitionsListOptions = [];
      if (this.firstInitTopic) {
        this.firstInitTopic = false;
      } else {
        this.postForm.topic = '';
        this.postForm.partition = '';
      }
      fetchTopicsByPulsarManager(this.postForm.tenant, this.postForm.namespace).then(function (response) {
        if (!response.data) return;
        for (var i in response.data.topics) {
          _this10.topicsListOptions.push(response.data.topics[i]['topic']);
          _this10.topicPartitions[response.data.topics[i]['topic']] = response.data.topics[i]['partitions'];
          if (response.data.topics[i]['topic'] === _this10.postForm.topic) {
            _this10.generatePartitions();
          }
        }
      });
    },
    reload: function reload() {
      this.$router.push({ path: '/management/topics/' + this.postForm.persistent + '/' + this.getFullTopic() + '/topic?tab=' + this.currentTabName + '&cluster=' + this.getCurrentCluster() });
    },
    getCurrentCluster: function getCurrentCluster() {
      return this.clusterForm.cluster || '';
    },
    handleClick: function handleClick(tab, event) {
      this.currentTabName = tab.name;
      this.$router.push({
        query: {
          'tab': tab.name,
          'cluster': this.getCurrentCluster()
        }
      });
    },
    handleUnload: function handleUnload() {
      var _this11 = this;

      unloadOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        _this11.$notify({
          title: 'success',
          message: _this11.$i18n.t('topic.notification.unloadTopicSuccess'),
          type: 'success',
          duration: 3000
        });
      });
    },
    handleTerminate: function handleTerminate() {
      var _this12 = this;

      terminateOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        _this12.$notify({
          title: 'success',
          message: _this12.$i18n.t('topic.notification.terminateTopicSuccess'),
          type: 'success',
          duration: 3000
        });
        _this12.initTerminateAndSegments();
      });
    },
    initPermissions: function initPermissions() {
      var _this13 = this;

      getPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        if (!response.data) return;
        for (var key in response.data) {
          _this13.dynamicTags.push(key);
          _this13.roleMap[key] = response.data[key];
          _this13.roleMapOptions[key] = _this13.roleOptions;
        }
      });
    },
    handleClose: function handleClose(tag) {
      var _this14 = this;

      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      revokePermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), tag).then(function (response) {
        _this14.$notify({
          title: 'success',
          message: _this14.$i18n.t('namespace.notification.removeRoleSuccess'),
          type: 'success',
          duration: 3000
        });
        delete _this14.roleMap[tag];
        delete _this14.roleMapOptions[tag];
      });
    },
    showInput: function showInput() {
      var _this15 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this15.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var _this16 = this;

      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.roleMap.hasOwnProperty(inputValue)) {
          this.$message({
            message: this.$i18n.t('role.roleAlreadyExists'),
            type: 'error'
          });
          this.inputVisible = false;
          this.inputValue = '';
          return;
        }
        grantPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), inputValue, this.roleMap[inputValue]).then(function (response) {
          _this16.$notify({
            title: 'success',
            message: _this16.$i18n.t('namespace.notification.addRoleSuccess'),
            type: 'success',
            duration: 3000
          });
          _this16.dynamicTags.push(inputValue);
          _this16.roleMap[inputValue] = [];
          _this16.roleMapOptions[inputValue] = _this16.roleOptions;
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleChangeOptions: function handleChangeOptions(role) {
      var _this17 = this;

      grantPermissionsOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), role, this.roleMap[role]).then(function (response) {
        _this17.$notify({
          title: 'success',
          message: _this17.$i18n.t('namespace.notification.addRoleActionsSuccess'),
          type: 'success',
          duration: 3000
        });
      });
      this.$forceUpdate();
    },
    getFullTopic: function getFullTopic() {
      var fullTopic = this.postForm.tenant + '/' + this.postForm.namespace + '/' + this.postForm.topic;
      if (parseInt(this.postForm.partition) >= 0) {
        fullTopic += '-partition-' + this.postForm.partition;
      }
      return fullTopic;
    },
    handleCompaction: function handleCompaction() {
      var _this18 = this;

      compactOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        _this18.$notify({
          title: 'success',
          message: _this18.$i18n.t('topic.notification.startCompactionSuccess'),
          type: 'success',
          duration: 3000
        });
        _this18.getCompactionStatus();
      });
    },
    handleOffload: function handleOffload() {
      var _this19 = this;

      var threshold = validateSizeString(this.offloadThreshold);
      if (isNaN(threshold) || threshold < 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.errorLog.invalidSizeStr'),
          type: 'error',
          duration: 3000
        });
        return;
      }

      fetchTopicStatsInternal(this.postForm.persistent, this.getFullTopic()).then(function (response) {
        var ledgers = response.data.ledgers;

        if (!ledgers || ledgers.length <= 1) {
          _this19.$notify({
            title: 'warn',
            message: _this19.$i18n.t('topic.errorLog.noOffloadData'),
            type: 'warn',
            duration: 3000
          });
          return;
        }

        ledgers[ledgers.length - 1].size = response.data.currentLedgerSize;
        ledgers = ledgers.reverse();

        var totalSize = 0;
        var preLedgerId = ledgers[0].ledgerId;
        var messageId;
        for (var i = 0; i < ledgers.length; i++) {
          totalSize += ledgers[i].size;
          if (totalSize > threshold) {
            messageId = {
              ledgerId: preLedgerId,
              entryId: 0,
              partitionIndex: -1
            };
            break;
          }
          preLedgerId = ledgers[i].ledgerId;
        }

        if (!messageId) {
          _this19.$notify({
            title: 'warn',
            message: _this19.$i18n.t('topic.errorLog.noOffloadData'),
            type: 'warn',
            duration: 3000
          });
          return;
        }

        offloadOnCluster(_this19.getCurrentCluster(), _this19.postForm.persistent, _this19.getFullTopic(), messageId).then(function (response) {
          var msg = _this19.$i18n.t('topic.notification.startOffloadSuccess') + ' before ' + messageId['ledgerId'] + ':' + messageId['entryId'] + ' !';
          _this19.$notify({
            title: 'success',
            message: msg,
            type: 'success',
            duration: 3000
          });
        });
        _this19.getOffloadStatus();
      });
    },
    handleDeleteTopic: function handleDeleteTopic() {
      this.dialogStatus = 'delete';
      this.dialogFormVisible = true;
    },
    deleteTopic: function deleteTopic() {
      var _this20 = this;

      deleteTopicOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic()).then(function (response) {
        _this20.$notify({
          title: 'success',
          message: _this20.$i18n.t('topic.notification.deleteTopicSuccess'),
          type: 'success',
          duration: 3000
        });
        _this20.$router.push({ path: '/management/namespaces/' + _this20.postForm.tenant + '/' + _this20.postForm.namespace + '/namespace?tab=topics' });
      });
    },
    handleCreateSub: function handleCreateSub() {
      this.subName = '';
      this.dialogStatus = 'createSub';
      this.dialogFormVisible = true;
    },
    createSub: function createSub() {
      var _this21 = this;

      if (this.subName.length <= 0) {
        this.$notify({
          title: 'error',
          message: this.$i18n.t('topic.subscription.subNotification'),
          type: 'error',
          duration: 3000
        });
        return;
      }
      putSubscriptionOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.subName).then(function (response) {
        _this21.$notify({
          title: 'success',
          message: _this21.$i18n.t('topic.subscription.createSubSuccess'),
          type: 'success',
          duration: 3000
        });
        _this21.initTopicStats();
        _this21.dialogFormVisible = false;
      });
    },
    handleCommand: function handleCommand(subName) {
      this.subName = subName;
      this.dialogStatus = 'deleteSub';
      this.dialogFormVisible = true;
    },
    deleteSub: function deleteSub() {
      var _this22 = this;

      deleteSubscriptionOnCluster(this.getCurrentCluster(), this.postForm.persistent, this.getFullTopic(), this.subName).then(function (response) {
        _this22.$notify({
          title: 'success',
          message: _this22.$i18n.t('topic.subscription.deleteSubSuccess'),
          type: 'success',
          duration: 3000
        });
        _this22.initTopicStats();
        _this22.dialogFormVisible = false;
      });
    }
  }
};