var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.handleCreateUser }
        },
        [_vm._v(_vm._s(_vm.$t("user.buttonNewUser")))]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 }
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.userListLoading,
                      expression: "userListLoading"
                    }
                  ],
                  key: _vm.userTableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userList,
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserName"),
                      "min-width": "50px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserDesc"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.description))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserEmail"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserPhone"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.phoneNumber))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserLocation"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.location))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.colUserCompany"),
                      align: "center",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.company))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("table.actions"),
                      align: "center",
                      "class-name": "small-padding fixed-width"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.handleUpdateUser(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("table.edit")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    _vm.handleDeleteUser(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("table.delete")))]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getUsers
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "top"
              }
            },
            [
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("user.colUserName"), prop: "name" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("user.userNamePlaceHolder")
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserDesc") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userDescPlaceHolder"),
                          type: "textarea"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("user.colUserEmail"),
                        prop: "email"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userEmailPlaceHolder")
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserPhone") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userPhonePlaceHolder")
                        },
                        model: {
                          value: _vm.form.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phoneNumber", $$v)
                          },
                          expression: "form.phoneNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserLocation") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userLocationPlaceHolder")
                        },
                        model: {
                          value: _vm.form.location,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "location", $$v)
                          },
                          expression: "form.location"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserCompany") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userCompnayPlaceHolder")
                        },
                        model: {
                          value: _vm.form.company,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "company", $$v)
                          },
                          expression: "form.company"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("uesr.colUserPassword"),
                        prop: "password"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userPasswordPlaceHolder"),
                          type: "password"
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("user.colUserPasswordRepeat"),
                        prop: "repeatPassword"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userPasswordPlaceHolder"),
                          type: "password"
                        },
                        model: {
                          value: _vm.form.repeatPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "repeatPassword", $$v)
                          },
                          expression: "form.repeatPassword"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserName") } },
                    [_c("span", [_vm._v(_vm._s(_vm.form.name))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserDesc") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userDescPlaceHolder"),
                          type: "textarea"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("user.colUserEmail"),
                        prop: "email"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userEamilPlaceHolder")
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserPhone") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userPhonePlaceHolder")
                        },
                        model: {
                          value: _vm.form.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phoneNumber", $$v)
                          },
                          expression: "form.phoneNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserLocation") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userLocationPlaceHolder")
                        },
                        model: {
                          value: _vm.form.location,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "location", $$v)
                          },
                          expression: "form.location"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.colUserCompany") } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: _vm.$t("user.userCompnayPlaceHolder")
                        },
                        model: {
                          value: _vm.form.company,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "company", $$v)
                          },
                          expression: "form.company"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus === "delete"
                ? _c("el-form-item", [
                    _c("h4", [_vm._v("Delete User " + _vm._s(_vm.form.name))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.handleOptions()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("table.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }