/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export default {
  computed: {
    device: function device() {
      return this.$store.state.app.device;
    }
  },
  mounted: function mounted() {
    // In order to fix the click on menu on the ios device will trigger the mouseeleave bug
    // https://github.com/PanJiaChen/vue-element-admin/issues/1135
    this.fixBugIniOS();
  },

  methods: {
    fixBugIniOS: function fixBugIniOS() {
      var _this = this;

      var $submenu = this.$refs.submenu;
      if ($submenu) {
        var handleMouseleave = $submenu.handleMouseleave;
        $submenu.handleMouseleave = function (e) {
          if (_this.device === 'mobile') {
            return;
          }
          handleMouseleave(e);
        };
      }
    }
  }
};